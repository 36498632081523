/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import { Box } from 'native-base';

import { StashboardMedia } from '@springbig/shared';

import _ReactPlayer, { ReactPlayerProps } from 'react-player'
import Image from '~/shared/Image';

const ReactPlayer = _ReactPlayer as unknown as React.FC<ReactPlayerProps>;

export const CarouselCard = ({ item, index }: {item: StashboardMedia, index: any}) => {

    if (item.media_type === 'video') {

        return (
            <Box key={index} w={'100%'} height={'96'} zIndex={99999}>
                <ReactPlayer url={item.result_url} width={'100%'} height={'100%'} />
            </Box>
        );
    }

    if (item.media_type === 'image') {
        return (
            <Box key={index} w={'100%'} height={96} zIndex={99999}>
                <Image
                    w={'100%'}
                    h={'96'}
                    uri={`${item.result_url as string}`}
                />
            </Box>
        );
    }

    return (
        <Box key={index} w={'100%'} height={96} zIndex={99999}>
            <Image
                w={'100%'}
                h={'96'}
                uri={`https://www.foot.com/wp-content/uploads/2017/06/placeholder-square.jpg`}
            />
        </Box>
    );

};
