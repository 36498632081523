import React, { useCallback, useEffect, useState } from "react";

import Section from "~/shared/Section";
import MessageCarousel from "./components/MessagesCarousel";
import { useAppNavigate } from "..";
import { Stashboard, getStashboardData } from "@springbig/shared";
import { useAuthorization } from "~/hooks/auth";
import Loading from "~/shared/Loading";

const MessageDetailScreen = () => {
    const { params } = useAppNavigate();
    const {isLoadingAuth} = useAuthorization();
    const [data, setData] = useState<Stashboard | null>();

    const getMessage = useCallback(async (stashboard_key: string) => {


        const data = await getStashboardData({
            stashboard_key
        });

        setData(data);
    },[])

    useEffect(() => {

        if(params && params.stashboard_key){
            getMessage(params.stashboard_key);
        }
    }, [params, getMessage])

    if(isLoadingAuth){
        return <Loading isLoading={isLoadingAuth}/>;
    }
    

    return (
        <Section pb={10} flex={1} w={"100%"}>
            {data && <MessageCarousel data={data} />}
        </Section>
    );
};

export default MessageDetailScreen;
