/* eslint-disable max-lines-per-function */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useCallback, useEffect, useState } from 'react';
import { Center } from 'native-base';
import { Interest, Member, useGetMember, useSpringBigStore, useUpdateMember } from '@springbig/shared';

import Section from '~/shared/Section';
import ProfileHeader from './components/ProfileHeader';
import ProfileInterest from './components/ProfileInterest';
import { useGeneralStyles } from '~/shared/styles';
import { H1 } from '~/shared/Typography';
import { useAuthorization } from '~/hooks/auth';
import Loading from '~/shared/Loading';

const ProfileScreen = () => {

    const [interest, setInterest] = useState<Interest[]>();
    const { member } = useSpringBigStore();

    const {
        mutate: updateMember,
        isLoading: updateIsLoading
    } = useUpdateMember();

    const {
        refetch: fetchMember,
        isLoading: memberIsLoading
    } = useGetMember();

    const {isLoadingAuth} = useAuthorization();

    useEffect(() => {

        if (member) {
            setInterest(member.interests);
        }
    }, [member]);


    const handleInterest = useCallback((name?: string) => {

        if (!name) {
            return;
        }

        const payload: {
            member: Partial<Member>,
            interest_list: string
        } = {
            member: {
                id: member!.id,
            },
            interest_list: ''
        };

        let interestList: string[] = [];

        const checkedInterest: string[] = member && member.interests ? member.interests.filter(el => el.checked)?.map(el => el.name) : [];

        if (checkedInterest && checkedInterest.includes(name) ) {
            interestList = checkedInterest.filter(data => data !== name);
        }
        else {
            interestList = checkedInterest && checkedInterest.length > 0 ? [...checkedInterest, name] : [name];
        }

        payload.interest_list = [...interestList].join(',');

        updateMember(payload);
    }, [
        member,
        updateMember
    ]);

    if(isLoadingAuth || !member){
        return <Loading isLoading={isLoadingAuth}/>;
    }

    return (
        <Section flex={1} w={'100%'}>
                <Center mb={16}alignItems='center' position={'relative'}>
                    <ProfileHeader member={member}/>
                    {
                        interest && interest.length > 0 ? <ProfileInterest
                            handleInterest={handleInterest}
                            interest={interest}
                            updateIsLoading={updateIsLoading}
                        /> : <NoInterest
                            message='No interest'
                        />
                    }
                </Center>
        </Section>
    );
};

const NoInterest = ({ message }: {message: string}) => {

    const {
        text,
    } = useGeneralStyles();

    return (
        <Center
            alignItems='center'
        >
            <H1
                color={text}
            >
                {message}
            </H1>
        </Center>
    );
};

export default ProfileScreen;