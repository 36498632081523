import React from "react";
import AppStorage from "easy-app-storage";
import { QueryClient } from "react-query";

import {
    MultiProvider,
    ApiProvider,
    storageOptions,
    I18nProvider,
    StorageProvider,
    api,
    SpringBigThemeProvider,
    SpringBigStoreProvider,
    AppSettingsProvider,
    StashboardProvider
} from "@springbig/shared";

import Theme from "@configs/theme";
import Settings from "@configs/settings";
import AppRoutes from "./routes";

const appStorage = new AppStorage(localStorage, storageOptions);
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: 0
        }
    }
});

const App = () =>
    <MultiProvider
        providers={[
            <ApiProvider api={api} />,
            <StorageProvider storage={appStorage} />,
            <SpringBigThemeProvider themeConfig={Theme} />,
            <I18nProvider language={{}} />,
            <AppSettingsProvider {...Settings} />,
            <SpringBigStoreProvider queryClient={queryClient} />,
            <StashboardProvider />,
        ]}
    >
        <AppRoutes />
    </MultiProvider>;

export default App;
