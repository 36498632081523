/* eslint-disable @typescript-eslint/no-unused-vars */
import { Interest } from '@springbig/shared';
import { Box } from 'native-base';
import React from 'react';

import ItemCard from '~/shared/ItemCard';
import { H2 } from '~/shared/Typography';
import { useGeneralStyles, useProfileStyles } from '~/shared/styles';

type ProfileInterestProps = {
    interest?: Interest[];
    handleInterest: (name?: string) => void;
    updateIsLoading: boolean;
}

const ProfileInterest = ({ interest, handleInterest, updateIsLoading }:ProfileInterestProps) => {

    const {
        text
    } = useProfileStyles();
    const gStyles = useGeneralStyles();

    return (
        <Box px={'4'} w={'100%'} bg={gStyles.section_background}>
            <H2 text="interests.title" color={text}/>
            <Box alignItems='center'>
                <Box flexDirection="row" flexWrap={'wrap'} justifyContent='space-between' w={'100%'}>
                    {
                        interest && interest.map(data => {

                            return (
                                <Box
                                    key={data.id}
                                    mb={4}
                                    w='47%'
                                >
                                    <ItemCard
                                        customImageWidth={48}
                                        loadingComponent={true}
                                        boxProps={{ w: '100%' }}
                                        needsMark={true}
                                        handleCallback={(id, title, selectedInterest) => handleInterest(selectedInterest)}
                                        isDisabled={updateIsLoading}
                                        checked={data.checked}
                                        id={data.id}
                                        interest={data.name}
                                        title={data.display_name || data.name}
                                        imageUrl={data.image_url || undefined}
                                    />
                                </Box>
                            );
                        })
                    }
                </Box>
            </Box>
        </Box>
    );

};


export default ProfileInterest;
