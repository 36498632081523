import React from 'react';
import { Box } from 'native-base';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

import { SpIcon } from '~/shared/Icon';
import { Caption } from '~/shared/Typography';
import { useMenuStyles } from '~/shared/styles';
import { useAppNavigate } from '~/pages';
import Pressable from '~/shared/Pressable';


const profileMenuRoutes: any = (wallet_id: any) => [
    {
        title: 'My Information',
        path: `w/${wallet_id}/profile/menu/account`,
    },
    {
        title: 'Message and notification settings',
        path: `w/${wallet_id}/profile/menu/settings`,
    },
    {
        title: 'Recent History',
        path: `w/${wallet_id}/profile/menu/history`,
    }
];

const ProfileMenuOptions = () => {

    const {navigate, params}= useAppNavigate();
    const {
        icon,
        card_background,
        card_border,
        card_text
    } = useMenuStyles();

    return (
        <Box minH={'100%'} >
            {
                profileMenuRoutes(params.wallet_id).map((route: any, index: any) => (

                    <Box
                        bg={card_background}
                        borderColor={card_border}
                        borderWidth={1}
                        p={4}
                        mt={index === 0 ? 4 : 0}
                        mb={4}
                        key={route.title}
                    >
                        <Pressable
                            flexDirection={'row'}
                            justifyContent='space-between'

                            onPress={() => navigate(route.path)}>
                            <Caption
                                color={card_text}
                            >
                                {route.title}
                            </Caption>
                            <SpIcon
                                color={icon}
                                icon={faAngleRight}
                            />
                        </Pressable>
                    </Box>
                ))
            }
        </Box>
    );

};

export default ProfileMenuOptions;
