/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Box, FormControl, IInputProps, Input, WarningIcon } from 'native-base';

import { I18nReacher } from '@springbig/shared';

import { Caption, CaptionError } from './Typography';

export interface InputFormProps extends IInputProps {
    type: string;
    label: I18nReacher;
    labelColor: string;
    placeholder?: string;
    onChange: (value: never) => void;
    errors?: string | null ;
    required?: boolean
}

const InputForm = ({
    fontSize = 14,
    w = '100%',
    h = 12,
    type,
    onChange,
    label,
    errors,
    placeholder,
    required,
    defaultValue,
    labelColor = 'black',
    ...props
}: InputFormProps) => {

    return (
        <Box my={2} w={w}>
            <FormControl.Label>
                <Caption color={labelColor} text={label} fontSize={14}/>
            </FormControl.Label>
            <Input
                {...props}
                defaultValue={defaultValue}
                testID={label.trim()}
                type={type}
                h={h}
                fontSize={fontSize}
                placeholder={placeholder}
                onChangeText={onChange}
                isRequired={required}
            />
            {errors && (
                <FormControl.ErrorMessage mt={2}>
                    <Box flexDirection='row'  alignItems='center'>
                        <WarningIcon
                            size="4"
                            mr="2"
                        />
                        <CaptionError>{errors}</CaptionError>
                    </Box>
                </FormControl.ErrorMessage>
            )}
        </Box>
    );
};

export default InputForm;
