/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { themeTools } from 'native-base';
import { makeThemeColors } from '@springbig/shared';
import { colors } from './colors';
import { darkTheme } from './dark';
import { lightTheme } from './light';
const theme = {
    space: {
        'space-2': '29px',
    },
    colors: {
        ...colors,
        ...makeThemeColors(darkTheme, lightTheme),
    },
    components: {
        Box: {
            baseStyle: (props) => ({
                bg: themeTools.mode('transparent', 'transparent')(props),
            }),
        },
        Button: {
            baseStyle: (props) => ({
                color: themeTools.mode('black', 'white')(props)
            }),
        },
        Text: {
            baseStyle: (props) => ({
                color: themeTools.mode('black', 'white')(props)
            })
        },
        VStack: {
            baseStyle: (props) => ({
                bg: themeTools.mode('transparent', 'transparent')(props)
            }),
        },
        Radio: {
            baseStyle: (props) => ({
                color: themeTools.mode('black', 'red')(props)
            }),
        },
        Divider: {
            baseStyle: (props) => ({
                bg: themeTools.mode('neutral', 'neutral')(props)
            })
        },
        SliderFilledTrack: {
            baseStyle: (props) => ({
                bg: themeTools.mode('primary', 'primary')(props)
            })
        },
        SliderTrack: {
            baseStyle: (props) => ({
                bg: themeTools.mode('neutral', 'neutral')(props)
            })
        },
    },
};
export default theme;
