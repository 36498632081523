import { faGift } from "@fortawesome/free-solid-svg-icons";
import OffersScreen from "./Offer";

export type OfferRoutes = Record<
    (
        `w/${string}/offers`
    ),
    RouteProps
>

const routes: OfferRoutes = {
    'w/:wallet_id/offers': {
        path: 'w/:wallet_id/offers',
        title: 'Offers',
        component: OffersScreen,
        auth: true,
        exact: true,
        showTab: true,
        icon: faGift,
    },
}

export default routes;
