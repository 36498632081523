/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';

import { Center, Modal, Checkbox, Box, View, Button as ButtonNativeBase } from 'native-base';

import Button from './Button';
import Signature from './Sign';
import { Caption, CaptionError, H3 } from './Typography';
import { useFormStyles, useGeneralStyles, useModalStyles } from './styles';

export type AlertProps = {
    title: string;
    subHeaderText?: string;
    checkMessage?: boolean;
    signature?: boolean;
    signatureHeight?: number;
    setSignature?: (signature: string) => void;
    message: string
    showAlert: boolean
    setCancelAlert: (status: boolean) => void
    setAcceptAlert: (status: boolean) => void
    textConfirmButton?: string
    textCancelButton?: string
    testID?: string;
}

const Alert = ({
    signatureHeight,
    signature,
    setSignature,
    checkMessage,
    subHeaderText,
    title,
    message,
    showAlert,
    setCancelAlert,
    setAcceptAlert,
    textCancelButton,
    textConfirmButton,
    testID }: AlertProps) => {

    const [checked, setChecked] = React.useState<boolean>(false);
    const [error, setError] = React.useState<string | null>(null);
    const {
        text,
        background,
        overlay_background,
        header_background,
        body_background,
        footer_background,
    } = useModalStyles();
    const {
        input_checkbox_background,
        input_checkbox_border,
        input_checkbox_icon
    } = useFormStyles();
    const gStyle = useGeneralStyles();

    const handleAccept = () => {

        if (!checkMessage) {

            setAcceptAlert(false);
        }
        else if (checkMessage && !checked) {
            setError('Please accept the terms and conditions');
        }
        else if (checkMessage && checked) {
            setChecked(false);
            setAcceptAlert(false);

        }

    };

    return (
        <Center>
            <Modal
                height={'auto'}
                top={'22%'}
                isOpen={showAlert}
                // bg={overlay_background}
                onClose={() => setCancelAlert(false)}
            >
                <Modal.Content
                    bg={background}
                    maxWidth="400px"
                >
                    <Modal.CloseButton
                        bg={gStyle.button_neutral_background}
                        _icon={{
                            color: gStyle.button_neutral_text,
                        }}
                    />
                    <Modal.Header
                        bg={header_background}
                        textAlign={'center'}
                        paddingRight={20}
                        borderBottomColor={gStyle.divider}
                    >
                        <H3
                            color={text}
                        >
                            {title}
                        </H3>
                        {
                            subHeaderText && (
                                <Caption
                                    color={text}
                                    mt={1}
                                    fontSize='12'
                                    fontWeight='light'
                                >
                                    {subHeaderText}
                                </Caption>
                            )
                        }
                    </Modal.Header>
                    <View
                        bg={body_background}
                        h={signature && signatureHeight || 'auto'}
                        pl={4}
                        pr={ signature ? 4 : 8}
                        pt={2}
                    >
                        <Box
                            flexDirection={'row'}
                            w={'100%'}
                        >
                            {
                                checkMessage && (
                                    <Checkbox
                                        bg={input_checkbox_background}
                                        borderColor={input_checkbox_border}
                                        _checked={{
                                            value: 'check',
                                            _icon: {
                                                color: input_checkbox_icon,
                                            }
                                        }}
                                        value='check'
                                        isChecked={checked}
                                        onChange={(value) => {

                                            setError(null);
                                            setChecked(value);
                                        }}
                                    >
                                        <Caption></Caption>
                                    </Checkbox>
                                )
                            }
                            <Caption
                                color={text}
                                w={'100%'}
                                pr={checkMessage ? 6 : 0}
                                fontSize={11}
                                ml={2}
                            >
                                {message}
                            </Caption>
                        </Box>
                        {
                            signature && <Box h={signatureHeight ? 250 : 150} mt={4}>
                                <View h={signatureHeight ? 250 : 150}>
                                    <Signature onChange={value => {

                                        setError(null);
                                        setSignature && setSignature(value);
                                    }} />
                                </View>
                            </Box>
                        }
                    </View>
                    <Modal.Body w={'100%'} pt={0} >
                        {error && <CaptionError fontSize={11} textAlign='center'>{error}</CaptionError>}
                    </Modal.Body>
                    <Modal.Footer
                        bg={footer_background}
                    >
                        <ButtonNativeBase.Group
                            space={2}
                        >
                            <Button
                                testID='alert-cancel'
                                variant="ghost"
                                bg={gStyle.button_danger_background}
                                _text={{
                                    color: gStyle.button_danger_text,
                                }}
                                onPress={() => {

                                    setError(null);
                                    setChecked(false);
                                    setCancelAlert(false);
                                }}
                            >
                                {textCancelButton || 'Cancel'}
                            </Button>
                            <Button
                                testID='alert-accept'
                                bg={gStyle.button_background}
                                _text={{
                                    color: gStyle.button_text,
                                }}
                                onPress={() => {

                                    handleAccept();
                                }}
                            >
                                {textConfirmButton || 'Accept'}
                            </Button>
                        </ButtonNativeBase.Group>
                    </Modal.Footer>
                </Modal.Content>
            </Modal>
        </Center>
    );
};

export default Alert;
