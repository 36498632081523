/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useState, useCallback, useEffect } from 'react';
import { Box, Modal } from 'native-base';
import { faAngleLeft, faStar } from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import { Image as ImageProps } from '@springbig/shared';

import Image from './Image';
import Button from './Button';
import { SpIcon } from './Icon';
import { useModalStyles } from './styles';
import { Caption, H1, H2 } from './Typography';

dayjs.extend(relativeTime);

export type RedeemedRewardProps = {
    memberPhoneNumber: string;
    createdAt: string;
    isOpen: boolean;
    title: string;
    image?: ImageProps[];
    description: string;
    expires?: string | null;
    confirmationNumber: number;
    returnWallet: () => void;
}

const RedeemedRewardModal = ({ image, memberPhoneNumber, createdAt, isOpen, title, returnWallet, expires, confirmationNumber }: RedeemedRewardProps) => {

    const styles = useModalStyles();

    return (
        <Modal
            isOpen={isOpen}
            height={'auto'}
            top={'12%'}
            // bg={styles.overlay_background}
        >
            <Box
                justifyContent={'center'}
                alignItems='center'
                w={'400px'}
                minH={'600px'}
                rounded={8}
                bg={styles.background}
            >
                <H2
                    color={styles.text}
                >
                    {memberPhoneNumber}
                </H2>

                <H2
                    color={styles.text}
                    my={0}
                >
                    Redeemed at: {dayjs(createdAt).format('h:mm:ss a')}
                </H2>

                {
                    expires && <ShowCounter expirationTime={expires} />
                }

                <H1
                    color={styles.text}
                    textAlign={'center'}
                >
                    {title}
                </H1>
                {
                    image && image.length > 0 ? (
                        <Image
                            uri={`${image[0].url }`}
                            w={40}
                            h={40}
                            rounded={8}
                        />
                    ) :  <SpIcon icon={faStar} size={180}/>
                }

                <H2
                    textAlign={'center'}
                    mb={0}
                    color={styles.text}
                >
                    Redeemed
                </H2>
                {
                    expires && (
                        <Caption
                            color={styles.text}
                            textAlign={'center'}
                        >
                            Valid until {`${dayjs(expires).format('h:mm a')}, ${dayjs(expires).format('dddd MMM DD')}` || ''}
                        </Caption>
                    )
                }

                <H2
                    color={styles.text}
                    textAlign={'center'}
                    mt={4}
                >
                    Confirmation: {confirmationNumber}
                </H2>

                <Button
                    bg={styles.button_background}
                    onPress={() => returnWallet()}
                    justifyContent='center'
                    alignItems={'center'}
                    w={'70%'}
                >

                    <H1
                        color={styles.button_text}
                    >
                        <SpIcon
                            color={styles.button_icon}
                            icon={faAngleLeft}
                        />
                        Return to wallet
                    </H1>
                </Button>
            </Box>
        </Modal>
    );
};

export default RedeemedRewardModal;


const ShowCounter = ({ expirationTime }: { expirationTime: string }) => {

    const [time] = useCountdown({ expirationTime });
    const styles = useModalStyles();

    return (
         time && <H2 color={styles.text} my={0}>Expires:
            {
                time?.expired ? 'Expired!' : `${time?.days}d ${time?.hours}h ${time?.minutes}m ${time?.seconds}s` 
            }
        </H2> || <></>
    );
};

type CountDownTimeProps = {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
    expired?: boolean;
}

const useCountdown = ({ expirationTime }: { expirationTime: string }) => {

    const [time, setTime] = useState<CountDownTimeProps>();

    const handleCalculateDifference = useCallback((expiredAtDate?: string) => {

        const created = new Date().getTime();
        const expired = new Date(expiredAtDate!).getTime();

        const _second = 1000;
        const _minute = _second * 60;
        const _hour = _minute * 60;
        const _day = _hour * 25;

        const distance = expired - created;

        const days = Math.floor(distance / _day);
        const hours = Math.floor((distance % _day) / _hour);
        const minutes = Math.floor((distance % _hour) / _minute);
        const seconds = Math.floor((distance % _minute) / _second);

        if (distance <= 0) {
            return { days: 0, hours: 0, minutes: 0, seconds: 0, expired: true };
        }

        return {
            days,
            hours,
            minutes,
            seconds
        };
    }, []);

    useEffect(() => {

        if (expirationTime) {

            const interval = setInterval(() => {

                setTime(handleCalculateDifference(expirationTime));
            }, 1000);

            return () => {

                clearInterval(interval);
            };
        }
    }, [expirationTime, handleCalculateDifference]);

    return [time];
};
