import React, { PropsWithChildren } from 'react';
import { Box } from 'native-base';

import { useAppSettings, useSpringBigStore } from '@springbig/shared';

import { Caption, Hr } from '~/shared/Typography';
import CopyButton from '~/shared/CopyButton';

const DeviceFact = (
    { name, value, dir = 'row' }: PropsWithChildren<{
        name: string;
        value: string | number | Date;
        dir?: 'row' | 'column'
    }>
) => {

    return <Box mb={3}>
        <Box
            flexDirection={'row'}
            justifyItems={'center'}
            alignItems={'center'}
            // mb={1}
        >
            <Caption key={1} fontSize={12} bold={true}>{ name + ':' }</Caption>
            <CopyButton
                key={0}
                copyText={name + ': ' + value}
                py={0.1}
                px={1}
                ml={3}
                maxW={100}
                bg='gray'
                opacity={0.2}
            />
        </Box>

        <Caption
            key={2}
            fontSize={12}
            fontFamily={'Courier'}
        >
            { value.toString() }
        </Caption>
    </Box>;

};

const DeviceInfoComponent = () => {

    const { merchantOrg, ...rest } = useAppSettings();
    const store = useSpringBigStore();

    const nonFunctions = Object.entries(store).filter(([, val]) => typeof val !== 'function');
    const storeObj = Object.fromEntries(nonFunctions);

    return (

        <Box w={'75%'} mt={10}>
            <DeviceFact name='Merchant' value={merchantOrg} />
            {/* <DeviceFact name='Version' value={DeviceInfo.getReadableVersion()} /> */}
            {/* <DeviceFact name='Device' value={DeviceInfo.getBrand() + ' ' + DeviceInfo.getModel()} /> */}
            {/* <DeviceFact name='OS' value={DeviceInfo.getSystemName() + ' ' + DeviceInfo.getSystemVersion()} /> */}
            {/* <DeviceFact name='Bundle ID' value={DeviceInfo.getBundleId()} /> */}
            <Hr />
            <DeviceFact name='App Config' value={JSON.stringify(rest, null, 4)} dir='column' />
            <Hr />
            <DeviceFact name='State' value={JSON.stringify(storeObj, null, 4)} dir='column' />
        </Box>
    );
};

export default DeviceInfoComponent;
