import {
    Pressable,
    IPressableProps
} from 'native-base';
import { F } from 'ts-toolbelt';

export type OurPressableProps = IPressableProps & { onPress?: F.Function; testID?: string }

const OurPressable = (
    props: OurPressableProps,
) => <Pressable
    {...props}
/>

export default OurPressable;