import { useLocation, useNavigate, useParams } from "react-router";
import authRoutes from "./auth/routes";
import rewardRoutes from "./reward/routes";
import offerRoutes from "./offer/routes";
import milestoneRoutes from "./milestone/routes";
import referRoutes from "./refer/routes";
import messageRoutes from "./message/routes";
import profileRoutes from "./profile/routes";
import qaRoutes from "./qa/routes";
import cartRoutes from "./cart/routes";
import menuRoutes from "./menu/routes";
import NotFound from "./404/NotFound";
export const useAppNavigate = () => {
    const navigation = useNavigate();
    const location = useLocation();
    const params = useParams();
    const navigate = (route, opts) => navigation(`/${route}`, opts);
    return {
        location,
        navigate,
        params,
    };
};
const routes = [
    authRoutes,
    rewardRoutes,
    milestoneRoutes,
    cartRoutes,
    offerRoutes,
    messageRoutes,
    profileRoutes,
    referRoutes,
    qaRoutes,
    menuRoutes,
    {
        '404': {
            path: '404',
            title: '404',
            component: NotFound,
            auth: false,
            exact: true,
        },
    }
].map((route) => Object.values(route)).flat();
export default routes;
