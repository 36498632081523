"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    colors: ()=>colors,
    makeThemeColors: ()=>makeThemeColors
});
const baseColors = {
    primary: '#399BF8',
    secondary: '#9fcdf5',
    success: '#28a745',
    warning: '#ffc107',
    danger: '#dc3545',
    info: '#17a2b8',
    red: '#FF0000',
    yellow: '#ffc107',
    green: '#28a745',
    blue: '#17a2b8',
    purple: '#6f42c1',
    gray: '#818b8f',
    grayDark: '#545454',
    grayLight: '#dcdcdd',
    light: '#f7f7f7',
    dark: '#0D1821',
    transparencyLight: '#f7f7f7B3',
    transparencyDark: '#0D1821B3',
    white: '#ffffff',
    black: '#000000'
};
const colors = {
    ...baseColors
};
const makeThemeColors = (dark, light)=>{
    const lightEntries = Object.entries(dark).map(([k, val])=>[
            `${k}:dark`,
            val
        ]);
    const darkEntries = Object.entries(light).map(([k, val])=>[
            `${k}:light`,
            val
        ]);
    return Object.fromEntries(lightEntries.concat(darkEntries));
};
