import React from "react";
import { Box, useColorMode } from "native-base";
import { faAngleLeft, faHome, faMoon, faSun, faBars } from "@fortawesome/free-solid-svg-icons";

import { SpIcon } from "./Icon";
import { LogoMd } from "./Image";
import Pressable from "./Pressable";
import { Caption } from "./Typography";
import { useTopNavigationStyles } from "./styles";
import AppRoutes, { useAppNavigate } from "~/pages";
import { useAuthorization } from "~/hooks/auth";
import Loading from "./Loading";

const TopNavigation = ({ isMenu }: {isMenu: boolean}) => {
    const { icon, background, divider, text } = useTopNavigationStyles();

    const { location, navigate, params } = useAppNavigate();

    const currentPath = location.pathname;
    const goBackRoute = AppRoutes.find(route => `/${route.path}` === currentPath)?.goBackPath;

    const {isLoadingAuth} = useAuthorization();

    if(isLoadingAuth){
        return <Loading isLoading={isLoadingAuth}/>;
    }

    return (
        <Box
            flexDirection={"row"}
            justifyContent="space-between"
            alignItems="center"
            p={4}
            zIndex={9999}
            position={'relative'}
            bg={background}
            borderBottomColor={divider}
        >
            <Pressable>
                {goBackRoute
                    ? <Pressable flexDirection={"row"} alignItems="center" onPress={() => navigate(goBackRoute)}>
                          <SpIcon color={icon} icon={faAngleLeft} />
                          <Caption
                              color={text}
                              fontSize={16}
                              text={"general.back"}
                          />
                      </Pressable>
                    : <LogoMd resizeMode="contain" ml={4} />}
            </Pressable>
            <Box />

            <Box alignItems={'center'} flexDirection={'row'}>
            {/* { forceColorMode ? null : (
                <Pressable
                    onPress={() => toggleColorMode()}
                    borderRadius={50}
                    p={1}
                    mr={6}
                >
                    {colorMode === 'light' ? <SpIcon
                        size={20}
                        color={icon}
                        icon={faSun}
                    /> : <SpIcon
                        color={icon}
                        size={20}
                        icon={faMoon}
                    />
                    }
                </Pressable>
            )} */}
            {isMenu && (
              <Pressable
                  testID="hamburger-menu"
                  borderRadius={50}
                  p={1}
                  onPress={() => navigate(`w/${params.wallet_id}/menu`)}
              >
                  <SpIcon
                      color={icon}
                      size={20}
                      icon={faBars}
                  />
              </Pressable>
            )}
            </Box>
        </Box>
    );
};

export default TopNavigation;
