/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Box } from 'native-base';
import { faFacebook, faInstagram, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';

import { SpIcon } from '~/shared/Icon';
import { Caption } from '~/shared/Typography';
import { useGeneralStyles, useReferStyles } from '~/shared/styles';
import Pressable from '~/shared/Pressable';

type ReferSocialMediaProps = {
    copyToClipboard: (link:string) => void;
}

const ReferSocialMedia = ({ copyToClipboard }:ReferSocialMediaProps) => {

    const {
        text,
        social_facebook_icon_color,
        social_twitter_icon_color,
        social_linkedin_icon_color,
        social_instagram_icon_color,
    } = useReferStyles();

    const gStyle = useGeneralStyles();


    const logicColorSocialMedia = (socialMedia: string) => {

        if (socialMedia === 'facebook') {
            return social_facebook_icon_color;
        }

        if (socialMedia === 'twitter') {
            return social_twitter_icon_color;
        }

        if (socialMedia === 'linkedin') {
            return social_linkedin_icon_color;
        }

        if (socialMedia === 'instagram') {
            return social_instagram_icon_color;
        }

        return 'black';
    };

    return (
        <Box
            bg={gStyle.section_background}
            w={'100%'}
            p={4}
            mb={4}
        >
            <Caption
                color={text}
                textAlign={'center'}
                mb={2}
                text='refer.text_social_media'
            />
            <Box
                flexDirection={'row'}
                alignItems='center'
                justifyContent={'space-evenly'}
            >
                {
                    dummySocial.map((social, index) => {

                        return (
                            <Pressable key={`${index}-${social}`} onPress={() => copyToClipboard(social.link)}>
                                <SpIcon
                                    color={logicColorSocialMedia(social.name)}
                                    icon={social.icon}/>
                            </Pressable>
                        );
                    }
                    )
                }
            </Box>
        </Box>
    );
};

export default ReferSocialMedia;

const dummySocial = [
    {
        name: 'facebook',
        icon: faFacebook,
        link: 'https://www.facebook.com'
    },{
        name: 'twitter',
        icon: faTwitter,
        link: 'https://twitter.com'
    },{
        name: 'instagram',
        icon: faInstagram,
        link: 'https://www.instagram.com'
    },{
        name: 'linkedin',
        icon: faLinkedin,
        link: 'https://www.linkedin.com'
    }
];
