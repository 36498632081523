import React, { useEffect, useRef,useState } from "react";
import { Box } from "native-base";
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';
import {EffectFade, Mousewheel, Pagination, Keyboard} from 'swiper';
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { StashboardMedia } from "@springbig/shared";

import { Caption } from "./Typography";
import { useMessagesStyles } from "./styles";
import { CarouselCard } from "~/pages/message/components/MessageCarouselCard";


type CarouselProps = {
    images?: StashboardMedia[]
}

const Carousel = ({images}: CarouselProps) => {

    const {
        slider_counter_background,
        slider_counter,
        slider_dots_background,
    } = useMessagesStyles();
    const [snapIndex, setSnapIndex] = useState(0);

    useEffect(() => {
        document.querySelector('.swiper-pagination-bullet-active')?.setAttribute('style', `background-color: ${slider_dots_background} !important`);
    })

    return (
            <Swiper

            spaceBetween={0}
            navigation={false}
            pagination={{
              clickable: true,
              type: 'bullets'
            }}
            mousewheel={true}
            keyboard={true}
            modules={[ Pagination, Mousewheel, Keyboard ]}
            className="mySwiper"
            style={{
                width: '100%',
                // height: '300px',
                zIndex: 9099999
            }}
            onSlideChange={(swiper) => {

                setSnapIndex(swiper.snapIndex)
            }}

      >
        {images && images.length > 0 && images.map((el, index) => (
            <SwiperSlide
                style={{
                    width: '100%',
                    height: '100%',
                }}
                key={index}
                >
                <CarouselCard index={index} item={el}/>
            </SwiperSlide>
        ))}
{
                images && images.length > 1 && (
                    <Box
                        position={'absolute'}
                        top={-30}
                        right={4}
                        mt={12}
                        px={2}
                        bg={slider_counter_background}
                        alignItems='center'
                        justifyContent={'center'}
                        flexDirection='row'
                        borderRadius={8}
                        zIndex={1}
                    >
                        <Caption
                            color={slider_counter}
                            fontSize={12}
                        >
                            {snapIndex + 1}/{images.length}
                        </Caption>
                    </Box>
                )
            }
        </Swiper>

    )

}

export default Carousel;
