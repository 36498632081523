"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    AssertationError: ()=>AssertationError,
    assert: ()=>assert
});
class AssertationError extends Error {
}
const assert = (test, message)=>{
    if (test === false) {
        throw new AssertationError(message || 'assertion failed');
    }
};
