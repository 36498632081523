import React from "react";
import { Box } from "native-base";

import { useAppSettings, useSpringBigStore } from "@springbig/shared";

import BottomNavigation from "./BottomNavigation";
import TopNavigation from "./TopNavigation";
import { useAppNavigate } from "~/pages";

const Layout = ({ children }: {children: React.ReactNode}) => {

    const { member } = useSpringBigStore();
    const { location } = useAppNavigate();
    const { navType } = useAppSettings();

    const isAuth = location.pathname.includes('auth');

    return (
        <Box
        w={'100%'}
        h={'100vh'}
        justifyContent={'center'}
        alignItems={'center'}
        bg={'grayLight'}
        >
            <Box
                w={'440px'}
                h={'100vh'}
                justifyContent={'center'}
                display={'flex'}
            >
                {
                    member && !isAuth && <TopNavigation isMenu={navType === 'stack'} />
                }
                <Box h='95vh' overflow='scroll'>
                    {children}
                </Box>
                {
                    member && !isAuth && navType !== 'stack' && <BottomNavigation />
                }
            </Box>
        </Box>
    );
}

export default Layout;