import { faBurger } from "@fortawesome/free-solid-svg-icons";
import MenuScreen from './Menu';
const routes = {
    'w/:wallet_id/menu': {
        path: 'w/:wallet_id/menu',
        title: 'Menu',
        component: MenuScreen,
        auth: true,
        exact: true,
        showTab: false,
        icon: faBurger
    }
};
export default routes;
