/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Box } from 'native-base';

import { Member } from '@springbig/shared';

import { Caption } from '~/shared/Typography';
import { useGeneralStyles, useReferStyles } from '~/shared/styles';
import Button from '~/shared/Button';

type ReferCopyLinkProps = {
    copyToClipboard: (link:string) => void;
    link: string;
    member?: Member | null;
}

const ReferCopyLink = ({ copyToClipboard, link, member }: ReferCopyLinkProps) => {

    const {
        text
    } = useReferStyles();
    const gStyle = useGeneralStyles();

    return (
        <Box
            w={'100%'}
            p={4}
            mb={4}
            bg={gStyle.section_background}
        >
            <Caption
                color={text}
                text='refer.text_link'
                textAlign={'center'}
            />
            <Caption
                mt={2}
                fontWeight={'bold'}
                color={gStyle.link}
                textAlign={'center'}
            >
                {member && member.referrals?.link}
            </Caption>
            <Button
                testID='refer-copy-link-btn'
                bg={gStyle.button_background}
                _text={{
                    color: gStyle.button_text
                }}
                w={'50%'}
                ml={'25%'}
                mt={2}
                onPress={() => copyToClipboard(member && member.referrals?.link || '')}
            >
                {link}
            </Button>
        </Box>
    );
};

export default ReferCopyLink;
