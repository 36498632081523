/* eslint-disable max-lines */ /* eslint-disable max-lines-per-function */ /* eslint-disable @typescript-eslint/no-explicit-any */ "use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    makeObserver: ()=>makeObserver,
    observer: ()=>observer
});
class EventTrace extends Error {
}
const traceStackFilterRgx = new RegExp(`(${[
    'traceFn',
    'node_modules',
    'emitterSpy',
    'listenerSpy',
    'observable/observer'
].join('|')})`);
const makeTracer = (fn, event, listenerOrData)=>{
    const err = new Error();
    const split = err.stack.split('\n');
    split.shift();
    const filtered = split.filter((line)=>!traceStackFilterRgx.test(line));
    const message = `EventStack (${fn} ${event.toString()}):`;
    filtered.unshift(message);
    const stack = filtered.join('\n');
    const tracer = new EventTrace(message);
    tracer.name = message;
    tracer.func = fn;
    tracer.event = event;
    tracer.stack = stack;
    tracer.listener = null;
    tracer.data = null;
    if (typeof listenerOrData === 'function') {
        tracer.listener = listenerOrData;
    } else {
        tracer.data = listenerOrData;
    }
    return tracer;
};
const rgxStrToRgx = (rgxStr)=>{
    const split = rgxStr?.replace(/^\//, '').split('/');
    const flags = split.pop();
    const expr = split.join('/');
    return RegExp(expr, flags);
};
const enableDebugging = (observer)=>{
    const shouldNotLogEvent = (event)=>{
        return observer._$eventRgx && !observer._$eventRgx.test(event.toString());
    };
    observer._$modifyOpts({
        listenerSpy: (fn, event, listener)=>{
            if (shouldNotLogEvent(event)) {
                return;
            }
            console.info(makeTracer(fn, event, listener));
        },
        emitterSpy: (event, data)=>{
            if (shouldNotLogEvent(event)) {
                return;
            }
            console.info(makeTracer('emit', event, data));
        }
    });
};
const makeObserver = (opts)=>{
    let { listenerSpy , emitterSpy  } = opts;
    const listenerMap = new Map();
    const rgxListenerMap = new Map();
    const getOrCreateCallbacks = (event)=>{
        if (typeof event === 'string') {
            let listeners = listenerMap.get(event);
            if (!listeners) {
                listeners = new Set();
                listenerMap.set(event, listeners);
            }
            return listeners;
        }
        if (event.constructor === RegExp) {
            const rgxStr = event.toString();
            let listeners = rgxListenerMap.get(rgxStr);
            if (!listeners) {
                listeners = new Set();
                rgxListenerMap.set(rgxStr, listeners);
            }
            return listeners;
        }
        return new Set();
    };
    const getMatchingEvents = (eventRgx)=>{
        return [
            ...listenerMap.keys()
        ].filter((k)=>eventRgx.test(k));
    };
    const getMatchingEventCallbacks = (eventRgx)=>{
        const listeners = [];
        const events = getMatchingEvents(eventRgx);
        for (const key of events){
            for (const fn of listenerMap.get(key) || []){
                listeners.push(fn);
            }
        }
        return listeners;
    };
    const listen = (event, listener)=>{
        if (typeof event === 'string') {
            const listeners = getOrCreateCallbacks(event);
            listeners.add(listener);
        }
        if (event.constructor === RegExp) {
            const listeners = getOrCreateCallbacks(event);
            listeners.add(listener);
        }
    };
    const remove = (event, listener)=>{
        const rmListener = (ev)=>{
            if (typeof ev === 'string') {
                const listeners = getOrCreateCallbacks(ev);
                listeners.delete(listener);
            }
            if (ev.constructor === RegExp) {
                const listeners = getOrCreateCallbacks(ev);
                listeners.delete(listener);
                const events = getMatchingEvents(ev);
                for (const key of events){
                    rmListener(key);
                }
            }
        };
        const rmAllListeners = (ev)=>{
            if (typeof ev === 'string') {
                listenerMap.set(ev, new Set());
            }
            if (ev.constructor === RegExp) {
                rgxListenerMap.set(ev.toString(), new Set());
                const events = getMatchingEvents(ev);
                for (const key of events){
                    rmAllListeners(key);
                }
            }
        };
        if (listener) {
            if (typeof event === 'string') {
                rmListener(event);
            }
            if (event.constructor === RegExp) {
                for (const key of getMatchingEvents(event)){
                    rmListener(key);
                }
                rmListener(event);
            }
        } else {
            rmAllListeners(event);
            if (event.constructor === RegExp) {
                for (const key of getMatchingEvents(event)){
                    rmAllListeners(key);
                }
            }
        }
    };
    const trigger = async (event, data)=>{
        let eventlisteners = [];
        let regexlisteners = [];
        if (typeof event === 'string') {
            // Get the string event listeners
            const evListeners = listenerMap.get(event) || new Set();
            // Get the rgx listeners to see which match
            const rgxListeners = [
                ...rgxListenerMap.keys()
            ].filter((rgxStr)=>{
                // Convert stored regex string to a regex
                const rgx = rgxStrToRgx(rgxStr);
                // Use if event matches rgx
                return rgx.test(event);
            }).map(// fetch those listeners
            (rgxKey)=>rgxListenerMap.get(rgxKey)).filter(Boolean).map((_set)=>[
                    ..._set
                ]).flat();
            eventlisteners = [
                ...evListeners
            ];
            regexlisteners = [
                ...rgxListeners
            ];
        }
        if (event.constructor === RegExp) {
            eventlisteners = getMatchingEventCallbacks(event);
        }
        await Promise.all(eventlisteners.map((listener)=>listener(data)));
        await Promise.all(regexlisteners.map((listener)=>listener({
                event,
                data
            })));
    };
    const _$modifyOpts = (_opts)=>{
        const newOpts = {
            ...opts,
            ..._opts
        };
        listenerSpy = newOpts.listenerSpy;
        emitterSpy = newOpts.emitterSpy;
    };
    const _$internals = ()=>({
            listenerMap,
            rgxListenerMap
        });
    const emitter = {
        _$modifyOpts,
        _$internals,
        _$debug (debug = true) {
            if (debug) {
                enableDebugging(this);
            } else {
                _$modifyOpts({
                    emitterSpy: undefined,
                    listenerSpy: undefined
                });
            }
        },
        on (event, listener) {
            if (listenerSpy) {
                listenerSpy('on', event, listener);
            }
            listen(event, listener);
        },
        once (event, listener) {
            if (listenerSpy) {
                listenerSpy('once', event, listener);
            }
            const cleanup = ()=>{
                remove(event, _listener);
            };
            const _listener = (data)=>{
                cleanup();
                listener(data);
            };
            listen(event, _listener);
            return cleanup;
        },
        off (event, listener) {
            if (listenerSpy) {
                listenerSpy('off', event, listener);
            }
            remove(event, listener);
        },
        emit (event, data) {
            if (emitterSpy) {
                emitterSpy(event, data);
            }
            return trigger(event, data);
        }
    };
    return emitter;
};
const observerOpts = {};
const observer = makeObserver(observerOpts);
if (/(true|1)/i.test(process.env.DEBUG_EVENTS_LOG_LISTENERS || '')) {
    enableDebugging(observer);
}
