import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import reportWebVitals from './reportWebVitals';

import { api } from '@springbig/shared';

api.unsetHeaders(['app-service']);

(window as any).DEMO_MODE = DEMO_MODE

// Remove console warnings
console.warn = () => {}

ReactDOM.render(
    <App />,
    document.getElementById('root') as HTMLElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
