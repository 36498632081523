/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import dayjs from 'dayjs';
import { Box, HStack, IBoxProps, VStack } from 'native-base';
import { faGift, faStar } from '@fortawesome/free-solid-svg-icons';

import { RewardGrant, useI18n } from '@springbig/shared';

import Image from './Image';
import Button from "./Button"
import { SpIcon } from './Icon';
import { Caption, H3 } from './Typography';
import { useGeneralStyles, useOffersStyles } from './styles';

export interface RewardGrantCardProps extends IBoxProps {

    offer: RewardGrant
    handleAcceptRedeemReward: (id: number, title: string) => void;
    handleBonusRewards: (id: number) => void;
    rewardActiveId: number | undefined;
    loadingActivation: boolean;
}

const RewardGrantCard = ({
    offer,
    handleAcceptRedeemReward,
    handleBonusRewards,
    rewardActiveId,
    loadingActivation,
    ...props
}: RewardGrantCardProps) => {

    const { t } = useI18n();
    const {
        text,
        icon,
        button_earn_background,
        button_earn_text
    } = useOffersStyles();
    const gStyles = useGeneralStyles();

    const handleDate = (date?: string | null) => {

        if (date) {
            return dayjs(date).format('MM/DD/YY');
        }
    };

    return (
        <Box {...props}
            mb={2}
            px={2}
            py={4}
            bg={gStyles.section_background}
            w={'100%'}
        >
            <Box
                pl="2"
                w={'90%'}
                flexDirection='row'
                alignItems={'center'}
                justifyContent='center'
                mb={2}
            >
                <SpIcon
                    color={icon}
                    icon={faGift}
                />
                <Caption
                    color={text}
                    ml={2}
                    fontWeight="bold"
                    textAlign='center'
                >
                    {offer._type}
                </Caption>
            </Box>
            <HStack
                alignItems="center"
                w={'100%'}
                justifyContent="space-evenly"
            >
                <Box
                    w={24}
                    h={24}
                    borderRadius={12}
                    bg={gStyles.screen_background}
                    alignItems='center'
                    justifyContent={'center'}
                >
                    {
                        offer.reward.images.length > 1 ? (
                            <Image
                                w={'24'}
                                h={'24'}
                                p={3}
                                borderRadius={12}
                                uri={`${offer.reward.images[0].url}`}
                            />
                        ) : (
                            <SpIcon
                                color={icon}
                                icon={faStar}
                                size={80}
                            />
                        )
                    }
                </Box>
                <Box w={'45%'}>
                    <H3
                        color={text}
                    >
                        {offer.reward?.name}
                    </H3>
                    <Caption
                        color={text}
                        text='rewards.expires_at'
                        textValues={[`${handleDate(offer?.expiration)}`]}
                    />
                </Box>
                {
                    offer._type === 'bonus' ? (
                        <VStack
                            w={'20%'}
                            borderTopRadius={4}
                            borderBottomRadius={4}
                        >
                            <Button
                                bg={offer.id === rewardActiveId ? button_earn_background : gStyles.button_background}
                                _text={{
                                    color: offer.id === rewardActiveId ? button_earn_text : gStyles.button_text,
                                }}
                                isDisabled={offer?.redemptions_available! === 0 || loadingActivation}
                                h={50}
                                onPress={() => handleBonusRewards(offer.id!)}
                            >
                                {offer.id === rewardActiveId ? 'Earning' : 'Use'}
                            </Button>
                            <Box pl="2" w={'90%'} alignItems="center">
                                {
                            offer?.redemptions_available! === 0 ? (
                                <Caption
                                    color={text}
                                    text='general.redeemed'/>
                            ) : <Caption
                                color={text}
                            >
                                {offer.redemptions_available === -1 ? `unlimited` : `use ${offer.redemptions_available}x`}
                            </Caption>
                                }
                            </Box>
                        </VStack>
                    ) : (
                        <VStack w={'20%'} borderTopRadius={4}
                            borderBottomRadius={4}>

                            <Button
                                bg={gStyles.button_background}
                                _text={{
                                    color: gStyles.button_text,
                                }}
                                isDisabled={offer?.redemptions_available! === 0}
                                h={50}
                                onPress={() => handleAcceptRedeemReward(offer.id!, offer.reward.name)}
                            >
                                {t('general.get')}
                            </Button>
                            <Box pl="2" w={'90%'} alignItems="center">
                                {
                            offer?.redemptions_available! === 0 ? (
                                <Caption
                                    color={text}
                                    text='general.redeemed'
                                />
                            ) : <Caption
                                color={text}
                            >
                                {offer.redemptions_available === -1 ? `unlimited` : `use ${offer.redemptions_available}x`}
                            </Caption>
                                }
                            </Box>
                        </VStack>
                    )
                }

            </HStack>
        </Box>
    );
};

export default RewardGrantCard;
