
import React from 'react';
import { Box, Pressable, useToast as useNativeToast } from 'native-base';
import { Caption } from '~/shared/Typography';
import { SpIcon } from '~/shared/Icon';
import { faCircleCheck, faClose, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';

export const useToast = () => {

    const toast = useNativeToast();

    const successToast = (title: string, description?: string) => {

        const id = Math.random().toString(36).substring(7);

        toast.show({
            id,
            render: () => {

                return (
                    <Box
                        bg="success"
                        px="6"
                        py="3"
                        rounded="sm"
                        mb={8}
                        flexDirection='row'
                        justifyContent={'space-between'}
                        alignItems={'center'}
                    >
                        <Box
                            justifyContent='center'
                            alignItems='center'
                            flexDirection='row'
                        >
                            <SpIcon color='white' icon={faCircleCheck}/>
                            <Caption ml={2} color={'white'}>{title}</Caption>
                            {
                                description && <Caption ml={2} color={'white'}>{description}</Caption>
                            }
                        </Box>
                        <Pressable
                            alignItems={'flex-end'}
                            justifyContent='center'
                            w={'8'}
                            h={6}
                            // onPress={() => toast.close(id)}
                        >
                            <SpIcon  size={12} color='white' icon={faClose} />
                        </Pressable>
                    </Box>
                );
            },
            placement: 'bottom',
            duration: 4000,
            isClosable: true
        });
    };

    const errorToast = (title: string, description?: string) => {

        const id = Math.random().toString(36).substring(7);

        toast.show({
            id,
            render: () => {

                return (
                    <Box
                        bg="danger"
                        px="6"
                        py="3"
                        rounded="sm"
                        mb={8}
                        flexDirection='row'
                        justifyContent={'space-between'}
                        alignItems={'center'}
                    >
                        <Box
                            justifyContent='center'
                            alignItems='center'
                            flexDirection='row'
                        >
                            <SpIcon color='white' icon={faXmarkCircle}/>
                            <Caption ml={2} color={'white'}>{title}</Caption>
                            {
                                description && <Caption ml={2} color={'white'}>{description}</Caption>
                            }
                        </Box>
                        <Pressable
                            alignItems={'flex-end'}
                            justifyContent='center'
                            w={'8'}
                            h={6}
                            // onPress={() => toast.close(id)}
                        >
                            <SpIcon  size={12} color='white' icon={faClose} />
                        </Pressable>
                    </Box>
                );
            },
            placement: 'bottom',
            duration: 4000,
            isClosable: true
        });
    };

    return {
        successToast,
        errorToast
    };
};
