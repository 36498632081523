"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    darkColors: ()=>_dark.darkColors,
    lightColors: ()=>_light.lightColors,
    makeThemeColors: ()=>_colors.makeThemeColors,
    SpringBigThemeProvider: ()=>_context.SpringBigThemeProvider
});
const _dark = require("./dark");
const _light = require("./light");
const _colors = require("./colors");
const _context = require("./context");
