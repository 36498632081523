/* eslint-disable max-lines */
import { useColorModeValue, useColorMode } from 'native-base';
import theme from '@configs/theme';
// FROM HERE
export const useReferStyles = () => {
    const { colorMode } = useColorMode();
    let icon = theme.colors?.['refer:dark']?.icon;
    let qr_code_background = theme.colors?.['refer:dark']?.qr_code_background;
    let qr_code_color = theme.colors?.['refer:dark']?.qr_code_color;
    let social_facebook_icon_color = theme.colors?.['refer:dark']?.social_facebook_icon_color;
    let social_twitter_icon_color = theme.colors?.['refer:dark']?.social_twitter_icon_color;
    let social_linkedin_icon_color = theme.colors?.['refer:dark']?.social_linkedin_icon_color;
    let social_instagram_icon_color = theme.colors?.['refer:dark']?.social_instagram_icon_color;
    if (colorMode === 'light') {
        icon = theme.colors?.['refer:light']?.icon;
        qr_code_background = theme.colors?.['refer:light']?.qr_code_background;
        qr_code_color = theme.colors?.['refer:light']?.qr_code_color;
        social_facebook_icon_color = theme.colors?.['refer:light']?.social_facebook_icon_color;
        social_twitter_icon_color = theme.colors?.['refer:light']?.social_twitter_icon_color;
        social_linkedin_icon_color = theme.colors?.['refer:light']?.social_linkedin_icon_color;
        social_instagram_icon_color = theme.colors?.['refer:light']?.social_instagram_icon_color;
    }
    return {
        icon,
        qr_code_background,
        qr_code_color,
        social_facebook_icon_color,
        social_twitter_icon_color,
        social_linkedin_icon_color,
        social_instagram_icon_color,
        text: useColorModeValue('refer:light.text', 'refer:dark.text'),
    };
};
export const useMilestonesStyles = () => {
    const { colorMode } = useColorMode();
    let icon = theme.colors?.['milestones:dark']?.icon;
    if (colorMode === 'light') {
        icon = theme.colors?.['milestones:light']?.icon;
    }
    return {
        icon,
        text: useColorModeValue('milestones:light.text', 'milestones:dark.text'),
    };
};
export const useMessagesStyles = () => {
    const { colorMode } = useColorMode();
    let icon = theme.colors?.['messages:dark']?.icon;
    let link_box_icon = theme.colors?.['messages:dark']?.link_box_icon;
    let slider_dots = theme.colors?.['messages:dark']?.slider_dots;
    if (colorMode === 'light') {
        slider_dots = theme.colors?.['messages:light']?.slider_dots;
        icon = theme.colors?.['messages:light']?.icon;
        link_box_icon = theme.colors?.['messages:light']?.link_box_icon;
    }
    return {
        icon,
        slider_dots,
        link_box_icon,
        text: useColorModeValue('messages:light.text', 'messages:dark.text'),
        slider_counter_background: useColorModeValue('messages:light.slider_counter_background', 'messages:dark.slider_counter_background'),
        slider_counter: useColorModeValue('messages:light.slider_counter', 'messages:dark.slider_counter'),
        slider_dots_background: useColorModeValue('messages:light.slider_dots_background', 'messages:dark.slider_dots_background'),
        link_box_border: useColorModeValue('messages:light.link_box_border', 'messages:dark.link_box_border'),
        link_box_background: useColorModeValue('messages:light.link_box_background', 'messages:dark.link_box_background'),
        link_box_icon_background: useColorModeValue('messages:light.link_box_icon_background', 'messages:dark.link_box_icon_background'),
    };
};
export const useOffersStyles = () => {
    const { colorMode } = useColorMode();
    let icon = theme.colors?.['offers:dark']?.icon;
    if (colorMode === 'light') {
        icon = theme.colors?.['offers:light']?.icon;
    }
    return {
        icon,
        text: useColorModeValue('offers:light.text', 'offers:dark.text'),
        button_earn_background: useColorModeValue('offers:light.button_earn_background', 'offers:dark.button_earn_background'),
        button_earn_text: useColorModeValue('offers:light.button_earn_text', 'offers:dark.button_earn_text'),
    };
};
export const useRewardsStyles = () => {
    return {
        text: useColorModeValue('rewards:light.text', 'rewards:dark.text'),
    };
};
export const useItemCardStyles = () => {
    const { colorMode } = useColorMode();
    let heart_filled_icon_color = theme.colors?.['item_card:dark']?.heart_filled_icon_color;
    let heart_empty_icon_color = theme.colors?.['item_card:dark']?.heart_empty_icon_color;
    let star_icon_color = theme.colors?.['item_card:dark']?.star_icon_color;
    if (colorMode === 'light') {
        heart_filled_icon_color = theme.colors?.['item_card:light']?.heart_filled_icon_color;
        heart_empty_icon_color = theme.colors?.['item_card:light']?.heart_empty_icon_color;
        star_icon_color = theme.colors?.['item_card:light']?.star_icon_color;
    }
    return {
        heart_filled_icon_color,
        heart_empty_icon_color,
        star_icon_color,
        box_background: useColorModeValue('item_card:light.box_background', 'item_card:dark.box_background'),
        box_border: useColorModeValue('item_card:light.box_border', 'item_card:dark.box_border'),
        box_paragraph_text: useColorModeValue('item_card:light.box_paragraph_text', 'item_card:dark.box_paragraph_text'),
    };
};
export const useProfileStyles = () => {
    const { colorMode } = useColorMode();
    let icon = theme.colors?.['profile:dark']?.icon;
    if (colorMode === 'light') {
        icon = theme.colors?.['profile:light']?.icon;
    }
    return {
        icon,
        text: useColorModeValue('profile:light.text', 'profile:dark.text'),
    };
};
export const useStoreStyles = () => {
    return {
        text: useColorModeValue('store:light.text', 'store:dark.text'),
        background: useColorModeValue('store:light.background', 'store:dark.background'),
        link_text: useColorModeValue('store:light.link_text', 'store:dark.link_text'),
        link_border: useColorModeValue('store:light.link_border', 'store:dark.link_border'),
        link_background: useColorModeValue('store:light.link_background', 'store:dark.link_background'),
    };
};
export const useFormStyles = () => {
    const { colorMode } = useColorMode();
    let input_html_background = theme.colors?.['form:dark']?.input_background;
    let input_html_border = theme.colors?.['form:dark']?.input_border;
    let input_html_text = theme.colors?.['form:dark']?.input_text;
    if (colorMode === 'light') {
        input_html_background = theme.colors?.['form:light']?.input_background;
        input_html_border = theme.colors?.['form:light']?.input_border;
        input_html_text = theme.colors?.['form:light']?.input_text;
    }
    return {
        input_html_background,
        input_html_border,
        input_html_text,
        input_label: useColorModeValue('form:light.input_label', 'form:dark.input_label'),
        input_text: useColorModeValue('form:light.input_text', 'form:dark.input_text'),
        input_background: useColorModeValue('form:light.input_background', 'form:dark.input_background'),
        input_border: useColorModeValue('form:light.input_border', 'form:dark.input_border'),
        input_placeholder: useColorModeValue('form:light.input_placeholder', 'form:dark.input_placeholder'),
        input_checkbox_background: useColorModeValue('form:light.input_checkbox_background', 'form:dark.input_checkbox_background'),
        input_checkbox_border: useColorModeValue('form:light.input_checkbox_border', 'form:dark.input_checkbox_border'),
        input_checkbox_icon: useColorModeValue('form:light.input_checkbox_icon', 'form:dark.input_checkbox_icon'),
    };
};
export const useAuthStyles = () => {
    const { colorMode } = useColorMode();
    let pad_text = theme.colors?.['auth:dark']?.pad_text;
    let pad_placeholder = theme.colors?.['auth:dark']?.pad_placeholder;
    let icon = theme.colors?.['auth:dark']?.icon;
    if (colorMode === 'light') {
        pad_text = theme.colors?.['auth:light']?.pad_text;
        pad_placeholder = theme.colors?.['auth:light']?.pad_placeholder;
        icon = theme.colors?.['auth:light']?.icon;
    }
    return {
        icon,
        pad_text,
        pad_placeholder,
        background: useColorModeValue('auth:light.background', 'auth:dark.background'),
        text: useColorModeValue('auth:light.text', 'auth:dark.text'),
        signature_background: useColorModeValue('auth:light.signature_background', 'auth:dark.signature_background'),
        signature_text: useColorModeValue('auth:light.signature_text', 'auth:dark.signature_text'),
    };
};
export const useMenuStyles = () => {
    const { colorMode } = useColorMode();
    let icon = theme.colors?.['menu:dark']?.icon;
    if (colorMode === 'light') {
        icon = theme.colors?.['menu:light']?.icon;
    }
    return {
        icon,
        background: useColorModeValue('menu:light.background', 'menu:dark.background'),
        card_background: useColorModeValue('menu:light.card_background', 'menu:dark.card_background'),
        card_border: useColorModeValue('menu:light.card_border', 'menu:dark.card_border'),
        card_text: useColorModeValue('menu:light.card_text', 'menu:dark.card_text'),
    };
};
export const useBottomNavigationStyles = () => {
    const { colorMode } = useColorMode();
    let background = theme.colors?.['bottom_navigation:dark']?.background;
    let border = theme.colors?.['bottom_navigation:dark']?.border;
    let active_icon_color = theme.colors?.['bottom_navigation:dark']?.active_icon_color;
    let inactive_icon_color = theme.colors?.['bottom_navigation:dark']?.inactive_icon_color;
    if (colorMode === 'light') {
        background = theme.colors?.['bottom_navigation:light']?.background;
        border = theme.colors?.['bottom_navigation:light']?.border;
        active_icon_color = theme.colors?.['bottom_navigation:light']?.active_icon_color;
        inactive_icon_color = theme.colors?.['bottom_navigation:light']?.inactive_icon_color;
    }
    return {
        background,
        border,
        active_icon_color,
        inactive_icon_color,
    };
};
export const useTopNavigationStyles = () => {
    const { colorMode } = useColorMode();
    let icon = theme.colors?.['top_navigation:dark']?.icon;
    let background = theme.colors?.['top_navigation:dark']?.background;
    let divider = theme.colors?.['top_navigation:dark']?.divider;
    if (colorMode === 'light') {
        icon = theme.colors?.['top_navigation:light']?.icon;
        background = theme.colors?.['top_navigation:light']?.background;
        divider = theme.colors?.['top_navigation:light']?.divider;
    }
    return {
        icon,
        background,
        divider,
        text: useColorModeValue('top_navigation:light.text', 'top_navigation:dark.text'),
    };
};
export const useSubNavigationStyles = () => {
    const { colorMode } = useColorMode();
    let icon = theme.colors?.['sub_navigation:dark']?.icon;
    let background = theme.colors?.['sub_navigation:dark']?.background;
    if (colorMode === 'light') {
        icon = theme.colors?.['sub_navigation:light']?.icon;
        background = theme.colors?.['sub_navigation:light']?.background;
    }
    return {
        icon,
        background,
        text: useColorModeValue('sub_navigation:light.text', 'sub_navigation:dark.text'),
    };
};
export const useGeneralStyles = () => {
    const { colorMode } = useColorMode();
    let screen_background_hex = theme.colors?.['general:dark']?.screen_background;
    if (colorMode === 'light') {
        screen_background_hex = theme.colors?.['general:light']?.screen_background;
    }
    return {
        screen_background_hex,
        link: useColorModeValue('general:light.link', 'general:dark.link'),
        text: useColorModeValue('general:light.text', 'general:dark.text'),
        divider: useColorModeValue('general:light.divider', 'general:dark.divider'),
        screen_background: useColorModeValue('general:light.screen_background', 'general:dark.screen_background'),
        section_background: useColorModeValue('general:light.section_background', 'general:dark.section_background'),
        button_background: useColorModeValue('general:light.button_background', 'general:dark.button_background'),
        button_text: useColorModeValue('general:light.button_text', 'general:dark.button_text'),
        button_danger_background: useColorModeValue('general:light.button_danger_background', 'general:dark.button_danger_background'),
        button_danger_text: useColorModeValue('general:light.button_danger_text', 'general:dark.button_danger_text'),
        button_neutral_background: useColorModeValue('general:light.button_neutral_background', 'general:dark.button_neutral_background'),
        button_neutral_text: useColorModeValue('general:light.button_neutral_text', 'general:dark.button_neutral_text'),
        spinner_background: useColorModeValue('general:light.spinner_background', 'general:dark.spinner_background'),
        spinner_icon_color: useColorModeValue('general:light.spinner_icon_color', 'general:dark.spinner_icon_color'),
    };
};
export const useAccountHistoryStyles = () => {
    const { colorMode } = useColorMode();
    let icon = theme.colors?.['account_history:dark']?.icon;
    if (colorMode === 'light') {
        icon = theme.colors?.['account_history:light']?.icon;
    }
    return {
        icon,
        text: useColorModeValue('account_history:light.text', 'account_history:dark.text'),
        active_button_background: useColorModeValue('account_history:light.active_button_background', 'account_history:dark.active_button_background'),
        active_button_text: useColorModeValue('account_history:light.active_button_text', 'account_history:dark.active_button_text'),
        inactive_button_background: useColorModeValue('account_history:light.inactive_button_background', 'account_history:dark.inactive_button_background'),
        inactive_button_text: useColorModeValue('account_history:light.inactive_button_text', 'account_history:dark.inactive_button_text'),
    };
};
export const useAccountInformationStyles = () => {
    const { colorMode } = useColorMode();
    let icon = theme.colors?.['account_information:dark']?.icon;
    if (colorMode === 'light') {
        icon = theme.colors?.['account_information:light']?.icon;
    }
    return {
        icon,
    };
};
export const useAccountSettingsStyles = () => {
    return {
        text: useColorModeValue('account_settings:light.text', 'account_settings:dark.text'),
        swipe_background: useColorModeValue('account_settings:light.swipe_background', 'account_settings:dark.swipe_background'),
        swipe_inactive_background: useColorModeValue('account_settings:light.swipe_inactive_background', 'account_settings:dark.swipe_inactive_background'),
        thumb_active_background: useColorModeValue('account_settings:light.thumb_active_background', 'account_settings:dark.thumb_active_background'),
        thumb_inactive_background: useColorModeValue('account_settings:light.thumb_inactive_background', 'account_settings:dark.thumb_inactive_background'),
    };
};
export const useModalStyles = () => {
    const { colorMode } = useColorMode();
    let icon = theme.colors?.['modal:dark']?.icon;
    let button_icon = theme.colors?.['modal:dark']?.button_icon;
    if (colorMode === 'light') {
        icon = theme.colors?.['modal:light']?.icon;
        button_icon = theme.colors?.['modal:light']?.button_icon;
    }
    return {
        icon,
        button_icon,
        text: useColorModeValue('modal:light.text', 'modal:dark.text'),
        overlay_background: useColorModeValue('modal:light.overlay_background', 'modal:dark.overlay_background'),
        background: useColorModeValue('modal:light.background', 'modal:dark.background'),
        header_background: useColorModeValue('modal:light.header_background', 'modal:dark.header_background'),
        body_background: useColorModeValue('modal:light.body_background', 'modal:dark.body_background'),
        footer_background: useColorModeValue('modal:light.footer_background', 'modal:dark.footer_background'),
        button_background: useColorModeValue('modal:light.button_background', 'modal:dark.button_background'),
        button_text: useColorModeValue('modal:light.button_text', 'modal:dark.button_text'),
    };
};
export const useRangeBarStyles = () => {
    const { colorMode } = useColorMode();
    let dots_unreached = theme.colors?.['range_bar:dark']?.dots_unreached;
    let dots_reached = theme.colors?.['range_bar:dark']?.dots_reached;
    if (colorMode === 'light') {
        dots_unreached = theme.colors?.['range_bar:light']?.dots_unreached;
        dots_reached = theme.colors?.['range_bar:light']?.dots_reached;
    }
    return {
        dots_reached,
        dots_unreached,
        unfilled_track: useColorModeValue('range_bar:light.unfilled_track', 'range_bar:dark.unfilled_track'),
        dots_text: useColorModeValue('range_bar:light.dots_text', 'range_bar:dark.dots_text'),
        filled_track: useColorModeValue('range_bar:light.filled_track', 'range_bar:dark.filled_track'),
    };
};
