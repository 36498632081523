/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import { Image as NativeImage, Box, IImageProps, IBoxProps } from 'native-base';
import { useAppSettings, activateChangeEnv, useQaAgent, useSpringBigStore } from '@springbig/shared';

import { Txt } from './Typography';
import { useAppNavigate } from '~/pages';
import Pressable from './Pressable';

export interface ImageProps extends IImageProps {
    uri?: string;
    containerProps?: IBoxProps
}

const Image = ({ uri, ...props }: ImageProps) => {

    return (
        <Box {...props.containerProps}>{uri && <NativeImage alt={props.alt || 'Alternate Text'}
            source={{ uri: `${uri}` }} {...props} />}</Box>
    );
};

const useDevActions = () => {

    const {navigate }= useAppNavigate();
    const { isQaAgent } = useQaAgent();


    const onPress = () => {

        if (isQaAgent) {

            navigate('auth/select-env');
            return;
        }

        navigate('auth/secret-pin');
    };

    // return onPress;
    return () => activateChangeEnv(onPress);
};

const LogoImage = ({ ...props }: ImageProps) => {

    const onPress = useDevActions();
    const { isQaAgent } = useQaAgent();
    const { apiEnv } = useSpringBigStore();

    let stage: null | React.ReactNode = null;

    if (isQaAgent) {
        stage = (
            <Box
                bg='background'
                position={'absolute'}
                bottom={'-5'}
                right={'-10'}
                py={0}
                px={0.5}
                zIndex={99}
            ><Txt fontSize={10} color={'primary'}>{ apiEnv }</Txt></Box>
        );
    }

    return (
        <Pressable
        onPress={onPress}
        >
            { stage }
            <NativeImage {...props} />
        </Pressable>
    );
};

export const Logo2xl = ({ ...props }: ImageProps) => {

    const { images } = useAppSettings();

    return (
        <LogoImage
            alt="Alternate Text"
            resizeMode="contain"
            w={160}
            h={160}
            source={images?.fullLogo}
            {...props}
        />
    );
};

export const LogoXl = ({ ...props }: ImageProps) => {

    const { images } = useAppSettings();

    return (
        <LogoImage
            alt="Alternate Text"
            resizeMode="contain"
            w={60}
            h={60}
            source={images?.fullLogo}
            {...props}
        />
    );
};

export const LogoLg = ({ ...props }: ImageProps) => {

    const { images } = useAppSettings();

    return (
        <LogoImage
            source={images?.fullLogo}
            alt="Alternate Text"
            resizeMode="contain"
            w={70}
            h={50}
            {...props}
        />
    );
};

export const LogoMd = ({ ...props }: ImageProps) => {

    const { images } = useAppSettings();

    return (
        <LogoImage
            source={images?.navLogo}
            alt="some_here"
            w={45}
            h={25}
            {...props}
        />
    );
};

export const LogoSm = ({ ...props }: ImageProps) => {

    const { images } = useAppSettings();

    return (
        <LogoImage
            source={images?.fullLogo}
            alt="some_here"
            w={35}
            h={18}
            {...props}
        />
    );
};

export default Image;
