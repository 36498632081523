import React from 'react';
import { useAuthorization } from '~/hooks/auth';
import Loading from '~/shared/Loading';

import Section from '~/shared/Section';
import ProfileMenuOptions from './components/ProfileMenuOptions';

const ProfileMenuScreen = () => {

    const {isLoadingAuth} = useAuthorization();

    if(isLoadingAuth){
        return <Loading isLoading={isLoadingAuth}/>;
    }

    return (
        <Section
            flex={1}
            pb={10}
        >
            <ProfileMenuOptions/>
        </Section>
    );
};


export default ProfileMenuScreen;