"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    ApiEnvironments: ()=>ApiEnvironments,
    ApiEnvironmentsList: ()=>ApiEnvironmentsList,
    changeApiUrl: ()=>changeApiUrl,
    authHeaders: ()=>authHeaders,
    api: ()=>api
});
const _cryptoJs = require("crypto-js");
const _ = require("..");
var ApiEnvironments;
(function(ApiEnvironments) {
    ApiEnvironments[ApiEnvironments['alpha'] = 0] = 'alpha';
    ApiEnvironments[ApiEnvironments['beta'] = 1] = 'beta';
    ApiEnvironments[ApiEnvironments['staging'] = 2] = 'staging';
    ApiEnvironments[ApiEnvironments['delta'] = 3] = 'delta';
    ApiEnvironments[ApiEnvironments['epsilon'] = 4] = 'epsilon';
    ApiEnvironments[ApiEnvironments['gamma'] = 5] = 'gamma';
    ApiEnvironments[ApiEnvironments['zeta'] = 6] = 'zeta';
    ApiEnvironments[ApiEnvironments['production'] = 7] = 'production';
})(ApiEnvironments || (ApiEnvironments = {}));
const ApiEnvironmentsList = Object.values(ApiEnvironments).filter((t)=>/[^\d]/.test(t));
const makeApiUrl = (env)=>window.location != undefined ? `https://api.${env === 'production' ? "" : `${env}.`}${window.location.host}` : `https://${env}.api.mywallet.deals`;
const changeApiUrl = (env)=>{
    api.changeBaseUrl(makeApiUrl(env));
    api.setStore({
        apiEnv: env
    });
};
const authHeaders = (phoneNumber, userToken)=>{
    const time = Math.ceil(+new Date() / 1000);
    const hashText = `${phoneNumber}_${time}`;
    const hmac = (0, _cryptoJs.HmacSHA256)(hashText, `${userToken}`).toString();
    return {
        hmac,
        time
    };
};
const api = new _.ApiFactory({
    // required
    baseUrl: __DEV__ ? makeApiUrl('beta') : makeApiUrl('production'),
    type: 'json',
    // optional
    headers: {
        'content-type': 'application/json'
    },
    makeOptions (opts, store) {
        if (store.phoneNumber && store.mobileSubscriberId) {
            const { hmac , time  } = authHeaders(store.phoneNumber, store.mobileSubscriberId);
            opts.headers = {
                ...opts.headers || {},
                'hmac-timestamp': `${time}`,
                'hmac-signature': hmac
            };
        }
        return opts;
    }
});
_.observer.on('switch-env', ({ env  })=>{
    changeApiUrl(env);
    _.observer.emit('env-changed');
});
_.observer.on('storage-set', ({ key , value  })=>{
    if (key === 'apiEnv') {
        _.observer.emit('switch-env', {
            env: value
        });
    }
});
