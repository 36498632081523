/* eslint-disable max-lines-per-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useEffect, useState } from 'react';
import { Box, IBoxProps } from 'native-base';
import { faHeart, faStar } from '@fortawesome/free-solid-svg-icons';
import { faHeart as faHeartOutline } from '@fortawesome/free-regular-svg-icons';

import Image from './Image';
import Loading from './Loading';
import { SpIcon } from './Icon';
import Pressable from './Pressable';
import { Caption } from './Typography';
import { useItemCardStyles } from './styles';

export type ItemCardProps = {
    handleCallback: (id: number, title?: string, interest?: string) => void;
    id?: number
    title?: string
    imageUrl?: string
    boxProps?: IBoxProps
    checked?: boolean
    needsMark?: boolean
    isDisabled?: boolean
    interest?: string
    loadingComponent?: boolean
    customImageWidth?: number,
    testID?: string;
}

const ItemCard = ({
    handleCallback,
    id,
    title,
    imageUrl,
    checked,
    isDisabled,
    boxProps,
    interest,
    loadingComponent,
    customImageWidth,
    testID,
    needsMark = false
}:ItemCardProps) => {

    const [isChecked, setIsChecked] = useState<boolean>(false);
    const {
        box_background,
        box_border,
        box_paragraph_text,
        heart_empty_icon_color,
        heart_filled_icon_color,
        star_icon_color
    } = useItemCardStyles();
    useEffect(() => {

        if (!isDisabled) {
            setIsChecked(false);
        }

    },[isDisabled]);

    return (
        <Box
            {...boxProps}
            bg={box_background}
            borderColor={box_border}
            borderWidth={0.25}
            borderTopRadius={12}
            borderBottomRadius={12}
        >
            <Loading
                isLoading={isChecked}
                borderRadius={12}
            />
            <Pressable
                testID={testID}
                onPress={() => {

                    if (loadingComponent) {
                        setIsChecked(true);
                    }

                    handleCallback(id!, title, interest);
                } }
                borderTopRadius={12}
                borderBottomRadius={12}
                key={id}
                alignItems='center'
                isDisabled={isDisabled}
            >
                <Box
                    w={'100%'}
                    borderTopRadius={12}
                    borderBottomRadius={12}
                    alignItems='center'>
                    {
                        imageUrl ? (
                            <Box
                                borderTopRadius={12}
                                borderBottomRadius={12}
                                position={'relative'}
                            >
                                <Image
                                    size={'100%'}
                                    w={customImageWidth || 40}
                                    h={40}
                                    containerProps={{
                                        borderTopRadius: 12
                                    }}
                                    borderTopRadius={12}
                                    uri={`${imageUrl}`}
                                />
                                {needsMark && (
                                    <Box position={'absolute'} bottom='1' right={'2'}>
                                        {
                                            checked ? (
                                                <SpIcon
                                                    size={16}
                                                    color={heart_filled_icon_color}
                                                    icon={faHeart}
                                                />
                                            ) : (
                                                <SpIcon
                                                    size={16}
                                                    color={heart_empty_icon_color}
                                                    icon={faHeartOutline}
                                                />
                                            )
                                        }
                                    </Box>)
                                }
                            </Box>
                        ) : (
                            <Box
                                h={40}
                                w={40}
                                borderTopRadius={12}
                                borderBottomRadius={12}
                                position={'relative'}
                                justifyContent='center'
                                alignItems='center'
                            >
                                <SpIcon
                                    icon={faStar}
                                    size={120}
                                    color={star_icon_color}
                                />
                                {needsMark && (
                                    <Box position={'absolute'} bottom='1' right='2'>
                                        {
                                            checked ? (
                                                <SpIcon
                                                    size={16}
                                                    color={heart_filled_icon_color}
                                                    icon={faHeart}
                                                />
                                            ) : (
                                                <SpIcon
                                                    size={16}
                                                    color={heart_empty_icon_color}
                                                    icon={faHeartOutline}
                                                />
                                            )
                                        }
                                    </Box>)
                                }
                            </Box>
                        )

                    }
                    <Box
                        justifyContent='center'
                        alignItems='center'
                        h={16}
                    >
                        <Caption
                            color={box_paragraph_text}
                            p={2}
                            maxW={'40'}
                            textAlign={'center'}
                        >{title && title.length > 35 ? `${title.slice(0, 35) + '...'}` : title || ''}</Caption>
                    </Box>

                </Box>
            </Pressable>
        </Box>
    );
};

export default ItemCard;
