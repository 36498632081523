/* eslint-disable @typescript-eslint/no-var-requires */
import React from "react";
import QRCode, { QRCodeProps } from "react-qr-code";

const QrCode = ({ ...props }: QRCodeProps) => {
    return (
        <QRCode
            size={props.size}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value={props.value}
        />
    );
};

export default QrCode;
