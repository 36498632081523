/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import { Box, Center } from 'native-base';
import { faTrophy } from '@fortawesome/free-solid-svg-icons';

import { devLog, useGetMilestones } from '@springbig/shared';

import CardMilestone from './components/CardMilestone';
import Section from '~/shared/Section';
import { Caption } from '~/shared/Typography';
import { SpIcon } from '~/shared/Icon';
import Loading from '~/shared/Loading';
import NoDataView from '~/shared/NoData';
import { useMilestonesStyles } from '~/shared/styles';
import { useAuthorization } from '~/hooks/auth';

const MilestoneScreen = () => {

    const { text, icon } = useMilestonesStyles();
    const {isLoadingAuth} = useAuthorization();

    const {
        refetch,
        data,
        isLoading
    } = useGetMilestones();


    const handleMilestone = () => {

        devLog('data', data);
    };

    if (isLoading || isLoadingAuth) {
        return <Loading isLoading={true}/>;
    }

    if (!data || !data.milestones || data.milestones.member_spend_milestones.length === 0) {
        return (
            <NoDataView message='No milestones available' callback={refetch} isLoading={isLoading} />
        );
    }

    return (
        <Section flex={1} w={'100%'}>
                <Center mb={8}>
                    <Box
                        w={'100%'}
                        flexDirection='row'
                        alignItems={'center'}
                        py={6}
                        px={4}
                    >
                        <Caption color={text} text="milestones.title" my={0} mr={2} />
                        <SpIcon
                            color={icon}
                            icon={faTrophy}
                        />
                    </Box>
                    <Box w={'100%'} mt={0}>
                        {
                            data &&
                            data.milestones?.member_spend_milestones.length > 0 && data.milestones.member_spend_milestones.map((item, index) => {

                                return (
                                    <CardMilestone
                                        key={index}
                                        lifetimeSpent={data.milestones.lifetime_spend || 0}
                                        spentGoal={parseInt(item.spend_milestone)}
                                        milestone={item}
                                        handleMilestone={handleMilestone}
                                    />
                                );
                            })
                        }
                    </Box>
                </Center>
        </Section>
    );
};

export default MilestoneScreen;