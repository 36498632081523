import { en, es } from './messages/index';
const settings = {
    merchantOrg: 'springbig',
    appTitle: 'SpringbigQA',
    cartWebviewUrl: 'https://springbig.com/',
    navType: 'tab',
    images: {
        fullLogo: require('./assets/images/home-logo.png'),
        navLogo: require('./assets/images/logo.png')
    },
    isContrast: true,
    hasCustomBackground: false,
    background: {
        light: require('./assets/images/background-light.png'),
        dark: require('./assets/images/background-dark.png')
    },
    firebase_project_id: 100,
    profileIcon: false,
    redeemOfferInStore: true,
    redeemRewardInStore: true,
    upperLeftIcon: {
        toScreen: 'Profile',
        iconUrl: 'https://aemabitfolder.sfo2.cdn.digitaloceanspaces.com/icons8-adobe.svg'
    },
    bottomNav: [
        {
            toScreen: 'Rewards',
            i18n: 'navigation.rewards',
            icon: 'Rewards'
        },
        {
            toScreen: 'Offers',
            i18n: 'navigation.offers',
            icon: 'Offers'
        },
        {
            toScreen: 'Milestones',
            i18n: 'navigation.milestones',
            icon: 'Milestones'
        },
        {
            toScreen: 'Messages',
            i18n: 'navigation.messages',
            icon: 'Messages'
        },
        {
            toScreen: 'Profile',
            i18n: 'navigation.profile',
            icon: 'Profile',
        },
        {
            toUrl: 'https://springbig.com/',
            i18n: 'navigation.cart',
            icon: 'Cart',
        },
        {
            toScreen: 'Refer',
            i18n: 'navigation.refer',
            icon: 'Shared',
        },
    ],
    sideNav: [
        {
            toScreen: 'LocationStore',
            i18n: 'navigation.select_store',
        },
        {
            toScreen: 'Rewards',
            i18n: 'navigation.rewards'
        },
        {
            toScreen: 'Offers',
            i18n: 'navigation.offers'
        },
        {
            toScreen: 'Milestones',
            i18n: 'navigation.milestones'
        },
        {
            toScreen: 'Messages',
            i18n: 'navigation.messages'
        },
        {
            toScreen: 'Profile',
            i18n: 'navigation.profile'
        },
        {
            toUrl: 'https://springbig.com/',
            i18n: 'navigation.cart'
        },
        {
            toScreen: 'Refer',
            i18n: 'navigation.refer'
        },
        {
            toUrl: 'https://springbig.com/',
            customLabel: 'Visit our website',
        },
    ],
    // customIcons: {
    //     Cart: 'https://springbig-mobile-app.s3.amazonaws.com/%APP_NAME%/svgs/cart.svg',
    //     BarMenu: 'https://springbig-mobile-app.s3.amazonaws.com/%APP_NAME%/svgs/menu.svg',
    //     Messages: 'https://springbig-mobile-app.s3.amazonaws.com/%APP_NAME%/svgs/messages.svg',
    //     Milestones: 'https://springbig-mobile-app.s3.amazonaws.com/%APP_NAME%/svgs/milestones.svg',
    //     Offers: 'https://springbig-mobile-app.s3.amazonaws.com/%APP_NAME%/svgs/offers.svg',
    //     Profile: 'https://springbig-mobile-app.s3.amazonaws.com/%APP_NAME%/svgs/profile.svg',
    //     Shared: 'https://springbig-mobile-app.s3.amazonaws.com/%APP_NAME%/svgs/refer.svg',
    //     Rewards: 'https://springbig-mobile-app.s3.amazonaws.com/%APP_NAME%/svgs/rewards.svg'
    // },
    expirationDateInDays: 30,
    languages: [
        {
            label: 'English (US)',
            i18n: en,
            default: true
        },
        {
            label: 'Spanish (ES)',
            i18n: es
        }
    ],
    switchLightDarkMode: false,
    titleOnTop: true,
    isMenu: true,
    disableLeftIcon: false,
    screenBanners: [
        {
            screen: 'Rewards',
            bannerUrl: 'https://aemabitfolder.sfo2.cdn.digitaloceanspaces.com/b1.png'
        },
        {
            screen: 'Offers',
            bannerUrl: 'https://aemabitfolder.sfo2.cdn.digitaloceanspaces.com/b2.png'
        },
        {
            screen: 'Refer',
            bannerUrl: 'https://aemabitfolder.sfo2.cdn.digitaloceanspaces.com/b3.png'
        },
        {
            screen: 'ConvosContainer',
            bannerUrl: 'https://aemabitfolder.sfo2.cdn.digitaloceanspaces.com/b4.png'
        }
    ],
    bottomTabsLength: 5
};
export default settings;
