/* eslint-disable max-len */ "use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: ()=>_default
});
const lang = {
    auth: {
        validation: {
            phone_number_required: 'Phone number is required',
            pin_required: 'Pin is required',
            pin_pattern: 'Pin must be atleast 4 characters long',
            email_required: 'Email is required',
            email_pattern: 'Invalid email',
            password_required: 'Password is required',
            password_pattern: 'Password must be atleast 8 characters long and 1 letter, 1 number, and 1 punctuation',
            confirm_required: 'Confirmation is required',
            confirm_validate: 'Password must match',
            shared_pin_paragraph: 'Enter your log in code below to preoceed',
            forgot_code: 'Forgot Code?',
            send_me_my_login_code: 'Send me my log in code'
        },
        links: {
            register: 'Need an Account?',
            forgot: 'Forgot Password?',
            login: 'Already Have an Account?'
        },
        confirm_account: {
            error_title: 'Oh No!',
            error_paragraph: 'There was an error with your confirmation link. Please check your email and try again.',
            confirmed_title: 'Account Confirmed!',
            confirmed_paragraph: 'You may now login with your email start setting up your business!',
            confirming: 'Confirming Account...'
        },
        forgot_password: {
            success_title: 'Success!',
            success_paragraph: 'Check your email for a password reset link.',
            title: 'Forgot Password'
        },
        reset_password: {
            validating_token: 'Validating token...',
            invalid_link: 'Invalid password reset link...',
            success_title: 'Success!',
            success_paragraph: 'You may now login with your new password.',
            title: 'Reset Password'
        },
        login: {
            title: 'Hello, <br/>welcome back!',
            paragraph: 'Enter your phone number',
            success: 'Login Successful!',
            pin_paragraph: 'A temporary pin has been sent to your phone and email (if applicable) - please enter it below to proceed'
        },
        location: {
            what_state: 'What {0} {1} are you located in?',
            choose_state: 'Please select a state',
            cannot_continue: 'Cannot Continue',
            merchant_does_not_serve: 'Sorry! This merchant is not authorized to provide service in your particular state.',
            location_associated_paragraph: 'Select your store program',
            location_unassociated_paragraph: 'Traveling? Click below to register for our other state programs'
        },
        logout: 'You have logged out!',
        register: {
            title: 'Welcome!',
            paragraph: 'By providing your phone number and checking the box provided, you consent to receive marketing focused text messages and calls, including via an automatic telephone dialing system or prerecorded or artifiial voice from {0} and its affiliates, brands and product manufacturers. Consent is not required as a condition of purchasing any goods or services. Standard per minute calland message rates may apply. Opt out at any time by repliying "END".'
        },
        update_password: {
            title: 'Update Password',
            paragraph: 'A temporary password has been set on your account. For security reasons, you cannot continue until you set a new password. Please set your new password and you will be able to login again.'
        }
    },
    alerts: {
        loyalty_opt_in: 'Opt in to loyalty',
        loyalty_opt_in_message: 'You are currently not opted in to our rewards program by providing your signature below and clicking "opt in" you will now be elegible to earn loyalty points and redeem rewards.'
    },
    rewards: {
        title: 'Rewards',
        modal_title: 'Redeem Reward',
        title_points_rewards: '{0} point rewards',
        points_redeemable: 'Points redeemable',
        spend_toward_status: 'Spend towards status',
        redemption_text: `STOP! Please make sure you are with staff before redeeming your reward. Staff needs to watch you redeem your offer in order for you to receive your reward.\nAre you ready to redeem your reward: {0}`,
        redemption_modal_title: 'Reward Redemption',
        redemption_in_store: 'So Excited You Want To Redeem Your Points! Please Redeem Your Points At The Store and Prior to Check Out!',
        available_points: 'available points',
        click_here_to_earn: 'click here to start earning points',
        expires_at: 'expires {0}'
    },
    redeem: {
        at: 'Redeemed at: {0}',
        valid_until: 'Valid until {0}',
        confirmation_number: 'Confirmation: {0}',
        go_back: 'return to wallet'
    },
    offers: {
        title: 'Offers',
        title_refer: 'Refer a friend',
        modal_title: 'Redeem Offer',
        available_offers: 'Available offers',
        refer_friend: 'Refer a friend',
        redemption_text: `STOP! Please make sure you are with staff before redeeming your reward. Staff needs to watch you redeem your offer in order for you to receive your reward.\nAre you ready to redeem your reward: {0}`,
        redemption_modal_title: 'Reward Redemption',
        redemption_in_store: 'So Excited You Want To Redeem Your Offer! Please Redeem Your Offer At The Store and Prior to Check Out!'
    },
    milestones: {
        title: 'Milestones',
        spends_remaining: 'spend ${0} more to unlock this milestone',
        spends_title: 'Spend ${0} to earn {1}',
        benefits_title: 'Benefits include:',
        achieved: 'milestione achieved!'
    },
    profile: {
        title: 'Profile',
        my_account: 'My Account',
        greetings: 'Hello, {0}',
        spend_toward_status: '{0} Spend towards status'
    },
    profileMenu: {
        my_information: 'My Information',
        recent_history: 'Recent History',
        message_notifications: 'Message and notification settings'
    },
    accountInformation: {
        delete_account_title: 'Are you sure you want to delete your account?',
        delete_account_message: `by doing so, all of your earned loyalty points will be deleted and not available for your use and you will be opted out of receiving all messages from this retailer.

        Once you delete your account, this will be permanent and can\'t be undone.`,
        delete_account: 'Delete Account',
        delete_account_header_title: `A temporary pin code has been sent to your phone. Enter it below to confirm that you want to delete your account.`
    },
    refer: {
        title: 'Refer a friend',
        copy_link: 'Copy Link',
        text_prize: 'When one of your friends joins, on your referral, you will receive a',
        prize: '30% off one purchase',
        text_receiver: 'and they will receive',
        pre_rolled_reward: 'a free pre-roll',
        text_link: 'Copy and share the link below with your friends or show your friends your unique qr code above for an easy sign up.',
        text_social_media: 'Do not forget to share your link on social media!'
    },
    interests: {
        title: 'Interests'
    },
    message_center: {
        title: 'Message Center'
    },
    settings: {
        header_title: 'Communication settings',
        modal_title: 'Opt in to email marketing',
        modal_paragraph: 'agree to terms and sign below to opt in to the messaging',
        modal_terms_and_conditions: 'By checking this box, I consent to receive automated marketing messages from {0} and its affiliates, brands, and product manufactures. Consent is not a condition of purchasing any goods or services',
        text_message: 'Text messages',
        push_notifications: 'Push notifications',
        email_notifications: 'Email notifications'
    },
    history: {
        title_purchases: 'Purchases',
        title_redemptions: 'Redemptions',
        transaction_total: 'Transaction total',
        item_ordered: 'Items ordered',
        category: 'Category',
        brand: 'Brand',
        product_name: 'Product name',
        redeemed: 'redeemed',
        points: 'points'
    },
    general: {
        accept: 'Accept',
        address1: 'Address 1',
        address2: 'Address 2',
        back: 'Back',
        birthday: 'Birthday',
        cancel: 'Cancel',
        cart: 'Cart',
        city: 'City',
        confirm_password: 'Confirm Password',
        continue: 'Continue',
        delete: 'Delete',
        edit: 'Edit',
        email: 'Email',
        female: 'Female',
        first_name: 'First Name',
        gender: 'Gender',
        get: 'Get',
        go_back: 'Go Back',
        last_name: 'Last Name',
        loading: 'Loading',
        login: 'Login',
        male: 'Male',
        merchant: 'Merchant',
        message_center: 'Message Center',
        new_password: 'New Password',
        offers: 'Offers',
        old_password: 'Old Password',
        other: 'Other',
        password: 'Password',
        phone_number: 'Phone Number',
        pin: 'Enter pin',
        press_to_sign: 'Press to sign',
        privacy_policy: 'Privacy Policy',
        profile: 'Profile',
        redeemed: 'Redeemed',
        region: 'State/Region',
        rewards: 'Rewards',
        save: 'Save',
        select_date: 'Select date',
        sign_below: 'Please sign below',
        signature_saved: 'Signature saved',
        signature: 'Signature',
        submit: 'Submit',
        success: 'Success',
        terms_and_conditions: 'Terms and Conditions',
        zip: 'Zip/Postal code',
        select_language: 'Select Language'
    },
    cart: {
        no_website: 'No website available'
    },
    apiErrors: {
        postJoinPhone: 'Error submitting phone number',
        getJoinPin: 'Error submitting pin',
        getWalletMember: 'Error receiving wallet',
        getWalletMilestones: 'Error receiving milestones',
        postWalletRedemption: 'Error redeeming rewards',
        getNativeMerchantOrgs: 'Error receiving locations',
        getNativeAppStashboardKeys: 'Error receiving message keys',
        getNativeAppStashboard: 'Error receiving message information',
        getNativeAppRewardRedemption: 'Error redeeming rewards',
        getNativeAppVisit: 'Error receiving visit',
        putNativeAppSync: 'Error syncing',
        patchNativeAppUpdate: 'Error updating your member',
        postNativeAppCreate: 'Error creating your member'
    },
    navigation: {
        menu: 'Menu',
        cart: 'Cart',
        messages: 'Messages',
        profile: 'Profile',
        rewards: 'Rewards',
        offers: 'Offers',
        refer: 'Refer',
        milestones: 'Milestones',
        select_store: 'Select Store'
    }
};
const _default = lang;
