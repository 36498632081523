/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import { Slider as NativeSlider, ISliderProps, Box } from 'native-base';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

import { SpIcon } from './Icon';
import { Caption } from './Typography';
import { useRangeBarStyles } from './styles';

export type DefaultValuesPointsProps =  {
    value: number;
    label: string;
    divisbleValue?: number;
}

export interface SliderProps extends ISliderProps {
    defaultValue?: number;
    valuePoints?: DefaultValuesPointsProps[];
    dots?: boolean;
    maxValue?: number;
}


const SliderBar = ({ dots, valuePoints , defaultValue, maxValue, ...props }: SliderProps) => {

    const {
        dots_reached,
        dots_unreached,
        dots_text
    } = useRangeBarStyles();

    const logicCalculateColor = (value: number) => {

        if (defaultValue && defaultValue >= value) {
            return dots_reached;
        }

        return dots_unreached;
    };

    return (
        <Box bg='transparent' w="80%" >
            {
                defaultValue === 0 && (
                    <Slider defaultValue={defaultValue} maxValue={maxValue}/>
                )
            }
            {
                defaultValue && defaultValue > 0 && (
                    <Slider defaultValue={defaultValue} maxValue={maxValue}/>
                )
            }
            {
                dots && valuePoints && valuePoints.length > 0 && valuePoints.map((point, index) => {

                    const divisor = point.divisbleValue ? point.divisbleValue : 1;
                    return (
                        <Box key={index} position='absolute' top={defaultValue ? '16%' : '16%'} left={`${divisor * 97}%`}>
                            <SpIcon
                                color={logicCalculateColor(point.value)}
                                icon={faCircle}
                                size={10}
                            />
                            <Caption
                                textAlign={'center'}
                                fontSize={7}
                                color={dots_text}
                            >{point.label}</Caption>
                        </Box>
                    );
                })
            }
        </Box>
    );
};

const Slider = ({ defaultValue, maxValue, ...props }: SliderProps) => {

    const {
        unfilled_track,
        filled_track,
    } = useRangeBarStyles();



    return (
        <NativeSlider
            isReadOnly={true}
            bg='transparent'
            defaultValue={defaultValue}
            maxValue={maxValue}
            size="lg"
        >
            <NativeSlider.Track
                bg={unfilled_track}
            >
                <NativeSlider.FilledTrack
                    bg={filled_track}
                />
            </NativeSlider.Track>
        </NativeSlider>
    );
};

export default SliderBar;

