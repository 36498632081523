/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useEffect } from 'react';
import { Box } from 'native-base';

import { useUpdateMember, useSpringBigStore, getMember, useGetMember, useI18n } from '@springbig/shared';

import { accountForms, GenForm, GenFormProps } from './components/AccountInformationForm';
import Section from '~/shared/Section';
import Alert from '~/shared/Alert';
import { useGeneralStyles } from '~/shared/styles';
import { useToast } from '~/hooks/toast';
import { useAppNavigate } from '..';
import Button from '~/shared/Button';
import { useAuthorization } from '~/hooks/auth';
import Loading from '~/shared/Loading';

const AccountInformationScreen = () => {

    const { member, setMember, walletId } = useSpringBigStore();
    const { t } = useI18n();
    const [formData, setFormData] = React.useState<GenFormProps['formKeys'][]>();
    const [showDeleteModal, setShowDeleteModal] = React.useState(false);

    const { mutate: updateMember, isLoading, data: memberUpdated } = useUpdateMember();

    const {navigate, params}= useAppNavigate();
    const { successToast } = useToast();
    const gStyle = useGeneralStyles();

    const {isLoadingAuth} = useAuthorization();

    useEffect(() => {

        if (member) {
            const data_: GenFormProps['formKeys'][] =  accountForms.map(el => {

                return {
                    ...el,
                    value: member[el.name] || undefined
                };
            });

            setFormData(data_);
        }
    }, [member]);

    useEffect(() => {

        if (memberUpdated) {
            handleMember(walletId || '');
            successToast('Member updated successfully');
        }

    }, [memberUpdated]);

    const handleMember =  async ( id: string) => {

        const member_ = await getMember({ walletId: id });

        if (member_) {
            setMember(member_);
        }

    };

    const handleSubmitValues = (values: GenFormProps['formKeys']) => {

        updateMember({
            member: {
                [values.name]: values.value
            }
        });
    };

    const handleDeleteAccount = () => {

        navigate(`w/${params.wallet_id}/profile/menu/account/remove`);
    };

    if(isLoadingAuth){
        return <Loading isLoading={isLoadingAuth}/>;
    }

    return (
        <Section
            flex={1}
            pb={16}
            mb={6}
        >
                    <Box pb={20}>

                        {
                            formData && formData.map((el, index) => {

                                return (
                                    <Box
                                        mt={ index === 0 ? 4 : 0 }
                                        mb={4}
                                        px={4}
                                        key={el.name}
                                        bg={gStyle.section_background}>
                                        <GenForm
                                            handleSubmitValues={handleSubmitValues}
                                            isLoading={isLoading}
                                            formKeys={el}
                                        />
                                    </Box>
                                );
                            })
                        }
                        <Box alignItems={'center'} my={2}>
                            <Button
                                bg={gStyle.button_danger_background}
                                _text={{
                                    color: gStyle.button_danger_text
                                }}
                                h={12}
                                w={32}
                                onPress={(() => {

                                    setShowDeleteModal(true);
                                })}
                                >
                                {
                                    t('accountInformation.delete_account')
                                }
                            </Button>
                        </Box>
                        {
                            showDeleteModal && (
                                <Alert
                                    setAcceptAlert={() => {

                                        setShowDeleteModal(false);
                                        handleDeleteAccount();
                                    }}
                                    setCancelAlert={() => setShowDeleteModal(false)}
                                    showAlert={showDeleteModal}
                                    message={t('accountInformation.delete_account_message')}
                                    title={t('accountInformation.delete_account_title')}
                                />
                            )
                        }
                    </Box>
        </Section>
    );
};

export default AccountInformationScreen