/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable max-len */
import React from 'react';
import { Box, Center } from 'native-base';

import { faShareAlt } from '@fortawesome/free-solid-svg-icons';
import { useI18n, useSpringBigStore } from '@springbig/shared';

import QrCode from '~/shared/QrCode';
import Section from '~/shared/Section';
import { Caption } from '~/shared/Typography';
import { SpIcon } from '~/shared/Icon';
import ReferSocialMedia from './components/ReferSocialMedia';
import ReferCopyLink from './components/ReferCopyLink';
import ReferRewards from './components/ReferRewards';
import { useReferStyles } from '~/shared/styles';
import { useToast } from '~/hooks/toast';
import { useCopyToClipboard } from 'usehooks-ts'
import Pressable from '~/shared/Pressable';
import { useAuthorization } from '~/hooks/auth';
import Loading from '~/shared/Loading';

export const ReferScreen = () => {

    const { member } = useSpringBigStore();
    const { t } = useI18n();
    const {
        text,
        icon,
        qr_code_background,
        qr_code_color,
    } = useReferStyles();
    const { successToast } = useToast();
    const [value, copy] = useCopyToClipboard()

    const {isLoadingAuth} = useAuthorization();

    const handleClipboard = (text: string) => {
        copy(text);
        successToast(t('refer.copy_link'));
    }

    if(isLoadingAuth){
        return <Loading isLoading={isLoadingAuth}/>;
    }

    return (
        <Section pb={10} flex={1} w={'100%'}>
                <Center mb={8}>
                    <Pressable
                        py={6}
                        w={'100%'}
                        mb={5}
                        flexDirection='row'
                        alignItems={'center'}
                        onPress={() => handleClipboard(member && member.referrals?.link || '')}
                    >
                        <Caption
                            color={text}
                            text="refer.title"
                            my={0}
                            mr={2}
                            px={4}
                        />
                        <SpIcon
                            color={icon}
                            icon={faShareAlt}
                        />
                    </Pressable>
                    <Box
                        w={'100%'}
                        px={4}
                        mb={4}
                        alignItems='center'
                    >
                        <Box
                            p={3}
                            bg={qr_code_background}
                            borderRadius={'md'}
                            w={'48'}
                            h={'48'}
                        >
                            <QrCode
                                color={qr_code_color}
                                size={170}
                                value={member && member.referrals?.link || 'www.google.com'}
                            />
                        </Box>
                    </Box>
                    {
                        member && member.referrals.has_referred_offer && member.referrals.referred_offer_name && (
                            <ReferRewards member={member}/>
                        )
                    }
                    <ReferCopyLink member={member} copyToClipboard={handleClipboard} link={t('refer.copy_link')}/>
                    <ReferSocialMedia copyToClipboard={handleClipboard}/>
                </Center>
        </Section>
    );
};

export default ReferScreen;