
import React, { useState } from 'react';
import { Center } from 'native-base';

import Button from "./Button"
import Section from './Section';
import { H1 } from './Typography';
import { useGeneralStyles } from './styles';

export type NoDataViewProps = {
    callback: () => void;
    message: string;
    isLoading: boolean
}

const NoDataView = ({ callback, message, isLoading }: NoDataViewProps) => {

    const [disabled, setDisabled] = useState(false);
    const {
        text,
        button_background,
        button_text
    } = useGeneralStyles();

    const handleCallback = () => {

        if (!disabled) {
            callback();
        }

        setDisabled(true);

        setTimeout(() => {

            setDisabled(false);
        }, 5 * 1000);
    };

    let label = 'Refresh';

    if (disabled) {
        label = 'Refresh Success';
    }

    if (isLoading) {
        label = 'Fetching...';
    }

    return (
        <Section
            pb={10}
            flex={1}
            justifyContent='center'
            w={'100%'}
        >
            <Center
                alignItems='center'>

                <H1
                    testID='h1-text'
                    color={text}
                >
                    {message}
                </H1>

                <Button
                    testID='no-data-refresh'
                    bg={button_background}
                    _text={{
                        color: button_text
                    }}
                    onPress={handleCallback}
                    isDisabled={isLoading || disabled}
                >
                    { label }
                </Button>
            </Center>
        </Section>
    );

};

export default NoDataView;
