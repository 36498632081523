"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: ()=>_default
});
const settings = {
    appTitle: 'QA App',
    merchantOrg: 'NOTCONFIGURED',
    images: {
        fullLogo: '',
        navLogo: ''
    },
    navType: 'stack',
    isContrast: true,
    hasCustomBackground: false,
    background: {
        light: '',
        dark: ''
    },
    firebase_project_id: 1,
    profileIcon: false,
    redeemRewardInStore: false,
    redeemOfferInStore: false,
    expirationDateInDays: 90,
    isMenu: true,
    bottomTabsLength: 4
};
const _default = settings;
