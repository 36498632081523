import { faMessage } from "@fortawesome/free-solid-svg-icons";
import MessageDetailScreen from "./Message";
import MessageListScreen from "./MessageList";
const routes = {
    'w/:wallet_id/messages': {
        path: 'w/:wallet_id/messages',
        title: 'Messages',
        component: MessageListScreen,
        auth: true,
        exact: true,
        showTab: true,
        icon: faMessage
    },
    'w/:wallet_id/messages/:stashboard_key': {
        path: 'w/:wallet_id/messages/:stashboard_key',
        title: 'Message Detail',
        component: MessageDetailScreen,
        auth: true,
        exact: true,
        goBackPath: 'w/:wallet_id/messages'
    }
};
export default routes;
