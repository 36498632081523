import React from 'react';
import { Box, Divider } from 'native-base';
import dayjs from 'dayjs';
import { faStar } from '@fortawesome/free-solid-svg-icons';

import { RedeemedResponse } from '@springbig/shared';

import { SpIcon } from '~/shared/Icon';
import Image from '~/shared/Image';
import { Caption } from '~/shared/Typography';
import { useAccountHistoryStyles, useGeneralStyles } from '~/shared/styles';

const AccountHistoryRedemptionCard = ({ redeemed }: {redeemed: RedeemedResponse}) => {

    const {
        text,
        icon,
    } = useAccountHistoryStyles();
    const gStyle = useGeneralStyles();

    return (
        <Box
            px={4}
            mb={4}
            w={'100%'}
            bg={gStyle.section_background}
        >
            <Box
                alignItems={'center'}
                mb={4}
                my={2}
                bg={gStyle.section_background}
            >
                <Caption
                    color={text}
                >
                    {dayjs(redeemed.transaction_date).format('MM/DD/YYYY h:mm a')}
                </Caption>
                <Divider
                    bg={gStyle.divider}
                    mt={2}
                />
            </Box>

            <Box
                alignItems={'center'}
                justifyContent='space-between'
                flexDirection={'row'}
                mb={4}
                bg={gStyle.section_background}
            >
                <Box
                    justifyContent={'center'}
                    size={80}
                    w={'20'}
                    h={'20'}
                    borderRadius={12}
                    bg={gStyle.section_background}
                    alignItems='center'>
                    {
                        redeemed.display_image ? (
                            <Image
                                w={'20'}
                                h={'20'}
                                p={3}
                                borderRadius={12}
                                uri={`${redeemed.display_image}`}
                            />
                        ) : (
                            <SpIcon
                                color={icon}
                                icon={faStar}
                                size={60}
                            />
                        )
                    }
                </Box>
                <Caption
                    color={text}
                    px={2}
                >
                    {redeemed.reward_name}
                </Caption>
                <Box px={2}>
                    <Caption
                        color={text}
                    >
                        {parseInt(redeemed.item_value || '0')} <Caption text='history.points'/>
                    </Caption>
                    <Caption
                        text='history.redeemed'
                        color={text}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default AccountHistoryRedemptionCard;
