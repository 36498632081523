/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable max-lines-per-function */
import React,
{ useEffect,
    useState } from 'react';
import { Box,
    Center } from 'native-base';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { orderBy } from 'lodash';
import { useEffectOnce } from 'usehooks-ts';

import {
    Stashboard,
    useSpringBigStore,
    useStashboards
} from '@springbig/shared';

import Section from '~/shared/Section';

import MessageCarousel from './components/MessagesCarousel';
import NoDataView from '~/shared/NoData';
import { useAuthorization } from '~/hooks/auth';
import Loading from '~/shared/Loading';

dayjs.extend(relativeTime);

const MessageListScreen = () => {

    const [items,
        setItems] = useState<Stashboard[]>();

    const { stashboards, member } = useSpringBigStore();

    const { fetch,
        isLoading } = useStashboards();

    const {isLoadingAuth} = useAuthorization();

    useEffectOnce(() => {

        fetch();
    });

    useEffect(() => {

        if (!stashboards || !stashboards?.length) {
            return;
        }

        const filtered = stashboards.filter(item => (
            
            item.merchant_id * 1 === member?.merchant_id
        ));
            
        
        setItems(
            orderBy(filtered,
                ['sent_at'],
                ['desc'])
        );
                
    },
    [stashboards,
        member?.merchant_id,
        setItems
    ]);



        if(isLoadingAuth){
            return <Loading isLoading={isLoadingAuth}/>;
        }


    if (!items || !items.length) {
        return (
            <NoDataView message='No Messages' callback={fetch} isLoading={isLoading} />
        );
    }

    return (
        <Section
            pb={10}
            flex={1}
            justifyContent='center'
            w={'100%'}
        >
                <Center alignItems='center'>
                    <Box w={'100%'}>
                        { items.length > 0 && items.map((data,
                        index) => {

                            return (
                                <MessageCarousel
                                    key={`${index}-stashboard`}
                                    data={data}
                                    hasMoreContent
                                />
                            );
                        })
                        }
                    </Box>
                </Center>
        </Section>
    );
};

export default MessageListScreen;