"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    themeConfig: ()=>themeConfig,
    default: ()=>_default
});
const _nativeBase = require("native-base");
const _colors = require("./colors");
const _dark = require("./dark");
const _light = require("./light");
const themeConfig = (0, _nativeBase.extendTheme)({
    space: {
        'space-2': '29px'
    },
    colors: {
        ..._colors.colors,
        ...(0, _colors.makeThemeColors)((0, _dark.darkColors)(_colors.colors), (0, _light.lightColors)(_colors.colors))
    },
    components: {
        Box: {
            baseStyle: (props)=>({
                    bg: _nativeBase.themeTools.mode('transparent', 'transparent')(props)
                })
        },
        Button: {
            baseStyle: (props)=>({
                    color: _nativeBase.themeTools.mode('black', 'white')(props)
                })
        },
        Text: {
            baseStyle: (props)=>({
                    color: _nativeBase.themeTools.mode('black_pure', 'white')(props)
                })
        },
        VStack: {
            baseStyle: (props)=>({
                    bg: _nativeBase.themeTools.mode('transparent', 'transparent')(props)
                })
        },
        Radio: {
            baseStyle: (props)=>({
                    color: _nativeBase.themeTools.mode('black_pure', 'red')(props)
                })
        },
        Divider: {
            baseStyle: (props)=>({
                    bg: _nativeBase.themeTools.mode('neutral', 'neutral')(props)
                })
        },
        SliderFilledTrack: {
            baseStyle: (props)=>({
                    bg: _nativeBase.themeTools.mode('primary', 'primary')(props)
                })
        },
        SliderTrack: {
            baseStyle: (props)=>({
                    bg: _nativeBase.themeTools.mode('neutral', 'neutral')(props)
                })
        }
    }
});
const _default = themeConfig;
