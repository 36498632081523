/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';

import {
    Box,
    Spinner,
    IBoxProps
} from 'native-base';
import { useGeneralStyles } from './styles';


interface ILoadingProps extends IBoxProps {
    isLoading:boolean
}

const Loading = ({ isLoading, ...props }: ILoadingProps) => {

    const {
        spinner_background,
        spinner_icon_color
    } = useGeneralStyles();

    if (!isLoading) {
        return null;
    }

    return (
        <Box
            zIndex={999}
            position={'absolute'}
            height={'100%'}
            w={'100%'}
            top={'0%'}
            left={'0'}
            bg={spinner_background}
            opacity={0.7}
            {...props}
        >
            <Spinner
                position={'absolute'}
                top={'40%'}
                left={'45%'}
                size={'lg'}
                color={spinner_icon_color}
            />
        </Box>
    );
};

export default Loading;
