import React from 'react';
import { Box, ScrollView } from 'native-base';

import { Stashboard } from '@springbig/shared';
import MessageLinkBox, { MessageLinkBoxProps } from './MessageLinkBox';

export const SliderLinkCard = ({ data }: { data: Stashboard }) => {

    return (
        <Box
            w={'100%'}
            alignItems='center'
        >
            {
                data.type === 'any_link' ? (
                    <>
                        {
                            data.any_link_extensions.length === 1 && data.any_link_extensions.map((link, i) => (

                                <MessageLinkBox
                                    type='any_link'
                                    url={`${link.url}`}
                                    link={link}
                                    key={`${i}-${link.id}`}
                                    stashboardKey={ data._key }
                                />
                            ))
                        }
                        {
                            data.any_link_extensions.length > 1 && (

                                <ScrollView
                                    my={6}
                                    w={'100%'}
                                    // horizontal={true}
                                    // decelerationRate={0}
                                    // showsHorizontalScrollIndicator={true}
                                >
                                    {
                                        data.any_link_extensions.map((link, i) => {

                                            return (
                                                <MessageLinkBox
                                                    type='any_link'
                                                    url={link.url || ''}
                                                    link={link}
                                                    key={`${i}-${link.id}`}
                                                    stashboardKey={ data._key }
                                                />
                                            );
                                        })
                                    }
                                </ScrollView>
                            )
                        }
                    </>
                ) : <Box>

                    <MessageLinkBox
                        type={ data.type as MessageLinkBoxProps['type'] }
                        url={ data.url || '' }
                        key={ data._key }
                        stashboardKey={ data._key }
                    />
                </Box>
            }
        </Box>
    );
};
