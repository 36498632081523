import React, { useRef } from "react";
import { Box } from "native-base";
import SignatureCanvas from 'react-signature-canvas'

import { useAuthStyles, useFormStyles } from "./styles";

export type SignatureProps = {
    onChange: (signature: any) => void;
}

const Signature = ({ onChange }: SignatureProps) => {
    const signCanvas = useRef<SignatureCanvas>(null);
    const { signature_background } = useAuthStyles();
    const { input_border } = useFormStyles();

    const handleEnd = () => {
        onChange(signCanvas.current?.toDataURL())
    }

    return (
        <Box
            bg={signature_background}
            borderColor={input_border}
            borderWidth={1}
        >
            <SignatureCanvas
                penColor='black'
                canvasProps={{width: 500, height: 200, className: 'sigCanvas'}}
                onEnd={handleEnd}
                ref={signCanvas}
            />
        </Box>
    )
}

export default Signature