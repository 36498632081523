import React, { useState, useCallback } from 'react';
import { Box } from 'native-base';

import {
    useSpringBigStore,
    useStorage,
    useQaAgent,
} from '@springbig/shared';

import { wait } from '~/utils/misc';

import { Caption } from '~/shared/Typography';

import { ActionButton } from './Buttons';
import { useAppNavigate } from '~/pages';

export const ResetButton = () => {

    type ResetState = Partial<
        Record<'confirm' |'clearing' |'done', boolean>
    >;

    const [state, setState] = useState<ResetState>({});

    const { storage } = useStorage();
    const {navigate} = useAppNavigate();
    const { switchApiEnv } = useSpringBigStore();
    const { setQaAgent } = useQaAgent();

    const onPress = useCallback(() => {

        if (!state.confirm) {

            setState({ ...state, confirm: true });
            return;
        }

        setState({ ...state, clearing: true });

        storage.getAllKeys!(async (_: any, keys: string[]) => {

            const p = Promise.all(keys.map(k => storage.removeItem(k)));

            await p;
            await wait(1000);

            setState({ ...state, done: true });

            await wait(1000);

            setQaAgent(false);
            switchApiEnv!(__DEV__ ? 'beta' : 'production');


// navigate('auth/login');

        });
    }, [state, navigate, storage, setState, setQaAgent, switchApiEnv]);

    let text = 'Reset State';

    if (state.confirm) {
        text = 'Are you sure?';
    }

    if (state.clearing) {
        text = 'Clearing...';
    }

    if (state.done) {
        text = 'Done!';
    }

    return <Box mt={5}>

        <ActionButton key={1} text={text} bg='red' onPress={onPress} />

        <Caption key={2}>
            This will remove all local storage data as if youve never logged in before.
        </Caption>
    </Box>;
};

