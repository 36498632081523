/* eslint-disable @hapi/scope-start */ "use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    redeemReward: ()=>redeemReward,
    activeRewardGrants: ()=>activeRewardGrants,
    getRedeemRewards: ()=>getRedeemRewards,
    getVisits: ()=>getVisits,
    useRedeemRewards: ()=>useRedeemRewards,
    useGetRedeemRewards: ()=>useGetRedeemRewards,
    useGetVisits: ()=>useGetVisits,
    useActiveRewardGrants: ()=>useActiveRewardGrants
});
const _reactQuery = require("react-query");
const _ = require("../..");
const redeemReward = (data, opts)=>{
    const { wallet_id , ...payload } = data;
    return _.api.post(`/wallet/v1/members/${wallet_id}/redemptions`, payload, opts);
};
const activeRewardGrants = ({ wallet_id , reward_grant_id  })=>{
    const payload = {
        active: true
    };
    return _.api.put(`/wallet/v1/members/${wallet_id}/reward_grants/${reward_grant_id}`, payload);
};
const getRedeemRewards = async ({ id  }, opts)=>{
    const response = await _.api.get(`/native_app/v1/reward_redemptions?member_id=${id}`, opts);
    return response;
};
const getVisits = async ({ id  }, opts)=>{
    const response = await _.api.get(`/native_app/v1/visits?member_id=${id}`, opts);
    return response;
};
const useRedeemRewards = (queryOptions = {})=>{
    const { walletId , firebaseToken  } = (0, _.useSpringBigStore)();
    return (0, _reactQuery.useMutation)((payload)=>redeemReward({
            reward_id: payload.reward_id,
            reward_grant_id: payload.reward_grant_id,
            wallet_id: walletId,
            firebaseToken
        }), queryOptions);
};
const useGetRedeemRewards = (queryOptions = {})=>{
    const { member  } = (0, _.useSpringBigStore)();
    return (0, _reactQuery.useQuery)('redeemedRewards', ({ signal  })=>getRedeemRewards({
            id: member?.id
        }, {
            signal
        }), queryOptions);
};
const useGetVisits = (queryOptions = {})=>{
    const { member  } = (0, _.useSpringBigStore)();
    return (0, _reactQuery.useQuery)('visits', ({ signal  })=>getVisits({
            id: member?.id
        }, {
            signal
        }), queryOptions);
};
const useActiveRewardGrants = (queryOptions = {})=>{
    const { walletId  } = (0, _.useSpringBigStore)();
    return (0, _reactQuery.useMutation)((rewardGrantId)=>activeRewardGrants({
            wallet_id: walletId,
            reward_grant_id: rewardGrantId
        }), queryOptions);
};
