"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    getMilestones: ()=>getMilestones,
    useGetMilestones: ()=>useGetMilestones
});
const _reactQuery = require("react-query");
const _ = require("../..");
const getMilestones = async ({ walletId  }, opts)=>{
    const response = await _.api.get(`/wallet/v1/members/${walletId}/milestones`, opts);
    return response;
};
const useGetMilestones = (queryOptions = {})=>{
    const { walletId , firebaseToken  } = (0, _.useSpringBigStore)();
    const payload = {
        firebaseToken,
        walletId: walletId || ''
    };
    return (0, _reactQuery.useQuery)('milestones', ({ signal  })=>getMilestones(payload, {
            signal
        }), queryOptions);
};
