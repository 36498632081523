import React, { useEffect, useState } from "react";
import { Box } from "native-base";

import { useSpringBigStore } from "@springbig/shared";

import { SpIcon } from "./Icon";
import Pressable from "./Pressable";
import { Caption } from "./Typography";
import routes, { AppRouteProps, useAppNavigate } from "~/pages";
import { useBottomNavigationStyles, useGeneralStyles } from "./styles";
import { generatePath } from "~/utils/url";

const BottomNavigation = () => {
    const [links, setLinks] = useState<RouteProps[]>([]);
    const { member, walletId } = useSpringBigStore();
    const { navigate, location } = useAppNavigate();
    const {
        active_icon_color,
        inactive_icon_color,
        background,
        border
    } = useBottomNavigationStyles();
    const { text } = useGeneralStyles();
    const currentPath = location.pathname;

    useEffect(
        () => {
            let _items = routes.filter(el => el.showTab);

            if (member) {
                // if (!member.referrals?.active) {
                //     _items = _items.filter((t) => t.title !== 'Refer');
                // }

                if (!member.show_rewards) {
                    _items = _items.filter(t => t.title !== "Rewards");
                }

                // if (!member.show_milestones) {
                //     _items = _items.filter((t) => t.title !== 'Milestones');
                // }

                if (!member.merchant_website) {
                    _items = _items.filter(t => t.title !== "Cart");
                }
            }

            setLinks(_items);
        },
        [member]
    );

    const handleNavigation = (path: string) => {

        const _path = generatePath(path, walletId!) as AppRouteProps
        navigate(_path as AppRouteProps);
    };

    const handleColor = (path: string) => {
        if (currentPath === `/${path}`) {
            return active_icon_color;
        }

        return inactive_icon_color;
    };

    return (
        <Box
            position={"fixed"}
            bottom={0}
            zIndex={999}
            w={"440"}
            bg={"transparent"}
            h={20}
            flexDirection={"row"}
            overflowX={"scroll"}
        >
            {links.map((el, i) => {
                return (
                    <Pressable
                        borderTopRadius={10}
                        borderWidth={1}
                        justifyContent={"center"}
                        alignItems={"center"}
                        bg={background}
                        borderColor={border}
                        key={i}
                        w={"24"}
                        h={"20"}
                        onPress={() => handleNavigation(el.path)}
                    >
                        {el.icon &&
                            <SpIcon
                                icon={el.icon}
                                color={handleColor(el.path)}
                            />}
                        <Caption mt={1} fontSize={10} color={handleColor(el.path)}>
                            {el.title}
                        </Caption>
                    </Pressable>
                );
            })}
        </Box>
    );
};

export default BottomNavigation;
