/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import { Box } from 'native-base';
import { faAngleDown, faAngleUp, faGift, faTrophy } from '@fortawesome/free-solid-svg-icons';

import { MemberSpendMilestones } from '@springbig/shared';

import Image from '~/shared/Image';
import SliderBar from '~/shared/RangeBar';
import { Caption, H3 } from '~/shared/Typography';
import { SpIcon } from '~/shared/Icon';
import { useGeneralStyles, useMilestonesStyles } from '~/shared/styles';
import Pressable from '~/shared/Pressable';

type CardMilestoneProps = {
    handleMilestone: () => void;
    lifetimeSpent: number;
    spentGoal: number;
    milestone: MemberSpendMilestones;
}

const CardMilestone = ({
    lifetimeSpent,
    spentGoal,
    milestone
}: CardMilestoneProps) => {

    const [openMilestone, setOpenMilestone] = React.useState<boolean>(false);
    const {
        text,
        icon
    } = useMilestonesStyles();
    const { section_background } = useGeneralStyles();

    return (
        <Box
            bg={section_background}
            p={4}
            mb={5}>
            <Pressable
                onPress={() => setOpenMilestone(!openMilestone)}
            >
                <Box flexDirection={'row'}>
                    <Caption
                        color={text}
                        mr={2}
                    >{milestone.name}</Caption>
                    {
                        lifetimeSpent > spentGoal && (
                            <SpIcon size={14} color={icon} icon={faTrophy}/>
                        )
                    }
                </Box>
                <Box flexDirection={'row'} w={'100%'}>
                    <SliderBar defaultValue={lifetimeSpent} maxValue={spentGoal} w={'85%'}/>

                    <Pressable
                        w={'15%'}
                        flexDirection={'row'}
                        justifyContent='flex-end'
                        onPress={() => setOpenMilestone(!openMilestone)}
                    >
                        {
                            openMilestone ? (
                                <SpIcon color={icon} icon={faAngleUp}/>
                            ) : (
                                <SpIcon color={icon} icon={faAngleDown}/>
                            )
                        }
                    </Pressable>
                </Box>
                {
                    lifetimeSpent > spentGoal ? (
                        <Caption
                            fontSize={12}
                            color={text}
                            text='milestones.achieved'
                        />
                    ) : (
                        <Caption
                            color={text}
                            fontSize={12}
                            text='milestones.spends_remaining'
                            textValues={[`${spentGoal - lifetimeSpent}`]}/>
                    )
                }
                {
                    openMilestone && (
                        <Box mt={5} flexDirection={'row'} w={'100%'} justifyContent={'space-between'}>
                            <Box w={'60%'}>
                                <H3
                                    color={text}
                                    text='milestones.spends_title'
                                    textValues={[`${milestone.spend_milestone}`,
                                        `${milestone.name}`]}
                                />
                                <Box flexDirection={'row'} mt={2} alignItems='center'>
                                    <SpIcon
                                        color={icon}
                                        icon={faGift}
                                    />
                                    <Box ml={2}>
                                        <Caption
                                            color={text}
                                            fontWeight={'semibold'}
                                            text='milestones.benefits_title'
                                        />
                                        <Caption color={text}>
                                            {milestone?.reward?.item_name}
                                        </Caption>
                                    </Box>
                                </Box>
                            </Box>
                            {
                                milestone?.reward?.preload ? <Image
                                    w={'24'}
                                    h={'24'}
                                    borderRadius={8}
                                    uri={`${milestone.reward.preload}`}
                                /> : <Box justifyContent='center' p={4}>
                                    <SpIcon color={icon} icon={faGift} size={40}/>
                                </Box>
                            }
                        </Box>
                    )
                }
            </Pressable>
        </Box>
    );
};

export default CardMilestone;
