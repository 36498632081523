import { faShareAlt } from "@fortawesome/free-solid-svg-icons";
import ReferScreen from "./Refer";
const routes = {
    'w/:wallet_id/refer': {
        path: 'w/:wallet_id/refer',
        title: 'Referral',
        component: ReferScreen,
        auth: true,
        exact: true,
        showTab: true,
        icon: faShareAlt
    },
};
export default routes;
