"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "storageOptions", {
    enumerable: true,
    get: ()=>storageOptions
});
const _ = require("..");
const onAfterRemove = (keyOrKeys)=>{
    if (Array.isArray(keyOrKeys)) {
        _.observer.emit('storage-removed', {
            keys: keyOrKeys
        });
        return;
    }
    _.observer.emit('storage-removed', {
        key: keyOrKeys
    });
};
const onAfterSet = (keyOrObject, value)=>{
    if (typeof keyOrObject === 'object') {
        _.observer.emit('storage-set', {
            entries: Object.entries(keyOrObject)
        });
        return;
    }
    _.observer.emit('storage-set', {
        key: keyOrObject,
        value
    });
};
const storageOptions = {
    prefix: 'app',
    onAfterRemove,
    onAfterSet
};
