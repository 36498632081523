import {
    AuthPayload,
    useCheckAuth,
    useSpringBigStore
} from "@springbig/shared";
import { useCallback, useEffect, useState } from "react";
import { useEffectOnce } from "usehooks-ts";
import { useAppNavigate } from "~/pages";

export const useAuthorization = () => {
    const { mutate: checkAuth, error: authError } = useCheckAuth();
    const [isLoadingAuth, setIsLoadingAuth] = useState(false);

    const { cleanState, walletId } = useSpringBigStore();
    const { params } = useAppNavigate();
    const { navigate } = useAppNavigate();

    const getAuthMember = useCallback(
        async (id: string, reference: AuthPayload["reference"]) => {
            checkAuth({
                stashboardOrMemberKey: id,
                reference
            });
        },
        [checkAuth]
    );

    useEffectOnce(() => {
        if (params && params.wallet_id) {
            setIsLoadingAuth(true);
            if (walletId !== params.wallet_id) {
                getAuthMember(params.wallet_id, "member");
                return;
            }
            setIsLoadingAuth(false);
        }
    });

    useEffect(
        () => {
            if (authError) {
                cleanState();
                navigate(`w/${params.wallet_id}`);
                setIsLoadingAuth(false);
            }
        },
        [authError]
    );

    return {
        isLoadingAuth
    };
};
