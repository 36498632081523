/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useState, useEffect } from 'react';
import { Box, Center, ScrollView } from 'native-base';
import routes, { AppRouteProps, useAppNavigate } from "~/pages";
import { useMenuStyles } from '~/shared/styles';
import Section from '~/shared/Section';
import { Logo2xl } from '~/shared/Image';
import CardMenu from './components/CardMenu';
import CardMenuAdditionalLink from './components/CardMenuAdditionalLink';
import { useSpringBigStore, useAppSettings } from '@springbig/shared';
import { generatePath } from '~/utils/url';

const MenuScreen = () => {

    const { additionalStackMenuLinks } = useAppSettings();
    const [links, setLinks] = useState<RouteProps[]>([]);
    const { member, cleanState } = useSpringBigStore();
    const { navigate, params} = useAppNavigate();
    const {
        background
    } = useMenuStyles();

    useEffect(() => {
        let _items = routes.filter(el => el.showTab);

        if (member) {
            // TODO: Create context to manage this, as it was implemented in mobile Routes Provider
            // if (!member.referrals?.active) {
            //     _items = _items.filter((t) => t.title !== 'Refer');
            // }

            if (!member.show_rewards) {
                _items = _items.filter(t => t.title !== "Rewards");
            }

            // if (!member.show_milestones) {
            //     _items = _items.filter((t) => t.title !== 'Milestones');
            // }

            if (!member.merchant_website) {
                _items = _items.filter(t => t.title !== "Cart");
            }
        }

        setLinks(_items);
    }, [member])

    return (
        <Section
            flex={1}
            justifyContent='center'
            w={'100%'}
        >
            <ScrollView>
                <Center
                    bg={background}
                    mb={20}
                >
                    <Logo2xl h={120} />
                    <Box
                        w={'100%'}
                    >
                        {
                            links.map((link) => (
                                <CardMenu
                                  path={generatePath(link.path, params.wallet_id!) as AppRouteProps}
                                  title={link.title}
                                  key={link.title}
                                  testID={link.title}
                                />
                            ))
                        }
                        {
                            additionalStackMenuLinks && additionalStackMenuLinks.map((link) => (
                              <CardMenuAdditionalLink
                                key={link.label}
                                  screen={link.screen as AppRouteProps}
                                  external={link.external}
                                  url={link.url as string}
                                  label={link.label}
                              />
                            ))
                        }
                        {/* <CardMenu
                            handleLogout={handleHome}
                            title={'Logout'}
                        /> */}
                    </Box>
                </Center>
            </ScrollView>
        </Section>
    );
};

export default MenuScreen;