import React from 'react';
import { Box, Center } from 'native-base';
import { faEnvelope, faHome, faPhone, faUserCircle } from '@fortawesome/free-solid-svg-icons';

import { Member, useAppSettings } from '@springbig/shared';

import { SpIcon } from '~/shared/Icon';
import { Caption, H1 } from '~/shared/Typography';

import { useGeneralStyles, useProfileStyles } from '~/shared/styles';
import { useAppNavigate } from '~/pages';
import Button from '~/shared/Button';

type ProfileHeaderProps = {
    member?: Member | null;
}

const ProfileHeader = ({ member }: ProfileHeaderProps) => {

    const {navigate, params}= useAppNavigate();
    const {profileIcon} = useAppSettings();
    const {
        text,
        icon
    } = useProfileStyles();
    const gStyles = useGeneralStyles();

    return (
        <Box
            px={'4'}
            w={'100%'}
            alignItems={'center'}
        >
            <Box w={'100%'}>
                <Center alignItems='center'>
                    <H1
                        fontWeight={'normal'}
                        text={'profile.greetings'}
                        textValues={[`${member?.first_name || ''} ${member?.last_name || ''}`]}
                        color={text}
                    />
                    {
                        profileIcon && (
                    <SpIcon
                        color={icon}
                        icon={faUserCircle}
                        size={120}
                    />
                        )
                    }

                    <Button
                        bg={gStyles.button_background}
                        mt={2}
                        mb={4}
                        onPress={() => navigate(`w/${params.wallet_id}/profile/menu`)}
                    >
                        <Caption
                            color={gStyles.button_text}
                            text='profile.my_account'
                        />
                    </Button>
                </Center>
            </Box>
            <Box flexDirection='column' w={'100%'} mb={4}>
                <Box mb={6} flexDirection='row' >
                    <Box w={'45%'} flexDirection='row' alignItems={'center'}>
                        <SpIcon
                            color={icon}
                            icon={faPhone}
                        />
                        <Caption
                            my={0}
                            ml={2}
                            color={text}
                        >{member?.phone_number}</Caption>
                    </Box>
                    {member && member.email && (
                        <Box w={'45%'} flexDirection='row' alignItems={'center'}>
                            <SpIcon
                                color={icon}
                                icon={faEnvelope}
                            />
                            <Caption
                                color={text}
                                my={0}
                                ml={2}
                            >{member.email}</Caption>
                        </Box>
                    )}
                </Box>
                {member?.address1 && (
                    <Box w={'80%'} flexDirection='row' alignItems={'flex-start'}>
                        <SpIcon
                            color={icon}
                            icon={faHome}
                        />
                        <Caption
                            color={text}
                            my={0}
                            ml={2}
                        >{member.address1 || ''} {member.address2 || ''}</Caption>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default ProfileHeader;
