import React, { useCallback, useState } from 'react';
import { Box } from 'native-base';
import { useEffectOnce } from 'usehooks-ts';

import { useSpringBigStore, useDeleteAccount, loginByPhoneNumber, useAppSettings, useI18n } from '@springbig/shared';

import PinForm, { PinFormData } from '~/pages/auth/components/PinForm'
import Section from '~/shared/Section';
import { useToast } from '~/hooks/toast';
import { Caption } from '~/shared/Typography';
import { useGeneralStyles } from '~/shared/styles';
import { useAppNavigate } from '..';
import Button from '~/shared/Button';
import { useAuthorization } from '~/hooks/auth';
import Loading from '~/shared/Loading';

const DeleteAccountScreen = () => {

    const [codeId, setCodeId] = useState<number>();

    const { mutate: deleteAccount, isLoading, error: deleteError } = useDeleteAccount();
    const { phoneNumber, member } = useSpringBigStore();
    const { merchantOrg } = useAppSettings();
    const {navigate, params} = useAppNavigate();
    const { t } = useI18n();
    const gStyle = useGeneralStyles();

    const {isLoadingAuth} = useAuthorization();

    const getAccesCodeId = useCallback(async () => {

        const code = await loginByPhoneNumber({
            native_app_name: merchantOrg,
            phone_number: phoneNumber!
        });

        if (code) {
            setCodeId(parseInt(code.member_access_code_id));
        }

    }, [ merchantOrg, phoneNumber]);

    useEffectOnce(() => {

        getAccesCodeId();
    });

    const handleSubmit = (values: PinFormData) => {

        deleteAccount({
            code_id: codeId || 123,
            code_value: values.pin,
            member: {
                merchant_id: member?.merchant_id!,
                phone_number: phoneNumber!
            }
        });
    };

    if(isLoadingAuth){
        return <Loading isLoading={isLoadingAuth}/>;
    }

    return (
        <Section
            flex={1}
            justifyContent='center'
        >
                <Box
                    alignItems="center"
                    w="100%"
                >
                    <Caption
                        color={gStyle.text}
                        w={'76%'}
                        mb={4}
                    >
                        {t('accountInformation.delete_account_header_title')}
                    </Caption>
                    <Box
                        w="90%"
                        maxWidth="300px"
                    >
                        <PinForm
                            buttonText='Delete'
                            isLoading={isLoading}
                            handleSubmitValues={handleSubmit}
                        />
                    </Box>
                    <Button
                        bg={gStyle.button_danger_background}
                        _text={{
                            color: gStyle.button_danger_text
                        }}
                        w={'68%'}
                        mt={2}
                        isDisabled={isLoading}
                        onPress={() => navigate(`w/${params.wallet_id}/profile`)}
                    >
                        {t('general.cancel')}
                    </Button>
                </Box>
        </Section>
    );

};

export default DeleteAccountScreen;
