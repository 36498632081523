import React, { PropsWithChildren } from 'react';
import { IButtonProps } from 'native-base';
import { useCopyToClipboard } from 'usehooks-ts';
import Button from './Button';
import { useToast } from '~/hooks/toast';

const CopyButton = (props: PropsWithChildren<{ copyText: string } & IButtonProps>) => {

    const { copyText, ...rest } = props;
    const { successToast } = useToast();

    const [value, copy] = useCopyToClipboard()


    const handleClipboard = (text: string) => {
        copy(text);
        successToast('Copied to clipboard');
    }

    return (
        // eslint-disable-next-line react/no-children-prop
        <Button children={'copy'} {...rest } onPress={() => handleClipboard(copyText)} />
    );
};

export default CopyButton;
