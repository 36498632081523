/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable max-lines-per-function */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { Box, FormControl, Switch } from 'native-base';

import {
    useUpdateMember,
    getMember,
    useAppSettings,
    useI18n,
    useSpringBigStore
} from '@springbig/shared';

import Alert from '~/shared/Alert';
import Section from '~/shared/Section';
import { Caption } from '~/shared/Typography';
import { useAccountSettingsStyles, useGeneralStyles } from '~/shared/styles';
import Button from '~/shared/Button';
import { useAuthorization } from '~/hooks/auth';
import Loading from '~/shared/Loading';

export type SettingFormData = {
    allowed_sms: boolean;
    allowed_email: boolean;
    allow_push_notifications: boolean;
};

const SettingsScreen = () => {

    const [isDisabled, setIsDisabled] = useState(true);
    const { t } = useI18n();
    const { member, setMember, walletId } = useSpringBigStore();
    const { mutate: updateMember, isLoading, data: memberUpdated } = useUpdateMember();
    const [formData, setFormData] = React.useState<SettingFormData>({
        allowed_sms: false,
        allowed_email: false,
        allow_push_notifications: false
    });
    const [showAlert, setShowAlert] = useState<{status: boolean, id: 'notification' | 'closeNotification' | null, title?: string}>({ status: false, id: null });
    const [signature, setSignature] = useState<string>();
    const { merchantOrg } = useAppSettings();
    const {
        text,
        swipe_background,
        swipe_inactive_background,
        thumb_active_background,
        thumb_inactive_background
    } = useAccountSettingsStyles();
    const gStyle = useGeneralStyles();
    const {isLoadingAuth} = useAuthorization();

    useEffect(() => {

        if (member) {
            setFormData({
                allowed_sms: member.allowed_sms ? true : false,
                allowed_email: member.allowed_email ? true : false,
                allow_push_notifications: member.allow_push_notifications ? true : false
            });
        }

    }, [member]);

    useEffect(() => {

        if (memberUpdated) {
            handleMember( walletId || '' );
        }

    }, [memberUpdated]);

    const handleMember = async ( id: string) => {

        const member_ = await getMember({ walletId: id });

        if (member_) {
            setMember(member_);
        }

        setIsDisabled(!isLoading);
    };

    const handleChanges = (value: boolean, type: 'sms' | 'push_notification' | 'email') => {

        const title = type === 'sms' ? 'Opt in to text marketing' : type === 'email' ? 'Opt in to email marketing' : 'Opt in to push notification marketing';

        if (value === true) {
            setShowAlert({ status: true, id: 'notification', title });

        }
        else {
            setShowAlert({ status: true, id: 'closeNotification' });
        }
    };

    const submitFormData = () => {

        updateMember({
            member: {
                allowed_sms: formData.allowed_sms,
                allowed_email: formData.allowed_email,
                // allow_push_notifications: formData.allow_push_notifications
            },
            signature
        });

    };

    const handleCancelConditions = () => {

        setShowAlert({ status: false, id: null });
        setFormData({
            allowed_sms: member && member.allowed_sms ? true : false,
            allowed_email: member && member.allowed_email ? true : false,
            allow_push_notifications: member && member.allow_push_notifications ? true : false
        });
    };

    const handleAcceptConditions = () => {

        setShowAlert({ status: false, id: null });
    };

    if(isLoadingAuth){
        return <Loading isLoading={isLoadingAuth}/>;
    }

    return (
        <Section flex={1}>
            <Box
                minH={'100%'}
            >
                <Box
                    flexDirection={'row'}
                    justifyContent='space-between'
                    alignItems={'center'}
                    px={4}
                    pt={4}
                >
                    <Caption
                        color={text}
                        text='settings.header_title'
                    />
                    {
                        isDisabled ? <Button
                            bg={gStyle.button_background}
                            onPress={() => setIsDisabled(false)}
                        >
                            <Caption
                                text='general.edit'
                                color={gStyle.button_text}
                            />
                        </Button> : <Button
                            bg={gStyle.button_background}
                            onPress={() => {

                                setIsDisabled(true);
                                submitFormData();
                            }}>
                            <Caption
                                text='general.save'
                                color={gStyle.button_text}
                            />
                        </Button>
                    }
                </Box>
                <Box mt={4}>
                    <FormControl isDisabled={isDisabled}>
                        <Box
                            flexDirection={'row'}
                            justifyContent='space-between'
                            mb={2}
                            alignItems='center'
                            bg={gStyle.section_background}
                            px={4}
                            py={4}
                        >
                            <Caption
                                color={text}
                                text='settings.text_message'
                            />
                            <Switch
                                onTrackColor={swipe_background}
                                onThumbColor={thumb_active_background}
                                offThumbColor={thumb_inactive_background}
                                offTrackColor={swipe_inactive_background}
                                colorScheme='primary'
                                isChecked={formData?.allowed_sms}
                                onValueChange={() => {

                                    handleChanges(formData.allowed_sms ? false : true , 'sms');
                                    setFormData((prevState) => ({ ...prevState, allowed_sms: !prevState.allowed_sms }));
                                }}
                            />
                        </Box>
                        <Box
                            flexDirection={'row'}
                            justifyContent='space-between'
                            my={2}
                            px={4}
                            py={4}
                            alignItems='center'
                            bg={gStyle.section_background}
                        >
                            <Caption
                                color={text}
                                text='settings.email_notifications'
                            />
                            <Switch
                                onTrackColor={swipe_background}
                                onThumbColor={thumb_active_background}
                                offThumbColor={thumb_inactive_background}
                                offTrackColor={swipe_inactive_background}
                                isChecked={formData?.allowed_email}
                                onValueChange={() => {

                                    handleChanges(formData.allowed_email ? false : true, 'email');
                                    setFormData((prevState) => ({ ...prevState, allowed_email: !prevState.allowed_email }));
                                }}

                            />
                        </Box>
                        {/* <Box flexDirection={'row'} justifyContent='space-between' my={2} alignItems='center'>
                        <Caption text='settings.push_notifications'/>
                        <Switch colorScheme='primary' onValueChange={async () => {

                            await requestNotificationPermission();
                            setFormData((prevState) => ({ ...prevState, allow_push_notifications: !prevState.allow_push_notifications }));
                        }}
                        isChecked={formData?.allow_push_notifications}
                        />
                    </Box> */}
                    </FormControl>
                </Box>
            </Box>
            {
                showAlert.status && showAlert.id === 'notification' && (
                    <Alert
                        checkMessage={true}
                        signature={true}
                        setSignature={(sign) => setSignature(sign)}
                        title={showAlert.title || ''}
                        subHeaderText={t('settings.modal_paragraph')}
                        message={t('settings.modal_terms_and_conditions', [merchantOrg])}
                        setAcceptAlert={handleAcceptConditions}
                        setCancelAlert={handleCancelConditions}
                        showAlert={showAlert.status}
                        textConfirmButton='Opt in'
                    />
                )
            }
            {
                showAlert.status && showAlert.id === 'closeNotification' && (
                    <Alert
                        title={'Alert'}
                        message={'Are you sure you want to opt out from receiving notifications?'}
                        setAcceptAlert={handleAcceptConditions}
                        setCancelAlert={handleCancelConditions}
                        showAlert={showAlert.status}
                    />
                )
            }
        </Section>
    );
};

export default SettingsScreen