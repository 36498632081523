const baseColors = {
    primary: '#C6AB80',
    secondary: '#C6AB80',
    success: '#28a745',
    warning: '#ffc107',
    danger: '#dc3545',
    info: '#17a2b8',
    red: '#FF0000',
    yellow: '#ffc107',
    green: '#28a745',
    blue: '#17a2b8',
    purple: '#6f42c1',
    gray: '#818b8f',
    grayDark: '#545454',
    grayLight: '#dcdcdd',
    light: '#f7f7f7',
    dark: '#0D1821',
    transparencyLight: '#f7f7f7B3',
    transparencyDark: '#0D1821B3',
    white: '#ffffff',
    black: '#000000',
};
export const colors = {
    ...baseColors,
};
