/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import { Text as NativeText, ITextProps, IBoxProps, Box } from 'native-base';

import { I18nReacher, useI18n } from '@springbig/shared';

import { nullDefault } from '~/utils/misc';

export interface TypographyProps extends ITextProps {
    text?: I18nReacher;
    textValues?: string[];
    testID?: string;
}

const Typography = ({ text, textValues, children, ...props }: TypographyProps) => {

    const { t } = useI18n();

    return (
        <NativeText {...props} bg='transparent'>{
            nullDefault(text ? t(text, textValues || undefined) : children)
        }</NativeText>
    );
};

export const H1 = ({ text, textValues, ...props }: TypographyProps) => (

    <Typography
        text={text}
        textValues={textValues}
        my={3}
        fontWeight="bold"
        fontSize={20}
        {...props}
    />
);

export const H2 = ({ text, textValues, ...props }: TypographyProps) => (

    <Typography
        text={text}
        textValues={textValues}
        my={3}
        fontWeight="bold"
        fontSize={18}
        {...props}
    />
);

export const H3 = ({ text, textValues, ...props }: TypographyProps) => (

    <Typography
        text={text}
        textValues={textValues}
        fontWeight="bold"
        fontSize={16}
        {...props}
    />
);

export const H4 = ({ text, textValues, ...props }: TypographyProps) => (

    <Typography
        text={text}
        textValues={textValues}
        fontWeight="bold"
        fontSize={14}
        {...props}
    />
);

export const Caption = ({ text, textValues, ...props }: TypographyProps) => (

    <Typography
        text={text}
        textValues={textValues}
        fontWeight="normal"
        fontSize={14}
        {...props}
    />
);

export const Txt = (props: TypographyProps) => <Caption {...props} />;

export const CaptionError = ({ text, textValues, ...props }: TypographyProps) => (

    <Typography
        text={text}
        textValues={textValues}
        fontWeight="normal"
        fontSize={14}
        color='danger'
        {...props}
    />
);

export const Hr = (props: IBoxProps) => {

    return <Box
        borderWidth={2}
        borderStyle={'solid'}
        borderColor={'light'}
        w={'100%'}
        my={3}
        minH={1}
        {...props}
        borderTopWidth={0}
        borderLeftWidth={0}
        borderRightWidth={0}
        // eslint-disable-next-line react/no-children-prop
        children={null}
    />;
};

export default Typography;
