/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useState } from 'react';

import {
    Box,
    FormControl,
} from 'native-base';

import {
    isSwitchEnvCode,
    useQaAgent
} from '@springbig/shared';

import { Caption, CaptionError } from '~/shared/Typography';
import Section from '~/shared/Section';
import InputForm from '~/shared/InputForm';
import Button from '~/shared/Button';
import { useAppNavigate } from '..';
import { useGeneralStyles } from '~/shared/styles';


const PinScreen = () => {

    const {navigate}= useAppNavigate();
    const {
        setQaAgent
    } = useQaAgent();
    const [error, setError] = useState<Error | null>(null);
    const [pin, setPin] = useState<number | null>(null);
    const { text } = useGeneralStyles()

    const handleSubmit = () => {

        if (!isSwitchEnvCode(pin! * 1) ) {

            setError(
                new Error(
                    'The secret pin you have entered is not correct. ' +
                    'Ask admin for more information.'
                )
            );
            return;
        }

        setQaAgent(true);
        navigate('auth/select-env');

    };

    return (
        <Section flex={1} justifyContent='center'>
                <Box alignItems="center" w="100%">

                    <Caption w={'76%'} mb={4}>Enter Secret Pin</Caption>

                    <Box w="90%" maxWidth="300px">

                        <CaptionError>{error && error.message}</CaptionError>

                        <FormControl isRequired isInvalid={error ? true : false}>

                            <InputForm
                                labelColor={text}
                                label="general.pin"
                                type="number"
                                placeholder='1234'
                                onChange={value => setPin(value)}
                                errors={error?.message || null}
                            />
                            <Button onPress={handleSubmit}>Submit Secret</Button>
                        </FormControl>
                    </Box>
                </Box>
        </Section>
    );
};

export default PinScreen;
