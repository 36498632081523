/* eslint-disable max-lines-per-function */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useState, useEffect, useCallback } from 'react';
import {  Center } from 'native-base';

import { Reward, useRedeemRewards, useSpringBigStore, useI18n, getMember, RedeemResponse, useGetMember, updateMember, useAppSettings } from '@springbig/shared';
import _ from 'lodash';

import Alert from '~/shared/Alert';
import { DefaultValuesPointsProps } from '~/shared/RangeBar';
import Section from '~/shared/Section';
import RedeemedRewardModal from '~/shared/RedeemedReward';
import RewardSliderBox from './components/RewardSliderBox';
import RewardHeader from './components/RewardHeader';
import { useToast } from '~/hooks/toast';
import { useAuthorization } from '~/hooks/auth';
import Loading from '~/shared/Loading';

export type  BuilderRewards = {
    [key:string]: Reward[]
};

type ShowAlertState = {
    status: boolean,
    id: number | null,
    title?: string
};

const RewardsScreen = () => {

    const [showAlert, setShowAlert] = useState<ShowAlertState>({ status: false, id: null, title: undefined });
    const [successRedeem, setSuccessRedeem] = useState<RedeemResponse | null>(null);
    const { hasCustomBackground } = useAppSettings();

    const [rewards, setRewards] = useState<BuilderRewards>({});
    const [rewardCheckPoints, setRewardCheckPoints] = useState<DefaultValuesPointsProps[]>([]);
    const [rewardMaxAmount, setRewardMaxAmount] = useState<number>();
    const [showLoyaltyAlert, setShowLoyaltyAlert] = useState<boolean>(false);
    const [loyaltySign, setLoyaltySign] = useState<string>();

    const { errorToast } = useToast();
    const { t } = useI18n();
    const { member, walletId, setMember } = useSpringBigStore();
    const {isLoadingAuth} = useAuthorization();

    const {
        mutate: redeemReward,
        data: redeemData,
        isLoading: redeemIsLoading
    } = useRedeemRewards();

    const {
        refetch: refetchMember,
        isLoading: memberIsLoading
    } = useGetMember();

    const syncMember = useCallback(
        async (reward?: RedeemResponse) => {

            const updatedMember = await getMember({ walletId });

            if (!updatedMember) {
                return;
            }

            setMember(updatedMember);

            if (reward) {
                setSuccessRedeem(reward);
            }
        },
        [setMember, setSuccessRedeem, walletId]
    );

    const handleAcceptRedeemReward = useCallback((id: number, title?: string) => {

        setShowAlert({
            status: true,
            id,
            title
        });
    }, [setShowAlert]);

    const handleCancelRedeem = useCallback(() => {

        setShowAlert({
            status: false,
            id: null,
            title: undefined
        });
    }, [setShowAlert]);

    const handleAcceptRedeem = useCallback(() => {

        setShowAlert(prevState => ({
            ...prevState,
            status: false
        }));

        if (!member) {
            return;
        }

        const reward = member.rewards.find(el => el.id === showAlert.id);

        if (!reward) {
            return;
        }

        if (reward.item_value! > member.balance! ) {
            errorToast('You don\'t have enough balance to redeem this reward');
            return;
        }

        redeemReward({ reward_id: showAlert.id! });
    }, [member, errorToast, redeemReward, showAlert]);

    // ===================== OLD CODE =====================

    // useEffect(() => {

    //     if (!member) {
    //         return;
    //     }

    //     if (!member.rewards) {
    //         return;
    //     }

    //     const _rewards: BuilderRewards = {};
    //     const _rewardCheckPoints: DefaultValuesPointsProps[] = [];
    //     const indexValues = [
    //         ...new Set(
    //             member.rewards.map(
    //                 r => r.item_value! * 1
    //             )
    //         )
    //     ].sort(
    //         (a, b) => a - b
    //     );

    //     indexValues.forEach(key => {

    //         _rewards[`reward-${key}`] = member.rewards.filter(

    //             (reward) => reward.item_value === key
    //         );

    //         if (key) {
    //             _rewardCheckPoints.push({
    //                 value: key,
    //                 label: `${key}`,
    //                 divisbleValue: 1
    //             });
    //         }
    //     });
    //     const maxVal = Math.max(..._rewardCheckPoints.map(el => el.value));

    //     for (const cp of _rewardCheckPoints) {

    //         cp.divisbleValue = (cp.value * 100 / maxVal) / 100;
    //     }

    //     setRewards(_rewards);
    //     setRewardCheckPoints(_rewardCheckPoints);
    //     setRewardMaxAmount(maxVal);

    //     if (!member.allowed_loyalty) {
    //         setShowLoyaltyAlert(true);
    //     }

    //     setLoyaltySign(undefined);
    // }, [
    //     member,
    //     setRewards,
    //     setRewardCheckPoints,
    //     setRewardMaxAmount
    // ]);

    // ===================== NEW CODE =====================
    useEffect(() => {

        if (!member) {
            return;
        }

        if (!member.rewards) {
            return;
        }

        const _rewards: BuilderRewards = {};
        const _rewardCheckPoints: DefaultValuesPointsProps[] = [];
        const indexValues = [
            ...new Set(
                member.rewards.map(
                    r => r.item_value! * 1
                )
            )
        ].sort(
            (a, b) => a - b
        );

        indexValues.forEach(key => {

            _rewards[`reward-${key}`] = member.rewards.filter(

                (reward) => reward.item_value === key
            );

            if (key) {
                _rewardCheckPoints.push({
                    value: key,
                    label: `${key}`,
                    divisbleValue: 1
                });
            }
        });
        const maxVal = Math.max(..._rewardCheckPoints.map(el => el.value));

        for (const cp of _rewardCheckPoints) {

            cp.divisbleValue = (cp.value * 100 / maxVal) / 100;
        }

        const _values: DefaultValuesPointsProps[] = [];
        const _balance = member.balance || 0;

        const _next = _rewardCheckPoints && _.orderBy(_rewardCheckPoints, 'value', 'asc').find((point) => point.value > _balance);
        const nextValue = _next?.value || maxVal || 0;

        _values.push({
            value: nextValue,
            label: `${nextValue}`,
            divisbleValue: 1
        });

        const _first = _rewardCheckPoints && _.orderBy(_rewardCheckPoints, 'value', 'desc').find((point) => point.value < nextValue);
        const firstValue = _first?.value || 0;

        _values.push({
            value: firstValue,
            label: `${firstValue}`,
            divisbleValue: 0.0001
        });

        setRewards(_rewards);
        setRewardCheckPoints(_values);
        setRewardMaxAmount(nextValue);

        if (!member.allowed_loyalty) {
            setShowLoyaltyAlert(true);
        }

        setLoyaltySign(undefined);
    }, [
        member,
        setRewards,
        setRewardCheckPoints,
        setRewardMaxAmount
    ]);

    const handleLoyaltySign = async () => {

        setShowLoyaltyAlert(false);

        if (!loyaltySign) {
            return;
        }

        await updateMember({
            payload: {
                member: {
                    merchant_id: member?.merchant_id,
                    phone_number: member?.phone_number,
                    allowed_loyalty: true,
                },
                signature: loyaltySign
            },
            walletId: walletId!,

        });

        await syncMember();
    };

    useEffect(() => {

        if (!redeemData || !walletId) {
            return;
        }

        syncMember(redeemData);
    }, [redeemData, walletId, syncMember]);

    if(isLoadingAuth){
        return <Loading isLoading={isLoadingAuth}/>;
    }

    return (
        <Section
            pb={'10'}
            flex={1}
            w={'100%'}
        >
                <RewardHeader
                    member={member}
                    rewardCheckPoints={rewardCheckPoints}
                    rewardMaxAmount={rewardMaxAmount}
                    setShowLoyaltyAlert={setShowLoyaltyAlert}
                />
                    <Center mt={200} mb={8} pb={hasCustomBackground ? 32 : 0} alignItems='center'>
                        <RewardSliderBox handleAcceptRedeemReward={handleAcceptRedeemReward} redeemIsLoading={redeemIsLoading} rewards={rewards}/>
                    </Center>
                    <Alert
                        title={t('rewards.modal_title')}
                        message={t('rewards.redemption_text', [showAlert.title])}
                        setAcceptAlert={handleAcceptRedeem}
                        setCancelAlert={handleCancelRedeem}
                        showAlert={showAlert.status}
                    />
                    {
                        successRedeem && (
                            <RedeemedRewardModal
                                memberPhoneNumber={member?.phone_number || ''}
                                createdAt={successRedeem.created_at}
                                confirmationNumber={successRedeem.id}
                                returnWallet={() => setSuccessRedeem(null)}
                                isOpen={true}
                                image={successRedeem.reward.images}
                                description={successRedeem.reward.description || ''}
                                title={successRedeem.reward.item_name}
                            />
                        )
                    }
                    {
                        showLoyaltyAlert && (
                            <Alert
                                title={t('alerts.loyalty_opt_in')}
                                message={t('alerts.loyalty_opt_in_message')}
                                signature={true}
                                setSignature={setLoyaltySign}
                                setAcceptAlert={() => handleLoyaltySign()}
                                setCancelAlert={() => setShowLoyaltyAlert(false)}
                                showAlert={showLoyaltyAlert}
                                textConfirmButton='Opt in'
                            />
                        )
                    }
        </Section>
    );
};

export default RewardsScreen;