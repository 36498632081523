import {
    Button,
    IButtonProps
} from 'native-base';
import { F } from 'ts-toolbelt';

type OurProps = IButtonProps & { onPress?: F.Function; testID?: string }

const OurButton = (
    props: OurProps,
) => <Button
    {...props}
    _text={{
        color: props._text?.color,
    }}
    _hover={{
        bg: props.bg
    }}
    _pressed={{
        bg: props.bg
    }}
    _focus={{
        bg: props.bg
    }}
/>

export default OurButton;