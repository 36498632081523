/* eslint-disable @hapi/scope-start */ "use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    getMember: ()=>getMember,
    loginByPhoneNumber: ()=>loginByPhoneNumber,
    validatePin: ()=>validatePin,
    createMembers: ()=>createMembers,
    updateMember: ()=>updateMember,
    checkAuth: ()=>checkAuth,
    authPin: ()=>authPin,
    requestPin: ()=>requestPin,
    syncFbToken: ()=>syncFbToken,
    deleteAccount: ()=>deleteAccount,
    useCheckAuth: ()=>useCheckAuth,
    useAuthPin: ()=>useAuthPin,
    useRequestPin: ()=>useRequestPin,
    useGetMember: ()=>useGetMember,
    useLoginByPhoneNumber: ()=>useLoginByPhoneNumber,
    useGetMemberPin: ()=>useGetMemberPin,
    useCreateMembers: ()=>useCreateMembers,
    useUpdateMember: ()=>useUpdateMember,
    useSyncFbToken: ()=>useSyncFbToken,
    useDeleteAccount: ()=>useDeleteAccount
});
const _reactQuery = require("react-query");
const _ = require("../..");
const getMember = async (payload, opts)=>{
    const response = await _.api.get(`/wallet/v1/members/${payload.walletId}`, opts);
    return response.member;
};
const loginByPhoneNumber = async (payload, opts)=>{
    return await _.api.post('/join/v1/member_access_codes', payload, opts);
};
const validatePin = async (payload, opts)=>{
    const { accessCode , memberAccessCodeId , firebase_token  } = payload;
    return await _.api.get(`/join/v1/member_access_codes/${memberAccessCodeId}?access_code=${accessCode}&firebase_token=${firebase_token}`, opts);
};
const createMembers = async (payload, opts)=>{
    const response = await _.api.post('/native_app/v1/members/', payload, opts);
    return response;
};
const updateMember = async ({ payload , firebaseToken , walletId  }, opts)=>{
    await _.api.patch(`native_app/v2/members`, {
        member: {
            ...payload.member,
            interest_list: payload.interest_list
        },
        signature: payload.signature,
        firebaseToken
    }, opts);
    return await getMember({
        walletId
    }, opts);
};
const checkAuth = async (payload, opts)=>{
    return await _.api.get(`wallet/auth/v1/remember_me/${payload.stashboardOrMemberKey}?${payload.reference}=1`, opts);
};
const authPin = async (payload, opts)=>{
    return await _.api.put(`wallet/auth/v1/remember_me/${payload.stashboardOrMemberKey}?${payload.reference}=1`, {
        pin: payload.pin
    }, opts);
};
const requestPin = async (payload, opts)=>{
    return await _.api.put(`wallet/auth/v1/send_pin/${payload.stashboardOrMemberKey}?${payload.reference}=1`, opts);
};
const syncFbToken = async (payload, opts)=>{
    const response = await _.api.put(`native_app/v1/push_notification_settings/sync`, payload, opts);
    return response;
};
const deleteAccount = async (payload, opts)=>{
    const response = await _.api.post(`native_app/v2/members/destroy`, payload, opts);
    return response;
};
const useCheckAuth = (queryOptions = {})=>{
    const { setStore  } = (0, _.useApi)();
    const { setMobileSubscriberId , setPhoneNumber , setWalletId  } = (0, _.useSpringBigStore)();
    return (0, _reactQuery.useMutation)((payload)=>checkAuth(payload), {
        ...queryOptions,
        onSuccess: (data)=>{
            setStore({
                phoneNumber: data.phone_number
            });
            setStore({
                mobileSubscriberId: data.mobile_subscriber_id
            });
            setPhoneNumber(data.phone_number);
            setMobileSubscriberId(data.mobile_subscriber_id);
            setWalletId(data.merchant_member_key);
        }
    });
};
const useAuthPin = (queryOptions = {})=>{
    const { setStore  } = (0, _.useApi)();
    const { setMobileSubscriberId , setPhoneNumber , setWalletId  } = (0, _.useSpringBigStore)();
    return (0, _reactQuery.useMutation)((payload)=>authPin(payload), {
        ...queryOptions,
        onSuccess: (data)=>{
            setStore({
                phoneNumber: data.phone_number
            });
            setStore({
                mobileSubscriberId: data.mobile_subscriber
            });
            setPhoneNumber(data.phone_number);
            setMobileSubscriberId(data.mobile_subscriber);
            setWalletId(data.merchant_member_key);
        }
    });
};
const useRequestPin = (queryOptions = {})=>{
    return (0, _reactQuery.useMutation)((payload)=>requestPin(payload), {
        ...queryOptions
    });
};
const useGetMember = (queryOptions = {})=>{
    const { walletId , setMember , firebaseToken  } = (0, _.useSpringBigStore)();
    return (0, _reactQuery.useQuery)('members', ({ signal  })=>getMember({
            walletId,
            firebaseToken
        }, {
            signal
        }), {
        ...queryOptions,
        onSuccess: (data)=>{
            if (data) {
                setMember(data);
            }
        }
    });
};
const useLoginByPhoneNumber = (queryOptions = {})=>{
    const { setStore  } = (0, _.useApi)();
    const { setMemberAccessCodeId , setPhoneNumber , firebaseToken  } = (0, _.useSpringBigStore)();
    return (0, _reactQuery.useMutation)((payload)=>loginByPhoneNumber({
            ...payload,
            firebaseToken
        }), {
        ...queryOptions,
        onSuccess: (data, variables)=>{
            setStore({
                phoneNumber: variables.phone_number
            });
            setPhoneNumber(variables.phone_number);
            setMemberAccessCodeId(data.member_access_code_id);
        }
    });
};
const useGetMemberPin = (queryOptions = {})=>{
    const { setStore  } = (0, _.useApi)();
    const { setMobileSubscriberId , firebaseToken  } = (0, _.useSpringBigStore)();
    return (0, _reactQuery.useMutation)((payload)=>validatePin({
            ...payload,
            firebaseToken
        }), {
        ...queryOptions,
        onSuccess: (data)=>{
            setStore({
                mobileSubscriberId: data.mobile_subscriber
            });
            setMobileSubscriberId(data.mobile_subscriber);
        }
    });
};
const useCreateMembers = (queryOptions = {})=>{
    const queryClient = (0, _reactQuery.useQueryClient)();
    const { setMember , setWalletId , setMerchantId , firebaseToken  } = (0, _.useSpringBigStore)();
    return (0, _reactQuery.useMutation)((payload)=>createMembers({
            ...payload,
            firebaseToken
        }), {
        ...queryOptions,
        onSuccess: (data)=>{
            if (data && data.member?.wallet_id) {
                setMember(data.member);
                setWalletId(data.member.wallet_id);
            }
            if (data && data.member?.merchant_id) {
                setMerchantId(data.member.merchant_id);
            }
            queryClient.invalidateQueries('merchants');
        }
    });
};
const useUpdateMember = (queryOptions = {})=>{
    const { phoneNumber , member , firebaseToken , setMember , walletId  } = (0, _.useSpringBigStore)();
    return (0, _reactQuery.useMutation)((payload)=>updateMember({
            payload: {
                signature: payload.signature,
                interest_list: payload.interest_list,
                member: {
                    ...payload.member,
                    phone_number: phoneNumber,
                    merchant_id: member?.merchant_id
                }
            },
            walletId: walletId,
            firebaseToken
        }), {
        ...queryOptions,
        onSuccess: (data)=>{
            if (data) {
                setMember(data);
            }
        }
    });
};
const useSyncFbToken = (queryOptions = {})=>{
    return (0, _reactQuery.useMutation)((payload)=>syncFbToken(payload), queryOptions);
};
const useDeleteAccount = (queryOptions = {})=>{
    const { cleanState  } = (0, _.useSpringBigStore)();
    return (0, _reactQuery.useMutation)((payload)=>deleteAccount(payload), {
        ...queryOptions,
        onSuccess: ()=>{
            cleanState();
        },
        onError: (data)=>{
            console.log(data, 'error');
        }
    });
};
