/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Member, useAppSettings } from '@springbig/shared';
import { Box, Center, useColorMode } from 'native-base';

import SliderBar, { DefaultValuesPointsProps } from '~/shared/RangeBar';
import { Caption } from '~/shared/Typography';
import { useGeneralStyles, useRewardsStyles } from '~/shared/styles';
import Pressable from '~/shared/Pressable';

type RewardHeaderProps = {
    member?: Member | null;
    setShowLoyaltyAlert: (show: boolean) => void;
    rewardMaxAmount?: number
    rewardCheckPoints: DefaultValuesPointsProps[]
}

const RewardHeader = ({ member, rewardCheckPoints, rewardMaxAmount, setShowLoyaltyAlert }: RewardHeaderProps) => {

    const { hasCustomBackground, background } = useAppSettings();
    const { colorMode } = useColorMode();

    const { text } = useRewardsStyles();
    const gStyle = useGeneralStyles();

    return (
        <div
            style={{
                width: '440px',
                paddingTop: '70px',
                paddingBottom: '6px',
                position: 'fixed',
                top: '0px',
                zIndex: 999,
                background: hasCustomBackground ? `url(${colorMode === 'light' ? background?.light : background?.dark})` : gStyle.screen_background_hex
            }}
        >
            <Center alignItems='center' >
                <Caption
                    fontSize={'3xl'}
                    color={text}
                >{member?.balance || '0'}</Caption>
                <Caption
                    fontSize={'xl'}
                    text='rewards.available_points'
                    color={text}
                />
                {
                    member && !member.allowed_loyalty && (
                        <Pressable my={2}
                        onPress={() => setShowLoyaltyAlert(true)}
                        >
                            <Caption
                                text='rewards.click_here_to_earn'
                                color={gStyle.link}
                            />
                        </Pressable>
                    )
                }
                <Center my={2} w={'100%'} alignItems='center'>
                    {
                        member && <SliderBar
                            maxValue={rewardMaxAmount}
                            valuePoints={rewardCheckPoints}
                            defaultValue={member.balance}
                            dots={true}
                        />
                    }
                </Center>
            </Center>
        </div>
    );

};

export default RewardHeader;
