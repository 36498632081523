/* eslint-disable max-lines-per-function */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useState, useEffect } from 'react';
import { Box, Center } from 'native-base';
import { orderBy } from 'lodash';

import { useSpringBigStore, useRedeemRewards, RewardGrant, useI18n, RedeemResponse, useGetMember, useActiveRewardGrants } from '@springbig/shared';

import Alert from '~/shared/Alert';
import Section from '~/shared/Section';
import RedeemedRewardModal from '~/shared/RedeemedReward';
import NoDataView from '~/shared/NoData';
import OffersCard from './components/OfferCard';
import OffersHeader from './components/OfferHeader';
import { useAuthorization } from '~/hooks/auth';
import Loading from '~/shared/Loading';

type RewardsAlert = {
    status: boolean,
    id: number | null,
    title?: string
};

type SuccessRedeemResponse = RedeemResponse & {
    expires: string;
};

const OffersScreen = () => {

    const [showAlert, setShowAlert] = useState<RewardsAlert>({ status: false, id: null });
    const [rewardGrants, setRewardGrants] = useState<RewardGrant[]>([]);
    const [successRedeem, setSuccessRedeem] = useState<SuccessRedeemResponse | null>(null);
    const [rewardActiveId, setRewardActiveId] = useState<number>();

    const { mutate: activeRewardGrants, isLoading: loadingActivation, data } = useActiveRewardGrants();

    const { t } = useI18n();
    const { member } = useSpringBigStore();
    const { refetch: onRefresh, isLoading } = useGetMember();
    const {isLoadingAuth} = useAuthorization();

    const {
        mutate: redeemReward,
        data: redeemData
    } = useRedeemRewards();

    useEffect(() => {

        if (data) {
            setRewardActiveId(data.id);
        }

    }, [data]);

    useEffect(() => {

        if (!member) {
            return;
        }

        if (!member.reward_grants) {
            return;
        }

        setRewardGrants(

            orderBy(member.reward_grants, ['redemptions_available'], 'desc')
        );

    }, [member]);

    useEffect(() => {

        if (!redeemData) {
            return;
        }

        const { expiration } = rewardGrants.find(el => el.reward.id === redeemData.reward.id) || {};

        setSuccessRedeem({
            ...redeemData,
            expires: expiration || ''
        });

    }, [redeemData]);

    const handleAcceptRedeemReward = (id: number, title: string) => {

        setShowAlert({
            status: true,
            id,
            title
        });
    };

    const handleCancelRedeem = () => {

        setShowAlert({
            status: false,
            id: null,
            title: undefined
        });
    };

    const handleAcceptRedeem = () => {

        setShowAlert(prevState => ({
            ...prevState,
            status: false,
            title: undefined
        }));

        redeemReward({ reward_grant_id: showAlert.id! });
    };

    const handleBonusRewards = (rewardGrantId: number) => {
        
        if (rewardActiveId === rewardGrantId) {
            setRewardActiveId(undefined);
            return;
        }

        activeRewardGrants( rewardGrantId );
    };

    if(isLoadingAuth){
        return <Loading isLoading={isLoadingAuth}/>;
    }

    if (!rewardGrants || !rewardGrants.length) {
        return (
            <NoDataView message='No offers available' callback={onRefresh} isLoading={isLoading} />
        );
    }

    return (
        <Section pb={10} flex={1}  w={'100%'}>
                <Box mb={20} alignItems='center' w={'100%'} h={'90vh'} overflow={'scroll'}>
                    <OffersHeader member={member}/>
                    <OffersCard
                        rewardGrants={rewardGrants}
                        handleAcceptRedeemReward={handleAcceptRedeemReward}
                        handleBonusRewards={handleBonusRewards}
                        rewardActiveId={rewardActiveId}
                        loadingActivation={loadingActivation}
                    />
                </Box>
                <Alert
                    title={t('rewards.modal_title')}
                    message={t('rewards.redemption_text', [showAlert.title])}
                    setAcceptAlert={handleAcceptRedeem}
                    setCancelAlert={handleCancelRedeem}
                    showAlert={showAlert.status}
                />
                {
                    successRedeem && (
                        <RedeemedRewardModal
                            memberPhoneNumber={member?.phone_number || ''}
                            createdAt={successRedeem.created_at}
                            confirmationNumber={successRedeem.id}
                            returnWallet={() => setSuccessRedeem(null)}
                            isOpen={true}
                            image={successRedeem.reward.images}
                            expires={successRedeem.expires}
                            description={successRedeem.reward.description || ''}
                            title={successRedeem.reward.item_name}
                        />
                    )
                }
        </Section>
    );
};

export default OffersScreen