import SharedPinScreen from "./SharedPin";
const routes = {
    'w/:wallet_id': {
        path: '/w/:wallet_id',
        title: 'Pin',
        component: SharedPinScreen,
        auth: false,
    },
    //TODO add the env param here and based on that change the env
    'm/:stashboardKey': {
        path: 'm/:stashboard_key/',
        title: 'Pin',
        component: SharedPinScreen,
        auth: false,
    }
};
export default routes;
