/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { FormControl } from "native-base";

import { devLog } from "@springbig/shared";

import { useFormStyles, useGeneralStyles } from "~/shared/styles";
import InputForm from "~/shared/InputForm";
import Button from "~/shared/Button";

export type PinFormProps = {
    handleSubmitValues: (values: PinFormData) => void;
    isLoading: boolean;
    buttonText?: string;
};

export type PinFormData = {
    pin: string;
};

export const PinForm = ({
    buttonText = "Login",
    handleSubmitValues,
    isLoading
}: PinFormProps) => {
    const [formData, setData] = React.useState<PinFormData | null>(null);
    const [errors, setErrors] = React.useState<PinFormData | null>(null);
    const {
        input_background,
        input_border,
        input_label,
        input_text
    } = useFormStyles();
    const gStyle = useGeneralStyles();

    const validate = () => {
        if (formData === null) {
            setErrors({ ...errors, pin: "Pin is required" });
            return false;
        } else if (formData && formData.pin!.length < 3) {
            setErrors({ ...errors, pin: "Pin is too short" });
            return false;
        }

        setErrors(null);
        return true;
    };

    const onSubmit = () => {
        if (!validate()) {
            devLog("Validation failed");
        }

        if (!formData) {
            return;
        }

        handleSubmitValues(formData);
    };

    return (
        <FormControl isRequired isInvalid={errors ? true : false}>
            <InputForm
                labelColor={input_label}
                color={input_text}
                borderColor={input_border}
                bgColor={input_background}
                label="general.pin"
                type="number"
                placeholder="1234"
                onChange={value => setData({ ...formData, pin: value })}
                errors={errors && "pin" in errors ? errors.pin : null}
            />
            <Button
                bg={gStyle.button_background}
                _text={{ color: gStyle.button_text }}
                isDisabled={isLoading}
                onPress={onSubmit}
            >
                {buttonText}
            </Button>
        </FormControl>
    );
};

export default PinForm;
