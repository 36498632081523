import React from 'react';
import { Box } from 'native-base';
import { faGift } from '@fortawesome/free-solid-svg-icons';

import { SpIcon } from '~/shared/Icon';
import { Caption } from '~/shared/Typography';
import { Member, useI18n } from '@springbig/shared';
import { useGeneralStyles, useOffersStyles } from '~/shared/styles';
import { useAppNavigate } from '~/pages';
import Button from "~/shared/Button";

const OffersHeader = ({ member }: {member?: Member | null}) => {

    const {navigate, params} = useAppNavigate();
    const { t } = useI18n();
    const {
        icon,
        text
    } = useOffersStyles();
    const gStyles = useGeneralStyles();

    return (
        <Box
            px={4}
            pt={4}
            pb={4}
            w={'100%'}
            alignItems='center'
            flexDirection={'row'}
            justifyContent='space-between'
        >
            <Box flexDirection='row' alignItems={'center'}>
                <Caption
                    my={0}
                    mr={2}
                    text="offers.available_offers"
                    color={text}
                />
                <SpIcon
                    color={icon}
                    icon={faGift}
                />
            </Box>
            {
                member && member.referrals.active && <Button
                    bg={gStyles.button_background}
                    _text={{
                        color: gStyles.button_text,
                    }}
                    onPress={() => navigate(`w/${params.wallet_id}/refer`)}
                >
                    {t('offers.refer_friend')}
                </Button>
            }
        </Box>
    );
};

export default OffersHeader;
