import { faUser } from "@fortawesome/free-solid-svg-icons";
import AccountInformationScreen from "./Account";
import DeleteAccountScreen from "./DeleteAccount";
import AccountHistoryScreen from "./History";
import ProfileMenuScreen from "./Menu";
import ProfileScreen from "./Profile";
import SettingsScreen from "./Setting";
const routes = {
    'w/:wallet_id/profile': {
        path: 'w/:wallet_id/profile',
        title: 'Profile',
        component: ProfileScreen,
        auth: true,
        exact: true,
        showTab: true,
        icon: faUser
    },
    'w/:wallet_id/profile/menu': {
        path: 'w/:wallet_id/profile/menu',
        title: 'Profile Menu',
        component: ProfileMenuScreen,
        auth: true,
        exact: true,
        goBackPath: 'w/:wallet_id/profile',
    },
    'w/:wallet_id/profile/menu/account': {
        path: 'w/:wallet_id/profile/menu/account',
        title: 'Account',
        component: AccountInformationScreen,
        auth: true,
        exact: true,
        goBackPath: 'w/:wallet_id/profile/menu',
    },
    'w/:wallet_id/profile/menu/account/remove': {
        path: 'w/:wallet_id/profile/menu/account/remove',
        title: 'Remove Account',
        component: DeleteAccountScreen,
        auth: true,
        exact: true,
        goBackPath: 'w/:wallet_id/profile/menu',
    },
    'w/:wallet_id/profile/menu/settings': {
        path: 'w/:wallet_id/profile/menu/settings',
        title: 'Settings',
        component: SettingsScreen,
        auth: true,
        exact: true,
        goBackPath: 'w/:wallet_id/profile/menu',
    },
    'w/:wallet_id/profile/menu/history': {
        path: 'w/:wallet_id/profile/menu/history',
        title: 'History',
        component: AccountHistoryScreen,
        auth: true,
        exact: true,
        goBackPath: 'w/:wallet_id/profile/menu',
    },
};
export default routes;
