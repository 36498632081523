/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useEffect, useState } from 'react';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { Box, FormControl } from 'native-base';

import { Member, useI18n, I18nReacher, devLog } from '@springbig/shared';

import { SpIcon } from '~/shared/Icon';
import InputForm from '~/shared/InputForm';
import { useAccountInformationStyles, useFormStyles, useGeneralStyles } from '~/shared/styles';
import Pressable from '~/shared/Pressable';
import Button from '~/shared/Button';

export type GenFormProps = {
    handleSubmitValues: (values: any) => void;
    isLoading: boolean;
    formKeys: {
        label: I18nReacher;
        name: keyof Member;
        type: string;
        placeholder?: string;
        value?: any;
        required?: boolean
    }
}

export const GenForm = ({ handleSubmitValues, isLoading, formKeys }: GenFormProps) => {

    const {
        input_background,
        input_border,
        input_label,
        input_text,
    } = useFormStyles();
    const { icon } = useAccountInformationStyles();
    const gStyle = useGeneralStyles();

    const [formData, setData] = useState<GenFormProps['formKeys']>(formKeys);
    const [errors, setErrors] = useState<string | null>(null);
    const [readOnly, setReadOnly] = useState<boolean>(true);
    const { t } = useI18n();

    const validate = () => {

        if (formData?.value === undefined) {
            setErrors(`${t(formData?.label)} is required`);
            return false;
        }

        if (formData?.value && formData.value.length < 2) {
            setErrors(`${t(formData?.label)} to short`);
            return false;
        }

        setErrors(null);
        return true;
    };

    useEffect(() => {

        if (!isLoading) {
            setReadOnly(true);
        }

    }, [isLoading]);

    const onSubmit = () => {

        if (!validate()) {

            devLog('Validation failed');
            return;
        }

        handleSubmitValues(formData);
    };

    return (
        <FormControl isRequired isInvalid={errors ? true : false}>
            <Box h={20} flexDirection={'row'} w={'100%'} alignItems='center' justifyContent='space-between'>
                <InputForm
                    bg={input_background}
                    borderColor={input_border}
                    labelColor={input_label}
                    textColor={input_text}
                    w={'60%'}
                    fontSize={16}
                    isReadOnly={readOnly}
                    _readOnly={{ borderWidth: 0 }}
                    h={8}
                    label={formData?.label || 'general.loading'}
                    type={formData?.type || 'text'}
                    placeholder={formData?.placeholder}
                    defaultValue={formData?.value}
                    onChange={value => formData && setData({ ...formData, value })}
                    errors={
                        errors ? errors : null
                    }
                />
                {
                    formKeys.name !== 'phone_number' && (
                        <Box>
                            {
                                readOnly ? <Pressable onPress={() => setReadOnly(false)}>
                                    <SpIcon
                                        color={icon}
                                        icon={faEdit}
                                    />
                                </Pressable> : (
                                    <Box h={16} mt={-1} justifyContent='flex-end'>
                                        <Box flexDirection={'row'}>
                                            <Button
                                                bg={gStyle.button_background}
                                                _text={{
                                                    color: gStyle.button_text,
                                                }}
                                                mr={1}
                                                h={8}
                                                w={16}
                                                m={0}
                                                p={1}
                                                onPress={() => onSubmit()}
                                                isDisabled={isLoading}
                                            >
                                                {t('general.save')}
                                            </Button>
                                            <Button
                                                bg={gStyle.button_danger_background}
                                                _text={{
                                                    color: gStyle.button_danger_text,
                                                }}
                                                h={8}
                                                w={16}
                                                m={0}
                                                p={1}
                                                isDisabled={isLoading}
                                                onPress={() => {

                                                    setData({ ...formData, value: formKeys.value });
                                                    setReadOnly(true);
                                                    setErrors(null);

                                                }}
                                            >
                                                {t('general.cancel')}
                                            </Button>
                                        </Box>
                                    </Box>
                                )
                            }
                        </Box>
                    )
                }
            </Box>
        </FormControl>
    );
};


export const accountForms: GenFormProps['formKeys'][] = [
    {
        label: 'general.phone_number',
        name: 'phone_number',
        type: 'text',
        required: true
    },
    {
        label: 'general.email',
        name: 'email',
        type: 'email',
        required: true
    },
    {
        label: 'general.first_name',
        name: 'first_name',
        type: 'text',
        required: true
    },
    {
        label: 'general.last_name',
        name: 'last_name',
        type: 'text',
        required: true
    },
    {
        label: 'general.birthday',
        name: 'birthday',
        type: 'text',
        required: true
    },
    {
        label: 'general.address1',
        name: 'address1',
        type: 'text',
        required: true
    },
    {
        label: 'general.address2',
        name: 'address2',
        type: 'text',
        required: true
    },
    {
        label: 'general.city',
        name: 'city',
        type: 'text',
        required: true
    },
    {
        label: 'general.region',
        name: 'region',
        type: 'text',
        required: true
    },
    {
        label: 'general.zip',
        name: 'zip',
        type: 'text',
        required: true
    }
];
