/* eslint-disable @typescript-eslint/no-explicit-any, require-jsdoc */
import * as React from 'react';
import { Nullable } from 'ts-toolbelt/out/Union/Nullable';

type ErrorBoundaryProps = React.PropsWithChildren<{
    name: string
}>;

type ErrorBoundaryState = {
    error: Nullable<Error>
    errorInfo: Nullable<React.ErrorInfo>
};

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {


    state: Readonly<ErrorBoundaryState> = {
        error: null,
        errorInfo: null
    };

    /**
     *
     * @param error
     * @param errorInfo
     */
    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {

        this.setState({
            error,
            errorInfo
        });
    }

    render() {

        if (this.state.errorInfo) {

            return (<>
                <h1>Something went wrong!</h1>

                {
                    this.props.name && (
                        <p><small>{this.props.name}</small></p>
                    )
                }

                <code>
                    <pre>
                        { this.state.error!.toString() }
                        <br />
                        { this.state.errorInfo!.componentStack }
                    </pre>
                </code>
            </>);
        }

        return <>{this.props.children}</>;
    }
}
