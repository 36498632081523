/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Box, Center, ScrollView } from 'native-base';
import { H2 } from '~/shared/Typography';
import ItemCard from '~/shared/ItemCard';
import { BuilderRewards } from '../Reward';
import { useGeneralStyles, useRewardsStyles } from '~/shared/styles';

export type RewardSliderBoxProps = {
    rewards: BuilderRewards;
    redeemIsLoading: boolean;
    handleAcceptRedeemReward: (id: number, title?: string) => void;
}

const RewardSliderBox = ({ rewards, redeemIsLoading, handleAcceptRedeemReward }: RewardSliderBoxProps) => {

    const {
        text
    } = useRewardsStyles();
    const gStyle = useGeneralStyles();

    return (
        <Box
        >
            <Center
                flexDirection={'row'}
                flexWrap={'wrap'}
                justifyContent="space-evenly"
                w={'100%'}
            >
                {
                    Object.entries(rewards).map(([key, value]) => {

                        const processKey = key.split('-')[1];
                        return (
                            <Box key={key} overflowX='hidden' w={'440px'}>
                                <Box>
                                    <H2
                                        color={text}
                                        px={4}
                                        alignItems={'flex-start'}
                                        text={'rewards.title_points_rewards'}
                                        textValues={[`${processKey}`]}
                                    />
                                </Box>
                                <Box
                                    key={key}
                                    flexDirection='column'
                                    bg={gStyle.section_background}
                                    overflowX='scroll'
                                >
                                    <Box
                                        bg={gStyle.section_background}
                                        py={4}
                                        px={1}
                                        w={'100%'}
                                        flexDirection='row'
                                    >
                                        {
                                            value.map(reward => {

                                                return (
                                                    <Box key={reward?.id}>
                                                        <ItemCard
                                                            testID={`reward-box`}
                                                            loadingComponent={false}
                                                            boxProps={{
                                                                mx: '2'
                                                            }}
                                                            handleCallback={handleAcceptRedeemReward}
                                                            isDisabled={redeemIsLoading}
                                                            id={reward.id}
                                                            title={reward.name}
                                                            imageUrl={reward.images.length > 0 ? reward.images[0].url : undefined}
                                                        />
                                                    </Box>
                                                );
                                            })
                                        }
                                    </Box>
                                </Box>
                            </Box>
                        );
                    })
                }
            </Center>
        </Box>
    );
};

export default RewardSliderBox;
