import React, { useState, useEffect } from 'react';

import { Box } from 'native-base';
import { faAward, faCartShopping, faCoins, faLink, faShareAlt, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

import { LinkExtension, observer } from '@springbig/shared';

import Image from '~/shared/Image';
import { SpIcon } from '~/shared/Icon';
import { Caption, H4 } from '~/shared/Typography';
import { useGeneralStyles, useMessagesStyles } from '~/shared/styles';
import { useAppNavigate } from '~/pages';
import Pressable from '~/shared/Pressable';

export type MessageLinkBoxProps = {
    url: string;
    type: 'any_link' | 'track_offer' | 'target_wallet' | null;
    link?: LinkExtension;
    stashboardKey: string;
}

const messageLinkValues:LinkProps[] = [
    {
        type: 'any_link',
        icon: faLink,
        caption: 'View more',
        preffix: '',
        title: ''
    },
    {
        type: 'track_offer',
        icon: faCoins,
        caption: 'View offer',
        preffix: 'offers',
        title: 'New offer available',
    },
    {
        type: 'track_offer',
        icon: faAward,
        caption: 'View rewards',
        preffix: 'rewards',
        title: 'Click here to view rewards',
    },
    {
        type: 'target_wallet',
        icon: faUserCircle,
        caption: 'View profile',
        preffix: 'profile',
        title: 'Click here to make sure your account is up to date'
    },
    {
        type: 'target_wallet',
        icon: faCartShopping,
        caption: 'Shop Online',
        preffix: 'checkout',
        title: 'Click here to view our menu'
    },
    {
        type: 'target_wallet',
        icon: faAward,
        caption: 'View rewards',
        preffix: 'rewards',
        title: 'Click here to view rewards',
    },
    {
        type: 'target_wallet',
        icon: faShareAlt,
        caption: 'refer a friend',
        preffix: 'offers',
        title: 'Click here to sign up your friends today!'
    }
];

type LinkProps = {
    type: 'any_link' | 'track_offer' | 'target_wallet' | null;
    icon: IconDefinition;
    caption: string;
    title: string;
    preffix: string;
}

const MessageLinkBox = ({ type, url, link, stashboardKey }: MessageLinkBoxProps) => {

    const {
        link_box_background,
        link_box_border,
        link_box_icon,
        text,
        icon,
        link_box_icon_background
    } = useMessagesStyles();
    const gStyle = useGeneralStyles();

    const validImage = link && link.thumbnail?.url ? `${link.thumbnail?.url}?h=50&w=100` : null;
    const {navigate, params} = useAppNavigate();
    const [linkProps, setLinkProps] = useState<LinkProps[]>();

    useEffect(() => {

        if (type === 'any_link') {
            const _linkProps = messageLinkValues.filter(l => l.type === 'any_link');
            setLinkProps(_linkProps);
        }

        if (type !== 'any_link' && url) {
            const _linkProps = messageLinkValues.filter((el) => el.type === type && url.includes(el.preffix));

            setLinkProps(_linkProps);
        }

    },[type, url]);



    const redirectUrl = (_url: string) => {

        observer.emit('any-link-clicked', {
            type: type || 'none',
            url: _url,
            _key: stashboardKey
        });

        if (type === 'any_link') {
            window.open(url, '_blank', 'noopener,noreferrer')
        }

        if (/.+rewards/i.test(_url)) {
            navigate(`w/${params.wallet_id}/rewards`);
        }

        if (/.+offers/i.test(_url)) {
            navigate(`w/${params.wallet_id}/offers`);
        }

        if (/.+profile/i.test(_url)) {
            navigate(`w/${params.wallet_id}/profile`);
        }

        if (/.+checkout/i.test(_url)) {
            // navigate('Cart');
        }
    };

    if (linkProps) {
        return (
            <Box
                position='relative'
                h={'40'}
                justifyContent={'center'}
                p={2}
            >
                {
                    linkProps.map((linkData, i) => (

                        <Pressable
                            key={`${i * Math.random()}-${linkData.type}`}
                            borderRightRadius={8}
                            borderLeftRadius={8}
                            px={4}
                            py={2}
                            borderWidth={1}
                            mr={3}
                            flexDirection={'row'}
                            borderColor={link_box_border}
                            bg={link_box_background}
                            w={'56'}
                            h={'20'}
                            alignItems={'center'}
                            onPress={() => redirectUrl(url)}
                        >
                            <Box
                                position={'absolute'}
                                top={-8}
                                left={-8}
                                bg={link_box_icon_background}
                                p={1}
                                borderRadius={'100'}
                            >
                                <SpIcon
                                    color={icon || 'white'}
                                    size={12}
                                    icon={linkData.icon}
                                />
                            </Box>
                            <Box>
                                {
                                    validImage ? <Image
                                        borderLeftRadius={8}
                                        borderRightRadius={8}
                                        w={'16'}
                                        h={'16'}
                                        uri={validImage}
                                    /> : <Box>
                                        <SpIcon
                                            color={link_box_icon || 'white'}
                                            size={54}
                                            icon={linkData.icon}
                                        />
                                    </Box>
                                }
                            </Box>
                            <Box w={'32'} ml={2} h={'100%'} justifyContent='space-between'>
                                <H4 fontWeight={'normal'} color={text} fontSize={11}>{link ? link.title : linkData.title }</H4>
                                <Caption textAlign={'right'} color={gStyle.link} fontSize={11}>{linkData.caption}</Caption>
                            </Box>
                        </Pressable>
                    ))
                }
            </Box>
        );
    }

    return (
        <Box></Box>
    );

};

export default MessageLinkBox;
