/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useCallback, useEffect, useState } from "react";
import { Box } from "native-base";

import {
    AuthPayload,
    useAuthPin,
    useCheckAuth,
    useRequestPin,
    useSpringBigStore
} from "@springbig/shared";

import PinForm, { PinFormData } from "./components/PinForm";
import { Caption, CaptionError, H3 } from "~/shared/Typography";
import Section from "~/shared/Section";
import { useAuthStyles } from "~/shared/styles";
import { useAppNavigate } from "..";
import { useEffectOnce } from "usehooks-ts";
import Pressable from "~/shared/Pressable";

const SharedPinScreen = () => {
    const [error, setError] = useState<string | null>(null);
    const [stashboardOrMemberKey, setStashboardOrMemberKey] = useState<
        AuthPayload["reference"]
    >();
    const [disabled, setDisabled] = useState(false);

    const { mobileSubscriberId, walletId } = useSpringBigStore();

    const { mutate: checkAuth, error: authError } = useCheckAuth();
    const { mutate: authPin, isLoading, error: pinError } = useAuthPin();
    const { mutate: requestPin, error: requestPinError } = useRequestPin();

    const { text, icon } = useAuthStyles();
    const { navigate, params } = useAppNavigate();

    const handleResendCode = () => {
        const _params =
            stashboardOrMemberKey === "stashboard"
                ? params.stashboard_key
                : params.wallet_id;

        createRequestPin(_params!);
        setDisabled(true);
    };

    const handleSubmit = useCallback(
        async (values: PinFormData) => {
            if (stashboardOrMemberKey) {
                const _params =
                    stashboardOrMemberKey === "stashboard"
                        ? params.stashboard_key
                        : params.wallet_id;

                authPin({
                    stashboardOrMemberKey: _params!,
                    pin: btoa(values.pin),
                    reference: stashboardOrMemberKey!
                });

                // if(response){
                //     setStore({
                //         phoneNumber: data.phone_number
                //     });

                //     setPhoneNumber(data.phone_number);
                //     setMobileSubscriberId(data.mobile_subscriber_id);
                //     setWalletId(data.merchant_member_key);
                // }

                // console.log(response, "response");
            }
        },
        [stashboardOrMemberKey, params, authPin]
    );

    const getAuthMember = useCallback(
        async (id: string, reference: AuthPayload["reference"]) => {
            checkAuth({
                stashboardOrMemberKey: id,
                reference
            });
        },
        [checkAuth]
    );

    const createRequestPin = useCallback(
        async (id: string) => {
            requestPin({
                stashboardOrMemberKey: id,
                reference: stashboardOrMemberKey!
            });
        },
        [stashboardOrMemberKey, requestPin]
    );

    useEffect(
        () => {
            if (authError) {
                const _params =
                    stashboardOrMemberKey === "stashboard"
                        ? params.stashboard_key
                        : params.wallet_id;
                createRequestPin(_params!);
            }
        },
        [authError]
    );

    useEffectOnce(() => {
        if (params && params.stashboard_key) {
            getAuthMember(params.stashboard_key, "stashboard");
            setStashboardOrMemberKey("stashboard");
        }

        if (params && params.wallet_id) {
            getAuthMember(params.wallet_id, "member");
            setStashboardOrMemberKey("member");
        }
    });

    useEffect(
        () => {
            if (mobileSubscriberId && params.wallet_id) {
                navigate(`w/${params.wallet_id}/profile`);
            }

            if (mobileSubscriberId && params.stashboard_key && walletId) {
                navigate(`w/${walletId}/messages/${params.stashboard_key}`);
            }

        },
        [mobileSubscriberId, navigate, params, walletId]
    );

    useEffect(
        () => {
            if (requestPinError) {
                console.log(requestPinError, "requestPinError");
                navigate("404");
            }
        },
        [requestPinError, navigate]
    );

    return (
        <Section flex={1} justifyContent="center">
            <Box
                alignItems="center"
                w="100%"
                h={"100%"}
                mb={40}
                justifyContent={"center"}
            >
                <H3 color={text} mb={4} text="auth.register.title" />
                <Caption
                    color={text}
                    w={"80%"}
                    mb={4}
                    textAlign="center"
                    text="auth.validation.shared_pin_paragraph"
                />
                <Box w="80%">
                    <CaptionError>
                        {error && error}
                    </CaptionError>
                    <PinForm
                        isLoading={false}
                        handleSubmitValues={handleSubmit}
                    />
                </Box>
                <Pressable
                    w={"100%"}
                    alignItems={"center"}
                    cursor={disabled ? "not-allowed" : "pointer"}
                    onPress={() => handleResendCode()}
                >
                    <Caption
                        color={disabled ? text : icon}
                        opacity={disabled ? 0.3 : 1}
                        fontWeight="bold"
                        w={"80%"}
                        my={4}
                        textAlign="center"
                        text="auth.validation.forgot_code"
                    />
                </Pressable>
                <Caption
                    mt={10}
                    color={icon}
                    w={"80%"}
                    my={1}
                    textAlign="center"
                    text="general.terms_and_conditions"
                />
                <Caption
                    color={icon}
                    w={"80%"}
                    my={1}
                    textAlign="center"
                    text="general.privacy_policy"
                />
            </Box>
        </Section>
    );
};

export default SharedPinScreen;
