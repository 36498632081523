/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
import React, { useRef, useState } from 'react';
// import { useWindowDimensions } from 'react-native';
import { Box, Center } from 'native-base';
import dayjs from 'dayjs';

import { Stashboard, StashboardMedia } from '@springbig/shared';

import { Caption, H3 } from '~/shared/Typography';

import { SliderLinkCard } from './MessagesSliderLinkCard';
import isYesterday from 'dayjs/plugin/isYesterday';
import { useEffect } from 'react';
import { useGeneralStyles, useMessagesStyles } from '../../../shared/styles';
import { useAppNavigate } from '~/pages';
import Carousel from '~/shared/Carousel';
import Pressable from '~/shared/Pressable';

dayjs.extend(isYesterday);

export type MessagesCarouselProps = {
    data: Stashboard;
    hasMoreContent?: boolean;
}

const MessageCarousel = ({ data, hasMoreContent }: MessagesCarouselProps) => {

    const {
        text,
    } = useMessagesStyles();
    const gStyle = useGeneralStyles();

    // const { width } = useWindowDimensions();
    const {navigate, params} = useAppNavigate();
    // const carouselRef = useRef<Carousel<any>>(null);
    const [stashboardMedia, setStashboardMedia] = useState<StashboardMedia[]>([]);

    const handleMms = ( stashboard: StashboardMedia[] ) => {

        return stashboard;
    };

    const handleDate = (date: string) => {

        const now = dayjs();
        const msgSentAt = dayjs(date);
        const today = now.format('MM/DD/YYYY');
        const yesterday = now.subtract(1, 'day').format('MM/DD/YYYY');
        const msgDate = msgSentAt.format('MM/DD/YYYY');
        const msgTime = msgSentAt.format('h:mm a');

        if (msgDate === today) {
            return `Today @ ${msgTime}`;
        }

        if (msgDate === yesterday) {
            return `Yesterday @ ${msgTime}`;
        }

        return `${msgDate} @ ${msgTime}`;
    };

    const handlePressMessage = (stashboardMessage: Stashboard) => {

        navigate(`w/${params.wallet_id}/messages/${stashboardMessage._key}`, {
            state: {
                message: stashboardMessage
            },
        });
    };

    useEffect(() => {

        if (data) {

            setStashboardMedia(handleMms(data.stashboard_media));
        }
    },[data]);

    return (
        <Center
            pb={10}
            w={'100%'}
            position='relative'
            flexDirection={'column'}
        >
            <H3
                color={text}
                mt={1}
            >
                { handleDate(data.sent_at) }
            </H3>
            <Carousel images={stashboardMedia}/>
            {
                hasMoreContent ? <Box
                    w={'100%'}
                    px={1}
                    mb={8}
                    mt={0}
                >
                    <Box w='100%' mt={6} >
                        <Pressable
                        onPress={() => handlePressMessage(data)}
                        fontWeight={'bold'}
                        >
                            <Caption w={'100%'}
                                textAlign='center'
                                fontSize={14}
                                color={text}
                            >{data.sms_content && data.sms_content.slice(0, stashboardMedia.length > 1 ? 220 : 1100)}
                                {
                                    stashboardMedia.length > 1 && data.sms_content && data.sms_content.length > 220 && (
                                        <Caption
                                            color={gStyle.link}
                                            fontWeight={'bold'}
                                        >
                                            {' '}...See More
                                        </Caption>
                                    )
                                }
                                {
                                    stashboardMedia.length === 0 && data.sms_content && data.sms_content.length > 1100 && (
                                        <Caption
                                            color={gStyle.link}
                                            fontWeight={'bold'}
                                        >
                                            {' '}...See More
                                        </Caption>
                                    )
                                }

                            </Caption>
                        </Pressable>
                        <SliderLinkCard data={data}/>
                    </Box>
                </Box> : <Box w='100%' px={1} mt={6}>
                    <Pressable
                    onPress={() => handlePressMessage(data)}
                    fontWeight={'bold'}
                    >
                        <Caption
                            color={text}
                            w={'100%'}
                            textAlign='center'
                            fontSize={14}
                        >{data.sms_content || ''}
                        </Caption>
                    </Pressable>
                    <SliderLinkCard data={data}/>
                </Box>
            }
        </Center>
    );
};

export default MessageCarousel;
