/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react";
import { Box } from "native-base";

import Section from "~/shared/Section";
import { useAuthStyles } from "~/shared/styles";
import { H1, H3 } from "~/shared/Typography";
import { useAuthorization } from "~/hooks/auth";
import Loading from "~/shared/Loading";

const NotFound = () => {

    const { text } = useAuthStyles();
    const {isLoadingAuth} = useAuthorization();

    if(isLoadingAuth){
        return <Loading isLoading={isLoadingAuth}/>;
    }

    return (
        <Section flex={1} justifyContent="center">
            <Box
                alignItems="center"
                w="100%"
                h={"98vh"}
                mb={40}
                justifyContent={"center"}
            >
                <H1 fontSize={"8xl"}>
                    404
                </H1>
               <H3 color={text} textAlign={'center'} mb={4}>It looks like that page doesn't exist - please check the URL and try again</H3>
            </Box>
        </Section>
    );
};

export default NotFound;
