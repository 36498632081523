/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import { Center } from 'native-base';
import Pressable from '~/shared/Pressable'
import { H2 } from '~/shared/Typography';
import { useMenuStyles } from '~/shared/styles';
import { useAppNavigate } from '~/pages';
import { AppRouteProps } from '~/pages';

export type CardMenuProps = {
    title: string;
    path?: AppRouteProps;
    handleLogout?: () => void;
    testID?: string;
};

const CardMenu = ({ title, path, handleLogout, testID }: CardMenuProps) => {

    const navigation = useAppNavigate();
    const {
        card_background,
        card_text,
        card_border
    } = useMenuStyles();

    const handlePress = (): void => {
        if (path) {
            navigation.navigate(path);
        }
    };

    return (
        <Pressable
            testID={testID}
            onPress={handleLogout ? handleLogout : handlePress}
            my={2}
            px={4}
            bg={card_background}
            borderColor={card_border}
            borderWidth={1}
            w={'100%'}>
            <Center alignItems="center" w={'100%'}>
                <H2
                    color={card_text}
                >
                    {title}
                </H2>
            </Center>
        </Pressable>
    );
};

export default CardMenu;