/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import { Center } from 'native-base';
import OurPressable from '~/shared/Pressable'
import { H2 } from '~/shared/Typography';
import { useMenuStyles } from '~/shared/styles';
import { useAppNavigate } from '~/pages';
import { AppRouteProps } from '~/pages';

export type CardMenuAdditionalLinkrops = {
    url: string;
    external: boolean;
    screen?: AppRouteProps;
    handleLogout?: () => void;
    label: string;
};

const CardMenuAdditionalLink = ({ screen, external, url, label }: CardMenuAdditionalLinkrops) => {

    const navigation = useAppNavigate();
    const {
        card_background,
        card_text,
        card_border
    } = useMenuStyles();

    const handlePress = (): void => {
        if (external) {
            window.open(url);
        }

        if (screen) {
            navigation.navigate(screen);
        }
    };

    return (
        <OurPressable
            onPress={handlePress}
            my={2}
            px={4}
            bg={card_background}
            borderColor={card_border}
            borderWidth={1}
            w={'100%'}>
            <Center alignItems="center" w={'100%'}>
                <H2
                    color={card_text}
                >
                    {label}
                </H2>
            </Center>
        </OurPressable>
    );
};

export default CardMenuAdditionalLink;