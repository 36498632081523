/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Box, Center } from 'native-base';

import { RewardGrant } from '@springbig/shared';

import RewardGrantCard from '~/shared/RewardGrantCard';

type OffersCardProps = {
    rewardGrants: RewardGrant[];
    handleAcceptRedeemReward: (id: number, title: string) => void;
    handleBonusRewards: (id: number) => void;
    rewardActiveId: number | undefined;
    loadingActivation: boolean;
}

const OffersCard = ({ handleAcceptRedeemReward, handleBonusRewards, loadingActivation, rewardActiveId, rewardGrants }:OffersCardProps) => {

    return (
        <Box w={'100%'}>
                {rewardGrants && rewardGrants.length > 0 && rewardGrants.map((offer, index) => {

                    return (
                        <RewardGrantCard
                            mt={index === 0 ? 0 : 2}
                            key={offer.id}
                            offer={offer}
                            handleAcceptRedeemReward={handleAcceptRedeemReward}
                            handleBonusRewards={handleBonusRewards}
                            rewardActiveId={rewardActiveId}
                            loadingActivation={loadingActivation}
                        />
                    );
                })
                }
        </Box>
    );
};

export default OffersCard;
