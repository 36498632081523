/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import { Box, IBoxProps, useColorMode } from 'native-base';

import { useAppSettings } from '@springbig/shared';

import { useGeneralStyles } from './styles';

const Section = ({ children, ...props }: IBoxProps) => {

    const { hasCustomBackground, background } = useAppSettings();
    const gStyle = useGeneralStyles();
    const { colorMode } = useColorMode();

    if (hasCustomBackground && background) {

        const bgLight = background.light;
        const bgDark = background.dark;

        return (
            <Box {...props} bg={'transparent'}>
                <div style={{
                    position: 'absolute',
                    top: 0,
                    height: '100%',
                    width: '100%',
                    backgroundImage: `url(${colorMode === 'light' ? bgLight : bgDark})`,
                    backgroundSize: 'contain',
                }}>
                </div>
                {children}
            </Box>
        );
    }

    return <Box
        {...props}
        bg={gStyle.screen_background}
    >
        {children}
    </Box>;

};

export default Section;
