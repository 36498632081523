/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable multiline-ternary */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useState, useCallback, PropsWithChildren, useMemo } from 'react';
import {
    Box,
    ScrollView
} from 'native-base';

import { F } from 'ts-toolbelt';

import {
    ApiEnvironmentsNames,
    ApiEnvironmentsList,
    useSpringBigStore,
    observer,
} from '@springbig/shared';

import { Caption, H2, Hr } from '~/shared/Typography';
import Loading from '~/shared/Loading';
import Section from '~/shared/Section';


import DeviceInfo from './components/DeviceInfo';
import { ResetButton } from './components/ResetButton';
import { ActionButton, EnvButton } from './components/Buttons';
import ChangeMerchant from './components/ChangeMerchant';
import { useAppNavigate } from '..';

type EnvListProps = PropsWithChildren<{
    changeEnv: F.Function
    apiEnv: ApiEnvironmentsNames | null
}>;

const EnvList = ({ apiEnv, changeEnv }: EnvListProps) => (

    <>
        <H2>Change Environment</H2>

        <Caption mb={4}>
            Select which environment you would like to switch to
        </Caption>

        <Box flexDirection={'row'} flexWrap={'wrap'}>

            { ApiEnvironmentsList.map((val) => (

                <EnvButton
                    key={val}
                    changeEnv={changeEnv}
                    currentEnv={apiEnv as string}
                    env={val as string}
                />
            ))}
        </Box>
    </>

);

const SelectEnv = () => {

    const [isLoading, setLoading] = useState(false);
    const {navigate}= useAppNavigate();

    const {
        logout,
        switchApiEnv,
        member,
        phoneNumber,
        apiEnv = __DEV__ ? 'beta' : 'production'
    } = useSpringBigStore();

    // const isQaApp = useMemo(() => /springbigqa/.test(getBundleId()), []);

    const changeEnv = (val: ApiEnvironmentsNames) => {

        if (val === apiEnv) {
            return;
        }

        observer.once('env-changed', () => {

            setLoading(false);
        });

        setLoading(true);

        setTimeout(() => {

            switchApiEnv!(val);
        }, 1000);
    };

    const goHome = useCallback(() => {

        // if (member && member.id) {
        //     navigate('merchant/list');
        //     return;
        // }

        navigate('');
    }, [ member, navigate ]);

    return (
        <ScrollView>
            <Section
                flex={1}
                justifyContent='center'
                alignContent='center'
            >

                { isLoading && <Loading isLoading={isLoading}/> }

                    <Box p={5}>

                        <H2>Quick Actions</H2>

                        <Box
                            alignItems={'center'}
                            flexDirection={'row'}
                            flexWrap={'wrap'}
                        >

                            <ActionButton key={1} text='Go Home' bg='yellow' onPress={goHome} />
                            { phoneNumber && <ActionButton key={2} text='Logout' bg='grayLight' onPress={logout} /> }
                        </Box>

                        <Hr />

                        <EnvList apiEnv={apiEnv as any} changeEnv={changeEnv} />

                        {/* { isQaApp && (<Hr />) }
                        { isQaApp && (<ChangeMerchant />) } */}

                        <Hr />

                        <H2>Application State</H2>

                        <ResetButton />

                        <Hr />

                        <DeviceInfo />
                    </Box>
            </Section>
        </ScrollView>
    );
};

export default SelectEnv;
