import React from 'react';

import { Member } from '@springbig/shared';
import { Box } from 'native-base';

import { Caption } from '~/shared/Typography';
import { useGeneralStyles, useReferStyles } from '~/shared/styles';

type ReferRewardsProps = {
    member: Member;
}

const ReferRewards = ({ member }: ReferRewardsProps) => {

    const {
        text,
    } = useReferStyles();
    const {
        section_background
    } = useGeneralStyles();

    return (
        <Box
            w={'100%'}
            p={4}
            mb={4}
            bg={section_background}
        >
            <Caption textAlign={'center'}>
                <Caption
                    color={text}
                    text='refer.text_prize'
                />{' '}
                <Caption
                    fontWeight={'bold'}
                    color={text}
                >
                    {member.referrals.referrer_offer_name}
                </Caption>{' '}
                <Caption
                    color={text}
                    text='refer.text_receiver'
                />{' \n '}
                <Caption
                    fontWeight={'bold'}
                    color={text}
                >
                    {member.referrals.referred_offer_name}
                </Caption>
            </Caption>
        </Box>
    );
};


export default ReferRewards;
