import React, { useCallback, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// import { useAuth } from '~/contexts/authContext';
// import { useAppEvents } from '~/contexts/eventContext';

import { ErrorBoundary } from '~/shared/ErrorBoundary';

import routes from '~/pages';
import { ApiEnvironmentsList, ApiEnvironmentsNames, api, authHeaders, getAllLocations, getMember, isDemoPhone, observer, useAppSettings, useSpringBigStore, useStashboards } from '@springbig/shared';
import Layout from './shared/Layout';
import NotFound from './pages/404/NotFound';
import { useBoolean } from 'usehooks-ts';
import { useColorMode } from 'native-base';

const AppRoutes = () => {

    const {
        setValue: setIsAuth,
        value: isAuth
    } = useBoolean(false);

    const { colorMode } = useColorMode();

    const { merchantOrg, forceColorMode } = useAppSettings();
    const { setApiEnv, apiEnv } = useSpringBigStore();

    useEffect(() => {
        const env = new URLSearchParams(window.location.search).get("env");
        if (env && ApiEnvironmentsList.includes(env)) {
            (async ()=>{
                await setApiEnv(env as ApiEnvironmentsNames);
                observer.emit('switch-env', { env: env as ApiEnvironmentsNames });
            })()

        }
    },[]);

    const {
        setMember,
        setMemberId,
        setMerchantId,
        walletId,
        phoneNumber,
        mobileSubscriberId,
        setDemoMode,
        setColorMode,
        setMerchant
    } = useSpringBigStore();

    const handleMember = useCallback(async () => {

        if (!walletId) {
            return;
        }

        const _member = await getMember({ walletId });

        if (_member) {

            setMember(_member);
            setMemberId(_member.id);
            api.setStore({
                member: _member,
                memberId: _member.id,
            });
        }

        setIsAuth(true);

    }, [walletId, setMember, setMemberId, setIsAuth]);

    const getMerchant = useCallback(async (wId: string, phone: string) => {

        if (phoneNumber && mobileSubscriberId){

            const { hmac, time } =  authHeaders(phoneNumber, mobileSubscriberId);
            const opts = {headers:{
                'hmac-timestamp': `${time}`,
                'hmac-signature': hmac
            }};
            const merchants = await getAllLocations({
                phoneNumber: phone,
                merchantOrganization: merchantOrg,
            }, opts);
            
            const merchant = merchants.find((m) => m.member_key === wId);
            
            if (merchant) {
                setMerchant(merchant.merchant);
                setMerchantId(merchant.merchant.id);
                api.setStore({ merchantId: merchant.merchant.id });
            }
        }

    }, [merchantOrg, setMerchant, setMerchantId, phoneNumber, mobileSubscriberId, apiEnv]);


    useEffect(() => {

        if (phoneNumber && isDemoPhone(phoneNumber)) {
            setDemoMode(true);
            api.toggleDemo(true);
        }

        if (phoneNumber && !isDemoPhone(phoneNumber)) {
            setDemoMode(false);
            api.toggleDemo(false);
        }

    }, [phoneNumber, setDemoMode]);

    useEffect(() => {

        if (forceColorMode && forceColorMode !== colorMode) {

            setColorMode(forceColorMode);
            return;
        }

        if (forceColorMode) {

            return;
        }

        if (!colorMode) {
            setColorMode('light');
        }

    }, [forceColorMode, colorMode, setColorMode] );

    useEffect(() => {

        if (walletId && phoneNumber) {

            handleMember();
            getMerchant(walletId, phoneNumber);
            return;
        }

    }, [walletId, phoneNumber, getMerchant, handleMember, mobileSubscriberId, apiEnv]);

    let displayComponent = routes.map((route, index) => {

            const { path } = route;

            return (
                <Route
                    key={ index }
                    path={ path }
                    element={ <ErrorBoundary name={ path }>
                        <Layout>
                        <route.component />
                        </Layout>
                    </ErrorBoundary> }
                />
            );
    })


    // if(!isAuth) {
    //     displayComponent = routes.filter((route) => route.auth === false).map((r, index) => {
    //         return (
    //             <Route
    //                 key={ index }
    //                 path={ r.path }
    //                 element={ <ErrorBoundary name={ r.path }>
    //                     <Layout>
    //                     <r.component />
    //                     </Layout>
    //                 </ErrorBoundary> }
    //             />
    //         );
    //     })
    // }

    return (
        <BrowserRouter>
            <Routes>
                {
                    displayComponent.map((route, index) => {
                        return route;
                    })
                }
                <Route path='*' element={<NotFound />}/>
            </Routes>
        </BrowserRouter>
    );
};

export default AppRoutes;