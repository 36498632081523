import { faAward } from "@fortawesome/free-solid-svg-icons";
import RewardsScreen from "./Reward";
const routes = {
    'w/:wallet_id/rewards': {
        path: 'w/:wallet_id/rewards',
        title: 'Rewards',
        component: RewardsScreen,
        auth: true,
        exact: true,
        showTab: true,
        icon: faAward
    },
};
export default routes;
