/* eslint-disable react/no-children-prop */
/* eslint-disable multiline-ternary */
import React from 'react';
import { IButtonProps } from 'native-base';
import { titleCase } from 'voca';
import { F } from 'ts-toolbelt';
import Button from '~/shared/Button';


type ActionButtonProps = IButtonProps & {
    text: string,
    onPress: F.Function
}

export const ActionButton = (props: ActionButtonProps) => (

    <Button { ...props } mb='1' mr='1' children={props.text} />
);

type EnvButtonProps = IButtonProps & {
    env: string,
    currentEnv: string,
    changeEnv: F.Function
}

export const EnvButton = ({ env, currentEnv, changeEnv, ...props}: EnvButtonProps) => (

    <Button
        { ...props }
        mr={3}
        mb={4}

        bg={(

            env === currentEnv
                ? 'primary'
                : 'gray'
        )}

        onPress={() => changeEnv(env)}

        // eslint-disable-next-line react/no-children-prop
        children={titleCase(env)}
    />
);

