/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';

export type SpIconProps = Omit<FontAwesomeIconProps, 'size'> & {
    size?: number;
}

export const SpIcon = ({ icon, size = 18, ...props }: SpIconProps) => {

    return (
        <FontAwesomeIcon
            {...props}
            icon={icon}
            style={{
                height: `${size}px`,
                width: `${size}px`
            }}
            color={ props.color }
        />
    );

};
