"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    observer: ()=>_observer.observer,
    useOnAppEvent: ()=>_hooks.useOnAppEvent,
    useOnceAppEvent: ()=>_hooks.useOnceAppEvent
});
const _observer = require("./observer");
const _hooks = require("./hooks");
_exportStar(require("./events"), exports);
function _exportStar(from, to) {
    Object.keys(from).forEach(function(k) {
        if (k !== "default" && !Object.prototype.hasOwnProperty.call(to, k)) Object.defineProperty(to, k, {
            enumerable: true,
            get: function() {
                return from[k];
            }
        });
    });
    return from;
}
