import SelectEnv from "./SelectEnv";
import SecretPin from "./SecretPin";
const routes = {
    'auth/select-env': {
        path: 'auth/select-env',
        title: 'Select Environtment',
        component: SelectEnv,
        auth: false,
        exact: true,
    },
    'auth/secret-pin': {
        path: 'auth/secret-pin',
        title: 'Secret Pin',
        component: SecretPin,
        auth: false,
        exact: true,
    },
};
export default routes;
