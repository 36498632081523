import { useEffect } from "react"
import { Box } from "native-base";

import { useSpringBigStore } from "@springbig/shared";

import { Caption } from "~/shared/Typography";
import { useAppNavigate } from "..";
import { useAuthorization } from "~/hooks/auth";
import Loading from "~/shared/Loading";

const CartScreen = () => {

    const { member } = useSpringBigStore();
    const { navigate, params } = useAppNavigate();

    const {isLoadingAuth} = useAuthorization();

    useEffect(() => {

        if(member && member.merchant_website) {
            window.open(member.merchant_website, 'blank', 'noopener,noreferrer');
        }

        navigate(`w/${params.wallet_id}/profile`)

    },[member, navigate])

    if(isLoadingAuth){
        return <Loading isLoading={isLoadingAuth}/>;
    }

    return <Box><Caption text='cart.no_website'/></Box>;
}

export default CartScreen