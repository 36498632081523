/* eslint-disable @hapi/scope-start */ "use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "sendEvent", {
    enumerable: true,
    get: ()=>sendEvent
});
const _ = require("../..");
const sendEvent = async (data, opts)=>{
    const { memberId , merchantId , firebaseToken , merchantOrg , appVersion , deviceManufacturer , deviceModel , mobileSubscriberId  } = _.api.getStore();
    const event = {
        ...data,
        deviceId: mobileSubscriberId,
        epoch: Math.round(+new Date() / 1000),
        firebaseToken: firebaseToken,
        merchantId: merchantId,
        appName: merchantOrg,
        appVersion: appVersion,
        deviceManufacturer: deviceManufacturer,
        deviceModel: deviceModel,
        memberId: memberId || 0
    };
    // console.log(event);
    // const __entriesToDelete = Object.entries(event);
    // for (const [key, value] of __entriesToDelete) {
    //     if (!value) {
    //         console.log(key, "is falsy or empty: ", value);
    //     }
    // }
    // for (const key of __bullshitToDelete) {
    //     if (!(event as any)[key]) {
    //         console.log("key", key, "is missing");
    //     }
    // }
    const payload = {
        eventSchemaVersion: 'v1',
        events: [
            event
        ]
    };
    const apiKeys = (0, _.eventApiKeys)();
    return await _.api.post(`/analytics/v1/native_app/events`, payload, {
        ...opts,
        headers: {
            ...opts?.headers || {},
            'x-api-key': apiKeys[_.api.getStore().apiEnv]
        }
    });
};
_.observer.on('storage-set', (data)=>{
    if (data.key && data.key === 'firebaseToken') {
        _.api.setStore({
            firebaseToken: data.value
        });
        return;
    }
});
