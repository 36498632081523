import { VisitsResponse } from '@springbig/shared';
import dayjs from 'dayjs';
import { Box, Divider } from 'native-base';
import React from 'react';
import { Caption } from '~/shared/Typography';
import { useAccountHistoryStyles, useGeneralStyles } from '~/shared/styles';

const AccountHistoryPurchaseCard = ({ visit }: {visit: VisitsResponse}) => {

    const {
        text,
    } = useAccountHistoryStyles();

    const gStyle = useGeneralStyles();

    return (
        <Box my={4} bg={gStyle.section_background}>
            <Box
                px={4}
                py={2}
                alignItems={'center'}
                bg={gStyle.section_background}
            >
                <Caption
                    color={text}
                    fontWeight={'bold'}
                    text='history.item_ordered'
                />
                <Caption
                    color={text}
                >
                    {dayjs(visit.transaction_date).format('MM/DD/YYYY h:mm a')}
                </Caption>
                <Divider
                    bg={gStyle.divider}
                    mt={2}
                />
            </Box>
            {
                visit.visit_details && visit.visit_details.length > 0 && visit.visit_details.map( (detail, i) => {

                    return (
                        <Box
                            px={4}
                            bg={gStyle.section_background}
                            key={`${detail.id}-${i}`}
                        >
                            <Box mb={2}>
                                <Caption
                                    fontWeight={'bold'}
                                    text='history.category'
                                    color={text}
                                />
                                <Caption
                                    color={text}
                                >
                                    {detail.category}
                                </Caption>
                            </Box>
                            {
                                detail.brand && (
                                    <Box mb={2}>
                                        <Caption
                                            fontWeight={'bold'}
                                            text='history.brand'
                                            color={text}
                                        />
                                        <Caption
                                            color={text}
                                        >
                                            {detail.brand}
                                        </Caption>
                                    </Box>
                                )
                            }
                            <Box mb={2}>
                                <Caption
                                    fontWeight={'bold'}
                                    text='history.product_name'
                                    color={text}
                                />
                                <Caption
                                    color={text}
                                >
                                    {detail.name}
                                </Caption>
                            </Box>
                            <Divider
                                bg={gStyle.divider}
                            />
                        </Box>
                    );
                })
            }
            <Box
                pt={2}
                px={4}
                mb={4}
                alignItems='center'
                flexDirection={'row'}
                justifyContent={'space-between'}
                bg={gStyle.section_background}
            >
                <Caption
                    color={text}
                    text='history.transaction_total'
                />
                <Caption
                    color={text}
                >
                    ${visit.transaction_total}
                </Caption>
            </Box>
        </Box>
    );
};

export default AccountHistoryPurchaseCard;
