/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable max-lines-per-function */
import React, { useCallback } from 'react';
import { Box } from 'native-base';

import { useGetRedeemRewards, useGetVisits } from '@springbig/shared';

import Loading from '~/shared/Loading';
import Section from '~/shared/Section';
import { Caption } from '~/shared/Typography';
import { useToast } from '~/hooks/toast';
import AccountHistoryPurchaseCard from './components/AccountHistoryPurchaseCard';
import AccountHistoryRedemptionCard from './components/AccountHistoryRedemptionCard';
import { useAccountHistoryStyles } from '~/shared/styles';
import Pressable from '~/shared/Pressable';
import { useAuthorization } from '~/hooks/auth';

const AccountHistoryScreen = () => {

    const {
        active_button_background,
        active_button_text,
        inactive_button_background,
        inactive_button_text,
    } = useAccountHistoryStyles();

    const [toggleView, setToggleView] = React.useState<'purchases' | 'redemptions'>('purchases');
    const {
        data: visits,
        isLoading: isLoadingVisits,
    } = useGetVisits();
    const {
        data: rewards,
        isLoading: isLoadingRewards,
    } = useGetRedeemRewards();

    const {isLoadingAuth} = useAuthorization();

    if (isLoadingVisits || isLoadingRewards || isLoadingAuth) {
        return <Loading isLoading={true}/>;
    }

    return (
        <Section
            flex={1}
            pb={12}
        >
                <Box
                    mb={4}
                    flexDirection={'row'}
                    alignItems='center'
                    justifyContent={'space-between'}
                >
                    <Pressable
                        onPress={() => setToggleView('purchases')}
                        w={'50%'}
                        bg={toggleView === 'purchases' ? active_button_background : inactive_button_background}
                        h={10}
                        alignItems='center'
                        justifyContent={'center'}
                    >
                        <Caption
                            text='history.title_purchases'
                            color={toggleView === 'purchases' ? active_button_text : inactive_button_text}
                        />
                    </Pressable>
                    <Pressable
                        onPress={() => setToggleView('redemptions')}
                        w={'50%'}
                        h={10}
                        alignItems='center'
                        justifyContent={'center'}
                        bg={toggleView === 'redemptions' ? active_button_background : inactive_button_background}
                    >
                        <Caption
                            text='history.title_redemptions'
                            color={toggleView === 'redemptions' ? active_button_text : inactive_button_text}
                        />
                    </Pressable>
                </Box>
                {
                    toggleView === 'purchases' && visits && visits.length > 0 && visits.map((visit, index) => (

                        <AccountHistoryPurchaseCard key={index} visit={visit}/>
                    ))
                }
                {
                    toggleView === 'redemptions' && rewards && rewards.length > 0 && rewards.map((redeemed, index) => (

                        <AccountHistoryRedemptionCard key={index + 200} redeemed={redeemed}/>
                    ))
                }
        </Section>
    );
};

export default AccountHistoryScreen