/* eslint-disable max-lines-per-function */ "use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    ApiFetchError: ()=>ApiFetchError,
    ApiFactory: ()=>ApiFactory
});
const _ = require("..");
class ApiFetchError extends Error {
}
class ApiFactory {
    /**
     * For saving values that may be needed to craft requests as the
     * application progresses; for example: as you login, you get a
     * member merchant ID which is used to generate an hmac.
     */ _store = {};
    demoMode = false;
    demoFetch = new _.DemoFetchApi();
    /**
     *
     * @param opts
     */ constructor({ baseUrl , type , ...opts }){
        (0, _.assert)(!!baseUrl, 'baseUrl is required');
        (0, _.assert)(!!type, 'type is required');
        (0, _.assert)([
            'json',
            'arrayBuffer',
            'blob',
            'formData',
            'text'
        ].includes(type), 'invalid type');
        this._baseUrl = new URL(baseUrl);
        this._type = type;
        const { onAfterRequest , onBeforeRequest , onError , makeOptions , ...rest } = opts;
        this._baseOptions = rest;
        this._headers = opts.headers || {};
        this._onError = onError;
        this._onBeforeRequest = onBeforeRequest;
        this._onAfterRequest = onAfterRequest;
        this._makeOptions = makeOptions;
    }
    /**
     * Executes a function if it exists
     * @param maybeFn
     * @param maybeArgs
     */ execIfExists(maybeFn, args) {
        if (typeof maybeFn === 'function') {
            return maybeFn.apply(maybeFn, args || []);
        }
    }
    /**
     * Makes headers
     * @param override
     * @returns
     */ makeHeaders(override = {}) {
        return {
            ...this._headers,
            ...override
        };
    }
    /**
     * Makes url based on basePath
     * @param path
     */ makeUrl(path) {
        path = path?.replace(/^\/{1,}/, '');
        const url = this._baseUrl.toString().replace(/\/$/, '');
        return `${url}/${path}`;
    }
    /**
     * Makes an API call using fetch
     * @returns
     */ async makeCall(method, path, options) {
        const { payload , controller , onAfterRequest , onBeforeRequest , onError , makeOptions , ...rest } = options;
        const url = this.makeUrl(path);
        const _onAfterRequest = onAfterRequest || this._onAfterRequest;
        const _onBeforeRequest = onBeforeRequest || this._onBeforeRequest;
        const _onError = onError || this._onError;
        const _makeOptions = makeOptions || this._makeOptions;
        const { _type: type , _baseOptions  } = this;
        let opts = {
            method: method?.toUpperCase(),
            signal: rest.signal || controller.signal,
            controller,
            credentials: 'include',
            ..._baseOptions,
            ...rest
        };
        opts.headers = this.makeHeaders(opts.headers);
        if (/put|post|patch|delete/i.test(method)) {
            if (type === 'json') {
                opts.body = JSON.stringify(payload);
            } else {
                opts.body = payload;
            }
        }
        let error;
        let response;
        opts = this.execIfExists(_makeOptions, [
            opts,
            this._store
        ]) || opts;
        try {
            this.execIfExists(_onBeforeRequest, [
                opts
            ]);
            await _.observer.emit('api-will-fetch', {
                instance: this,
                opts,
                url
            });
            if (this.demoMode) {
                response = await this.demoFetch.fetch(url, opts);
                await _.observer.emit('demo-did-fetch', {
                    method,
                    opts,
                    response,
                    url
                });
            } else {
                response = await fetch(url, opts);
            }
            const contentType = response.headers.get('content-type');
            this.execIfExists(_onAfterRequest, [
                response,
                opts
            ]);
            await _.observer.emit('api-did-fetch', {
                instance: this,
                opts,
                url,
                response
            });
            let data;
            if (/text/.test(contentType)) {
                data = await response.text();
            } else if (/json/.test(contentType)) {
                data = await response.json();
            } else {
                data = await response[type]();
            }
            if (response.ok) {
                return data;
            }
            error = new ApiFetchError(`${response.status}: ${response.statusText}`);
            error.data = data;
            error.status = response.status;
            error.method = method;
            error.path = path;
        } catch (e) {
            error = new ApiFetchError(e.message);
            let statusCode = error.status || 999;
            const name = e.name;
            const message = e.message || name;
            if (e.message === 'Aborted') {
                statusCode = 998;
            }
            error.status = statusCode;
            error.data = {
                message
            };
            error.method = method;
            error.path = path;
            response = new Response(`${statusCode} ${message}`);
        }
        this.execIfExists(_onError, [
            error
        ]);
        if (error) {
            await _.observer.emit('api-error', {
                instance: this,
                opts,
                url,
                response,
                error,
                store: this._store
            });
        }
        throw error;
    }
    /**
     * Makes a request
     * @param method
     * @param path
     * @param options
     */ request(method, path, options = {}) {
        // https://developer.mozilla.org/en-US/docs/Web/API/AbortSignal
        const controller = new AbortController();
        const call = this.makeCall(method, path, {
            ...options,
            controller
        });
        call.abort = (reason)=>controller.abort(reason);
        return call;
    }
    /**
     * Makes a options request
     * @param path
     * @param headers
     * @returns
     */ options(path, options = {}) {
        return this.request('options', path, options);
    }
    /**
     * Makes a get request
     * @param path
     * @param headers
     * @returns
     */ get(path, options = {}) {
        return this.request('get', path, options);
    }
    /**
     * Makes a delete request
     * @param path
     * @param headers
     * @returns
     */ delete(path, payload = null, options = {}) {
        return this.request('delete', path, {
            ...options,
            payload
        });
    }
    /**
     * Makes a post request
     * @param path
     * @param headers
     * @returns
     */ post(path, payload = null, options = {}) {
        return this.request('post', path, {
            ...options,
            payload
        });
    }
    /**
     * Makes a put request
     * @param path
     * @param headers
     * @returns
     */ put(path, payload = null, options = {}) {
        return this.request('put', path, {
            ...options,
            payload
        });
    }
    /**
     * Makes a patch request
     * @param path
     * @param headers
     * @returns
     */ patch(path, payload = null, options = {}) {
        return this.request('patch', path, {
            ...options,
            payload
        });
    }
    /**
     * Set an object of headers
     * @param headers
     */ setHeaders(headers) {
        Object.assign(this._headers, headers);
    }
    /**
     * Remove headers by reference, array of names, or single name
     * @param headers
     */ unsetHeaders(headers) {
        if (typeof headers === 'string') {
            delete this._headers[headers];
        }
        let _names = headers;
        if (!Array.isArray(headers)) {
            _names = Object.keys(headers);
        }
        for (const name of _names){
            delete this._headers[name];
        }
    }
    /**
     * Checks if header is set
     * @param name
     * @returns
     */ headerIsSet(name) {
        return this._headers.hasOwnProperty(name);
    }
    /**
     * Merges a store and its values into the api store
     * @param conf
     */ setStore(conf) {
        Object.assign(this._store || {}, conf);
    }
    /**
     * Resets the store to an empty object
     */ resetStore() {
        this._store = {};
    }
    /**
     * Changes the base URL for this api instance
     * @param url
     */ changeBaseUrl(url) {
        this._baseUrl = new URL(url);
    }
    /**
     * Toggle or set demo mode for the app. This wraps the
     * API calls with pre-generated response calls.
     * @param setTo
     */ toggleDemo(setTo) {
        this.demoMode = setTo === undefined ? !this.demoMode : setTo;
    }
    /**
     * Returns the API internal store
     */ getStore() {
        return this._store;
    }
}
