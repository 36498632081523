import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import CartScreen from "./Cart";
const routes = {
    'w/:wallet_id/cart': {
        path: 'w/:wallet_id/cart',
        title: 'Cart',
        component: CartScreen,
        auth: true,
        exact: true,
        showTab: true,
        icon: faCartShopping
    }
};
export default routes;
