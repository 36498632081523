import { faTrophy } from "@fortawesome/free-solid-svg-icons";
import MilestoneScreen from "./Milestone";

export type MilestoneRoutes = Record<
    (
        `w/${string}/milestones`
    ),
    RouteProps
>

const routes: MilestoneRoutes = {
    'w/:wallet_id/milestones': {
        path: 'w/:wallet_id/milestones',
        title: 'Milestones',
        component: MilestoneScreen,
        auth: true,
        exact: true,
        showTab: true,
        icon: faTrophy,
    },
}

export default routes;
