"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    getAllLocations: ()=>getAllLocations,
    useGetAllLocations: ()=>useGetAllLocations
});
const _react = require("react");
const _reactQuery = require("react-query");
const _ = require("../..");
const getAllLocations = async ({ phoneNumber , merchantOrganization  }, opts)=>{
    const response = await _.api.get(`/native_app/v1/merchant_organizations/${merchantOrganization}?phone_number=${phoneNumber}`, opts);
    return response;
};
const useGetAllLocations = (queryOptions = {})=>{
    const { setStore  } = (0, _.useApi)();
    const { merchantOrg  } = (0, _.useAppSettings)();
    const { phoneNumber , mobileSubscriberId , firebaseToken , isPreparingData  } = (0, _.useSpringBigStore)();
    setStore({
        phoneNumber: phoneNumber ? phoneNumber : '',
        mobileSubscriberId: mobileSubscriberId ? mobileSubscriberId : ''
    });
    const payload = {
        firebaseToken,
        merchantOrganization: merchantOrg || '',
        phoneNumber: phoneNumber || ''
    };
    const query = (0, _reactQuery.useQuery)('merchants', ({ signal  })=>{
        if (isPreparingData) {
            return [];
        }
        return getAllLocations(payload, {
            signal
        });
    }, queryOptions);
    (0, _react.useEffect)(()=>{
        if (!isPreparingData) {
            query.refetch();
        }
    }, [
        isPreparingData
    ]);
    return query;
};
