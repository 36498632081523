export const es = {
    auth: {
        validation: {
            phone_number_required: 'El número de teléfono es obligatorio',
            pin_required: 'Se requiere PIN',
            pin_pattern: 'El pin debe tener al menos 4 caracteres',
            email_required: 'El correo electrónico es obligatorio',
            email_pattern: 'Correo electrónico no válido',
            password_required: 'Se requiere contraseña',
            password_pattern: 'La contraseña debe tener al menos 8 caracteres y 1 letra, 1 número y 1 puntuación',
            confirm_required: 'Se requiere confirmación',
            confirm_validate: 'La contraseña debe coincidir',
            shared_pin_paragraph: 'Ingrese su código de inicio de sesión a continuación para continuar',
            forgot_code: '¿Olvidó el código?',
            send_me_my_login_code: 'Envíame mi código de inicio de sesión'
        },
        links: {
            register: '¿Necesita una cuenta?',
            forgot: '¿Olvidó su contraseña?',
            login: '¿Ya tiene una cuenta?'
        },
        confirm_account: {
            error_title: '¡Oh, no!',
            error_paragraph: 'Hubo un error con su enlace de confirmación. Por favor revisa tu correo electrónico y vuelve a intentarlo.',
            confirmed_title: '¡Cuenta confirmada!',
            confirmed_paragraph: '¡Ahora puede iniciar sesión con su correo electrónico y comenzar a configurar su negocio!',
            confirming: 'Confirmando Cuenta...'
        },
        forgot_password: {
            success_title: '¡Éxito!',
            success_paragraph: 'Revise su correo electrónico para obtener un enlace de restablecimiento de contraseña.',
            title: 'Olvidé mi contraseña'
        },
        reset_password: {
            validating_token: 'Validando token...',
            invalid_link: 'Enlace de restablecimiento de contraseña no válido...',
            success_title: '¡Éxito!',
            success_paragraph: 'Ahora puede iniciar sesión con su nueva contraseña.',
            title: 'Restablecer contraseña',
        },
        login: {
            title: '¡Hola, <br/>bienvenido de nuevo!',
            paragraph: 'Ingrese su número de teléfono',
            success: 'Inicio de sesión exitoso!',
            pin_paragraph: 'Se ha enviado un PIN temporal a su teléfono y correo electrónico (si corresponde); ingréselo a continuación para continuar'
        },
        location: {
            what_state: '¿En qué estado de EE. UU. te encuentras?',
            choose_state: 'Por favor seleccione un estado',
            cannot_continue: 'No se puede continuar',
            merchant_does_not_serve: '¡Lo siento! Este comerciante no está autorizado para brindar servicios en su estado en particular.',
            location_associated_paragraph: 'Seleccione su comercio a continuación para continuar',
            location_unassociated_paragraph: '¿Viajando? Haga clic a continuación para registrarse en nuestros otros programas estatales.'
        },
        logout: '¡Has cerrado la sesión!',
        register: {
            title: '¡Bienvenido!',
            paragraph: 'Al proporcionar su número de teléfono y marcar la casilla proporcionada, acepta recibir llamadas y mensajes de texto enfocados en marketing, incluso a través de un sistema de marcación telefónica automática o voz pregrabada o artificial de {0} y sus afiliados, marcas y fabricantes de productos. No se requiere el consentimiento como condición para comprar bienes o servicios. Se pueden aplicar tarifas estándar de llamadas y mensajes por minuto. Opte por no participar en cualquier momento respondiendo "FIN".'
        },
        update_password: {
            title: 'Actualizar contraseña',
            paragraph: 'Se ha establecido una contraseña temporal en su cuenta. Por razones de seguridad, no puede continuar hasta que establezca una nueva contraseña. Establezca su nueva contraseña y podrá volver a iniciar sesión.'
        }
    },
    alerts: {
        loyalty_opt_in: 'Optar por la lealtad',
        loyalty_opt_in_message: 'Actualmente no está registrado en nuestro programa de recompensas al proporcionar su firma a continuación y hacer clic en "aceptar", ahora será elegible para ganar puntos de fidelidad y canjear recompensas.'
    },
    rewards: {
        title: 'Recompensas',
        modal_title: 'Canjear recompensa',
        title_points_rewards: '{0} puntos de recompensa',
        points_redeemable: 'Puntos canjeables',
        spend_toward_status: 'Gastar hacia el estado',
        redemption_text: `¡ALTO! Asegúrese de estar con el personal antes de canjear su recompensa. El personal debe ver cómo canjeas tu oferta para que puedas recibir tu recompensa.\n¿Estás listo para canjear tu recompensa?: {0}`,
        redemption_modal_title: 'Canje de recompensas',
        redemption_in_store: '¡Estoy tan emocionado de querer canjear sus puntos! ¡Canjee sus puntos en la tienda y antes de pagar!',
        available_points: 'puntos disponibles',
        click_here_to_earn: 'haga clic aquí para comenzar a ganar puntos',
        expires_at: 'caduca {0}',
    },
    redeem: {
        at: 'Canjeado en: {0}',
        valid_until: 'Válido hasta {0}',
        confirmation_number: 'Confirmación: {0}',
        go_back: 'regresar a la billetera'
    },
    offers: {
        title: 'Ofertas',
        title_refer: 'Recomendar a un amigo',
        modal_title: 'Canjear oferta',
        available_offers: 'Ofertas disponibles',
        refer_friend: 'Recomendar a un amigo',
        redemption_text: `¡ALTO! Asegúrese de estar con el personal antes de canjear su recompensa. El personal debe ver cómo canjeas tu oferta para que puedas recibir tu recompensa.\n¿Estás listo para canjear tu recompensa?: {0}`,
        redemption_modal_title: 'Canje de recompensas',
        redemption_in_store: '¡Tan emocionado que desea canjear su oferta! ¡Canjee su oferta en la tienda y antes de pagar!',
    },
    milestones: {
        title: 'Hitos',
        spends_remaining: 'gastar ${0} más para desbloquear este hito',
        spends_title: 'Gasta ${0} para ganar {1}',
        benefits_title: 'Los beneficios incluyen:',
        achieved: 'milestione conseguido!',
    },
    profile: {
        title: 'Perfil',
        my_account: 'Mi Cuenta',
        greetings: 'Hola, {0}',
        spend_toward_status: '{0} Gastar en el estado',
    },
    profileMenu: {
        my_information: 'Informacion personal',
        recent_history: 'Historial',
        message_notifications: 'Configuración de notificaciones',
    },
    accountInformation: {
        delete_account_title: '¿Está seguro de que desea eliminar su cuenta?',
        delete_account_message: `al hacerlo, todos los puntos de fidelidad ganados se eliminarán y no estarán disponibles para su uso y se cancelará la recepción de todos los mensajes de este minorista.

    Una vez que elimine su cuenta, será permanente y no se podrá deshacer.`,
        delete_account: 'Eliminar cuenta',
        delete_account_header_title: `Se ha enviado un código pin temporal a su teléfono. Introdúzcalo a continuación para confirmar que desea eliminar su cuenta.`
    },
    refer: {
        title: 'Recomendar a un amigo',
        copy_link: 'Copiar enlace',
        text_prize: 'Cuando uno de tus amigos se una, en tu referencia, recibirás un',
        prize: '30% de descuento en una compra',
        text_receiver: 'y recibirán',
        pre_rolled_reward: 'un pre-roll gratis',
        text_link: 'Copia y comparte el siguiente enlace con tus amigos o muéstrales a tus amigos tu código qr único arriba para que se registren fácilmente.',
        text_social_media: '¡No olvides compartir tu enlace en las redes sociales!'
    },
    interests: {
        title: 'Intereses'
    },
    message_center: {
        title: 'Centro de mensajes',
    },
    settings: {
        header_title: 'Configuración de comunicación',
        modal_title: 'Suscribirse al marketing por correo electrónico',
        modal_paragraph: 'acepto los términos y firma a continuación para optar por la mensajería',
        modal_terms_and_conditions: 'Al marcar esta casilla, acepto recibir mensajes de marketing automatizados de {0} y sus afiliados, marcas y fabricantes de productos. El consentimiento no es una condición para comprar bienes o servicios',
        text_message: 'Mensajes de texto',
        push_notifications: 'Notificaciones push',
        email_notifications: 'Notificaciones por correo electrónico',
    },
    history: {
        title_purchases: 'Compras',
        title_redemptions: 'Redenciones',
        transaction_total: 'Total de la transacción',
        item_ordered: 'Artículos pedidos',
        category: 'Categoría',
        brand: 'Marca',
        product_name: 'Nombre del producto',
        redeemed: 'redimido',
        points: 'puntos',
    },
    general: {
        accept: 'Aceptar',
        address1: 'Dirección 1',
        address2: 'Dirección 2',
        back: 'atras',
        birthday: 'Cumpleaños',
        cancel: 'Cancelar',
        cart: 'Carrito',
        city: 'Ciudad',
        confirm_password: 'Confirmar contraseña',
        continue: 'Continuar',
        delete: 'Eliminar',
        edit: 'Editar',
        email: 'Correo electrónico',
        female: 'mujer',
        first_name: 'Nombre',
        gender: 'Género',
        get: 'Obtener',
        go_back: 'Volver',
        last_name: 'Apellido',
        loading: 'Cargando',
        login: 'Iniciar sesión',
        male: 'masculino',
        merchant: 'Comerciante',
        message_center: 'Centro de mensajes',
        new_password: 'Nueva contraseña',
        offers: 'Ofertas',
        old_password: 'Contraseña antigua',
        other: 'otro',
        password: 'Contraseña',
        phone_number: 'Número de teléfono',
        pin: 'Ingresar pin',
        press_to_sign: 'Presione para firmar',
        privacy_policy: 'Política de privacidad',
        profile: 'Perfil',
        redeemed: 'Redimido',
        region: 'Estado/Región',
        rewards: 'Recompensas',
        save: 'Guardar',
        select_date: 'Seleccionar fecha',
        sign_below: 'Por favor firme abajo',
        signature_saved: 'Firma guardada',
        signature: 'Firma',
        submit: 'enviar',
        success: 'Éxito',
        terms_and_conditions: 'Términos y condiciones',
        zip: 'Código postal/Código postal',
        select_language: 'Seleccionar idioma'
    },
    cart: {
        no_website: 'No hay sitio web disponible',
    },
    apiErrors: {
        postJoinPhone: 'Error al enviar el número de teléfono',
        getJoinPin: 'Error al enviar el pin',
        getWalletMember: 'Error al recibir la billetera',
        getWalletMilestones: 'Error al recibir hitos',
        postWalletRedemption: 'Error al canjear recompensas',
        getNativeMerchantOrgs: 'Error al recibir ubicaciones',
        getNativeAppStashboardKeys: 'Error al recibir las claves del mensaje',
        getNativeAppStashboard: 'Error al recibir la información del mensaje',
        getNativeAppRewardRedemption: 'Error al canjear recompensas',
        getNativeAppVisit: 'Error al recibir la visita',
        putNativeAppSync: 'Error al sincronizar',
        patchNativeAppUpdate: 'Error al actualizar su miembro',
        postNativeAppCreate: 'Error al crear su miembro',
    },
    navigation: {
        menu: 'Menu',
        cart: 'Carrito',
        messages: 'Mensajes',
        profile: 'Perfil',
        rewards: 'Recompensas',
        offers: 'Ofertas',
        refer: 'Referir',
        milestones: 'Hitos',
        select_store: 'Seleccionar tienda',
    }
};
